import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  createCourseSuggestion: ['courseSuggestion', 'callback'],
  createCourseSuggestionDone: null,
  createCourseSuggestionError: ['message'],
  getCoursesNearYou: null,
  getCoursesNearYouDone: ['coursesNearYou'],
  getCoursesNearYouError: ['message'],
  getCoursesNearYouSilent: null,
  setShowSuggestCourseModal: ['show'],
  sendCourseSuggestion: ['courseId', 'courseSuggestion', 'callback'],
  sendCourseSuggestionDone: null,
  sendCourseSuggestionError: ['message'],
  trackCourseSuggestionEvent: ['eventParams'],
});

export const CourseSuggestionsTypes = Types;
export default Creators;

const EMPTY_COURSE_SUGGESTION = {
  customerEmail: '',
  customerName: '',
  courseName: '',
  addressCity: '',
  addressCountry: '',
  addressState: '',
  addressZipcode: '',
};

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  courseSuggestion: EMPTY_COURSE_SUGGESTION,
  coursesNearYou: [],
  errorMessage: '',
  showSuggestCourseModal: false,
  isSendingSuggestion: false,
  isSendingSuggestionDone: false,
});

const createCourseSuggestion = (state) => state.merge({
  isDone: false,
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const createCourseSuggestionDone = (state) => state.merge({
  isDone: true,
  isLoading: false,
  isError: false,
  showSuggestCourseModal: false,
});

const createCourseSuggestionError = (state, { message }) => state.merge({
  isDone: true,
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const getCoursesNearYou = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
});

const getCoursesNearYouDone = (state, { coursesNearYou }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  coursesNearYou,
});

const getCoursesNearYouError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const setShowSuggestCourseModal = (state, { show }) => state.merge({
  showSuggestCourseModal: show,
});

const sendCourseSuggestion = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  errorMessage: '',
});

const sendCourseSuggestionDone = (state) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  errorMessage: '',
});

const sendCourseSuggestionError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_COURSE_SUGGESTION_DONE]: createCourseSuggestionDone,
  [Types.CREATE_COURSE_SUGGESTION_ERROR]: createCourseSuggestionError,
  [Types.CREATE_COURSE_SUGGESTION]: createCourseSuggestion,
  [Types.GET_COURSES_NEAR_YOU]: getCoursesNearYou,
  [Types.GET_COURSES_NEAR_YOU_DONE]: getCoursesNearYouDone,
  [Types.GET_COURSES_NEAR_YOU_ERROR]: getCoursesNearYouError,
  [Types.SET_SHOW_SUGGEST_COURSE_MODAL]: setShowSuggestCourseModal,
  [Types.SEND_COURSE_SUGGESTION]: sendCourseSuggestion,
  [Types.SEND_COURSE_SUGGESTION_DONE]: sendCourseSuggestionDone,
  [Types.SEND_COURSE_SUGGESTION_ERROR]: sendCourseSuggestionError,
});
