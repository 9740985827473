import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getPriceAlerts: [],
  getPriceAlertsDone: ['priceAlerts'],
  getPriceAlertsError: ['message'],
  updatePriceAlert: ['priceAlert'],
});

export const PriceAlertsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  priceAlerts: [],
});

const getPriceAlerts = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  errorMessage: '',
});

const getPriceAlertsDone = (state, { priceAlerts }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  errorMessage: '',
  priceAlerts,
});

const getPriceAlertsError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const updatePriceAlert = (state, { priceAlert }) => {
  const priceAlerts = state.priceAlerts.map((p) => ((p.id === priceAlert.id) ? priceAlert : p));

  return (
    state.merge({
      isLoading: false,
      isError: false,
      errorMessage: '',
      priceAlerts,
    })
  );
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRICE_ALERTS]: getPriceAlerts,
  [Types.GET_PRICE_ALERTS_DONE]: getPriceAlertsDone,
  [Types.GET_PRICE_ALERTS_ERROR]: getPriceAlertsError,
  [Types.UPDATE_PRICE_ALERT]: updatePriceAlert,
});
