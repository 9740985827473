import SupremeGolfApiClient from './SupremeGolfApiClient';

const OFFERS_ENDPOINT = 'v6/offers';

class OffersApi {
  static async getOffers() {
    const response = await SupremeGolfApiClient.get(OFFERS_ENDPOINT);
    const { data } = response;
    return data.offers;
  }

  static async redeemOffer(id) {
    await SupremeGolfApiClient.post(`${OFFERS_ENDPOINT}/${id}/redeem`);
    const response = await SupremeGolfApiClient.get(OFFERS_ENDPOINT);
    const { data } = response;
    return data.offers;
  }
}

export default OffersApi;
