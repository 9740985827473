import { createReducer, createActions } from 'reduxsauce';

import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getAffiliate: null,
  getAffiliateDone: ['affiliate'],
  getAffiliateError: ['message'],
});

export const AffiliateTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  storeUrl: '',
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const getAffiliate = (state) => state.merge({
  storeUrl: '',
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const getAffiliateDone = (state, { affiliate }) => state.merge({
  isLoading: false,
  isError: false,
  storeUrl: affiliate.storeUrl,
  errorMessage: '',
});

const getAffiliateError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  storeUrl: '',
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AFFILIATE]: getAffiliate,
  [Types.GET_AFFILIATE_DONE]: getAffiliateDone,
  [Types.GET_AFFILIATE_ERROR]: getAffiliateError,
});
