import SupremeGolfApiClient from './SupremeGolfApiClient';

const COURSES_NEARBY_ENDPOINT = 'v6/featured_courses/near_you';
const COURSE_SUGGESTIONS_ENDPOINT = 'v6/course_suggestions';

class CourseSuggestionsApi {
  static async getCoursesNearYou(location) {
    const lat = location.coords.latitude;
    const lon = location.coords.longitude;
    const response = await SupremeGolfApiClient.get(
      COURSES_NEARBY_ENDPOINT,
      {
        lat,
        lon,
      },
    );
    const { data } = response;
    return data.coursesNearYou;
  }

  static async createCourseSuggestion(courseSuggestion) {
    const response = await SupremeGolfApiClient.post(
      COURSE_SUGGESTIONS_ENDPOINT,
      {
        ...courseSuggestion,
      },
    );
    const { data } = response;
    return data;
  }

  static async sendCourseSuggestion(courseId, courseSuggestion) {
    const response = await SupremeGolfApiClient.post(
      `${COURSE_SUGGESTIONS_ENDPOINT}/${courseId}`,
      {
        ...courseSuggestion,
      },
    );
    const { data } = response;
    return data;
  }
}

export default CourseSuggestionsApi;
