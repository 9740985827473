import SupremeGolfApiClient from './SupremeGolfApiClient';

const STATES_ENDPOINT = 'v6/states';

class StatesApi {
  static async getCountryStates(countryId) {
    const response = await SupremeGolfApiClient.get(`${STATES_ENDPOINT}`, {
      countryId,
    });
    const { data } = response;
    return data.states;
  }
}

export default StatesApi;
