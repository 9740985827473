import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const REEDEM_TYPE = {
  promoCode: 0,
  rewards: 1,
  giftCard: 2,
};

const { Types, Creators } = createActions({
  getPrepare: ['params'],
  getPrepareDone: ['creditCard', 'preparedTeeTime', 'features', 'checkoutDisclaimer'],
  getPrepareError: ['message'],
  setCartAndHoles: ['cart', 'holes'],
  setPromoCode: ['promoCode'],
  setRewardsPointsAmount: ['rewardsPointsAmount'],
  setGiftCardAmount: ['giftCardAmount'],
  setGiftCardCode: ['giftCardCode'],
  setGiftCardCvv: ['giftCardCvv'],
  setIsGiftCardCvvRequired: ['isGiftCardCvvRequired'],
  setIsValidationLimitReached: ['limitReached'],
  updatePrepare: [
    'players',
    'teeTimeId',
    'teeTimeReservationId',
    'promoCode',
    'rewardsPointsAmount',
    'giftCardCode',
    'giftCardAmount',
    'prepaymentRuleId',
  ],
  setCreditCard: ['creditCard'],
  setApplyBookingFeeCredit: ['applyBookingFeeCredit'],
  setDonationAmount: ['donationAmount', 'donationType'],
  makePayment: ['token', 'creditCardId'],
  makePaymentDone: ['receipt'],
  makePaymentError: ['message'],
  setUpdatePrepareRecent: ['updatePrepareRecent'],
  requestValidateRedeem: ['redeemType', 'cb'],
  requestValidateRedeemError: ['errorMessage'],
  resetCheckout: null,
  resetPromo: null,
});

export const CheckoutTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  isPaymentLoading: false,
  isPaymentError: false,
  errorMessage: '',
  preparedTeeTime: {
    teeTime: {
      isRefundable: false,
      isPrepaid: false,
      provider: { logoSvg: '', name: '' },
      bonusPromoCode: { promoCode: '', promoSavings: 0 },
    },
    promoCode: '',
  },
  isPreparedTeeTimeError: false,
  promoCode: '',
  rewardsPointsAmount: '',
  giftCard: {},
  giftCardCode: '',
  giftCardAmount: '',
  giftCardCvv: '',
  isGiftCardCvvRequired: false,
  isValidationLimitReached: false,
  receipt: { receipt: { reservationId: '' } },
  updatePrepareRecent: false,
  loadingValidatePromoCode: false,
  validatePromoCodeError: '',
  loadingValidateRewards: false,
  validateRewardsError: '',
  loadingValidateGiftCard: false,
  validateGiftCardError: '',
  creditCard: null,
  features: {
    giftCardRedemption: false,
    rewardsRedemption: false,
    promoCodeRedemption: false,
    promoCodeRedemptionBlocked: false,
    giftCardRedemptionBlocked: false,
  },
  donationAmount: null,
  donationType: null,
  applyBookingFeeCredit: true,
  checkoutDisclaimer: null,
});

const resetCheckout = () => INITIAL_STATE;

const getPrepare = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  errorMessage: '',
  preparedTeeTime: {
    teeTime: {
      isRefundable: false,
      isPrepaid: false,
      provider: { logoSvg: '', name: '' },
      bonusPromoCode: { promoCode: '', promoSavings: 0 },
    },
    promoCode: '',
  },
  rewardsPointsAmount: '',
  promoCode: '',
  isPreparedTeeTimeError: false,
});

const setUpdatePrepareRecent = (state, { updatePrepareRecent }) => state.merge({
  updatePrepareRecent,
});

const getPrepareDone = (state, {
  creditCard, preparedTeeTime, features, checkoutDisclaimer,
}) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  isPreparedTeeTimeError: false,
  preparedTeeTime,
  creditCard,
  features,
  checkoutDisclaimer,
  loadingValidatePromoCode: false,
  validatePromoCodeError: '',
  loadingValidateRewards: false,
  validateRewardsError: '',
  loadingValidateGiftCard: false,
  validateGiftCardError: '',
});

const updatePrepare = (state) => state.merge({
  isDone: false,
  isLoading: true,
  isError: false,
  preparedTeeTime: {
    teeTime: {
      isRefundable: false,
      isPrepaid: false,
      provider: { logoSvg: '', name: '' },
      bonusPromoCode: { promoCode: '', promoSavings: 0 },
    },
    promoCode: '',
  },
  isPreparedTeeTimeError: false,
});

const getPrepareError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isPreparedTeeTimeError: true,
  errorMessage: message,
});

const setCartAndHoles = (state, { cart, holes }) => state.merge({
  cart,
  holes,
});

const setCreditCard = (state, { creditCard }) => state.merge({
  creditCard,
});

const makePayment = (state) => state.merge({
  isPaymentLoading: true,
  isPaymentError: false,
  isDone: false,
  errorMessage: '',
});

const makePaymentDone = (state, { receipt }) => INITIAL_STATE.merge({
  isPaymentLoading: false,
  isPaymentError: false,
  isDone: true,
  errorMessage: '',
  receipt,
});

const makePaymentError = (state, { message }) => state.merge({
  isPaymentLoading: false,
  isPaymentError: true,
  isDone: true,
  errorMessage: message,
});

const setRewardsPointsAmount = (state, { rewardsPointsAmount }) => state.merge({
  rewardsPointsAmount,
});
const setGiftCardAmount = (state, { giftCardAmount }) => state.merge({
  giftCardAmount,
});
const setGiftCardCode = (state, { giftCardCode }) => state.merge({
  giftCardCode,
});
const setGiftCardCvv = (state, { giftCardCvv }) => state.merge({
  giftCardCvv,
});

const setIsGiftCardCvvRequired = (state, { isGiftCardCvvRequired }) => state.merge({
  isGiftCardCvvRequired,
  loadingValidateGiftCard: false,
});

const setIsValidationLimitReached = (state, { limitReached }) => state.merge({
  isValidationLimitReached: limitReached,
  loadingValidateGiftCard: false,
  loadingValidatePromoCode: false,
});

const setRequestValidateRedeemError = (state, { errorMessage }) => state.merge({
  loadingValidatePromoCode: false,
  validatePromoCodeError: errorMessage.validatePromoCodeError,
  loadingValidateRewards: false,
  validateRewardsError: errorMessage.validateRewardsError,
  rewardsPointsAmount: errorMessage.validateRewardsError ? '' : state.rewardsPointsAmount,
  promoCode: errorMessage.validatePromoCodeError ? '' : state.promoCode,
  loadingValidateGiftCard: false,
  validateGiftCardError: errorMessage.validateGiftCardError,
  giftCardCode: errorMessage.validateGiftCardError ? '' : state.giftCardCode,
});

const setRequestValidateRedeem = (state, { redeemType }) => state.merge({
  loadingValidatePromoCode: redeemType === REEDEM_TYPE.promoCode,
  validatePromoCodeError:
      redeemType === REEDEM_TYPE.promoCode ? '' : state.validatePromoCodeError,
  loadingValidateRewards: redeemType === REEDEM_TYPE.rewards,
  validateRewardsError:
      redeemType === REEDEM_TYPE.rewards ? '' : state.validateRewardsError,
  loadingValidateGiftCard: redeemType === REEDEM_TYPE.giftCard,
  validateGiftCardError:
      redeemType === REEDEM_TYPE.giftCard ? '' : state.validateGiftCardError,
  isGiftCardCvvRequired: false,
});

const setApplyBookingFeeCredit = (state, { applyBookingFeeCredit }) => state.merge({
  applyBookingFeeCredit,
});

const setDonationAmount = (state, { donationAmount, donationType }) => state.merge({
  donationAmount,
  donationType,
});

const setPromoCode = (state, { promoCode }) => state.merge({
  promoCode,
});

const resetPromo = (state) => state.merge({
  promoCode: '',
  loadingValidatePromoCode: false,
  validatePromoCodeError: '',
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_CHECKOUT]: resetCheckout,
  [Types.GET_PREPARE]: getPrepare,
  [Types.SET_UPDATE_PREPARE_RECENT]: setUpdatePrepareRecent,
  [Types.GET_PREPARE_DONE]: getPrepareDone,
  [Types.GET_PREPARE_ERROR]: getPrepareError,
  [Types.SET_CART_AND_HOLES]: setCartAndHoles,
  [Types.SET_CREDIT_CARD]: setCreditCard,
  [Types.MAKE_PAYMENT]: makePayment,
  [Types.MAKE_PAYMENT_DONE]: makePaymentDone,
  [Types.MAKE_PAYMENT_ERROR]: makePaymentError,
  [Types.SET_PROMO_CODE]: setPromoCode,
  [Types.SET_REWARDS_POINTS_AMOUNT]: setRewardsPointsAmount,
  [Types.SET_GIFT_CARD_AMOUNT]: setGiftCardAmount,
  [Types.SET_GIFT_CARD_CODE]: setGiftCardCode,
  [Types.SET_GIFT_CARD_CVV]: setGiftCardCvv,
  [Types.SET_IS_GIFT_CARD_CVV_REQUIRED]: setIsGiftCardCvvRequired,
  [Types.SET_IS_VALIDATION_LIMIT_REACHED]: setIsValidationLimitReached,
  [Types.SET_APPLY_BOOKING_FEE_CREDIT]: setApplyBookingFeeCredit,
  [Types.SET_DONATION_AMOUNT]: setDonationAmount,
  [Types.UPDATE_PREPARE]: updatePrepare,
  [Types.REQUEST_VALIDATE_REDEEM]: setRequestValidateRedeem,
  [Types.REQUEST_VALIDATE_REDEEM_ERROR]: setRequestValidateRedeemError,
  [Types.RESET_PROMO]: resetPromo,
});
