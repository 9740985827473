import {
  call,
  getContext,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { TrackingTypes } from 'reducers/tracking';

export function* trackEvent({
  eventName,
  dataFields,
}) {
  const iterable = yield getContext('iterable');
  const profile = yield select((state) => state.profile);

  const { loggedIn: userIsLoggedIn, id: userId } = profile;

  if (userIsLoggedIn) {
    yield call(iterable.trackEvent, {
      userId: userId.toString(),
      eventName,
      dataFields: { ref: `${process.env.REACT_APP_ITERABLE_NAME}_web`, ...dataFields },
    });
  }
}

export function* trackGAEvent({ event, params }) {
  if (!event?.name) return;
  let extraParams = {};
  const user = yield select((state) => state.profile);
  if (user?.loggedIn) {
    extraParams.email_address = user.email;
    extraParams.sg_user_id = user.id;
  }

  if (params) extraParams = { ...extraParams, ...params };

  const gtm = yield getContext('gtm');
  yield call(gtm.trackEvent, {
    event: event.name,
    eventLabel: event?.label ?? '',
    eventAction: event?.action ?? 'click',
    eventCategory: event?.category ?? '',
    extraParams,
  });
}

function* trackEventWatcher() {
  yield takeLatest(TrackingTypes.TRACK_EVENT, trackEvent);
}

function* trackGAEventWatcher() {
  yield takeLatest(TrackingTypes.TRACK_GA_EVENT, trackGAEvent);
}

export default [trackEventWatcher, trackGAEventWatcher];
