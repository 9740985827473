import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getRateTypes: ['courseId', 'params'],
  getRateTypesDone: ['summary', 'rateTypeList'],
  getRateTypesError: ['message'],
});

export const RateTypesTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  rateTypeList: [],
  summary: null,
});

const getRateTypes = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  rateTypeList: [],
  summary: null,
});

const getRateTypesDone = (state, { summary, rateTypeList }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  rateTypeList,
  summary,
});

const getRateTypesError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RATE_TYPES]: getRateTypes,
  [Types.GET_RATE_TYPES_DONE]: getRateTypesDone,
  [Types.GET_RATE_TYPES_ERROR]: getRateTypesError,
});
