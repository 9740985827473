import MembershipApiClient from './MembershipApiClient';

const PROGRAM_WITH_MEMBERSHIP_FROM_SLUG_ENDPOINT = 'Program';

class ProgramApi {
  static async programWithMembershipFromSlug(slug) {
    const response = await MembershipApiClient.get(
      `${PROGRAM_WITH_MEMBERSHIP_FROM_SLUG_ENDPOINT}/getProgramWithMembershipFromSlug/${slug}`,
    );

    return response.data;
  }
}

export default ProgramApi;
