import {
  put, takeLatest, call,
} from 'redux-saga/effects';

import AffiliateActions, { AffiliateTypes } from 'reducers/affiliate';
import AffiliateApi from 'apis/supremeGolfApi/AffiliateApi';

export function* getAffiliateHandler() {
  try {
    const { affiliate } = yield call(
      AffiliateApi.getAffiliate,
    );
    yield put(AffiliateActions.getAffiliateDone(affiliate));
  } catch (error) {
    yield put(AffiliateActions.getAffiliateError(error.message));
  }
}

function* getAffiliateWatcher() {
  yield takeLatest(AffiliateTypes.GET_AFFILIATE, getAffiliateHandler);
}

export default [
  getAffiliateWatcher,
];
