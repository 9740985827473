import {
  call, put, takeLatest, select, take,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import UserAgreementsActions, { UserAgreementsTypes } from 'reducers/userAgreements';
import ProfileActions from 'reducers/profile';
import RegistrationActions from 'reducers/registrations';
import UsersApi from 'apis/supremeGolfApi/UsersApi';
import UnsubscriptionsApi from 'apis/supremeGolfApi/UnsubscriptionsApi';
import SessionActions from 'reducers/session';
import { goToSignInHandler } from './session';

function* updatePreferences(response) {
  yield put(RegistrationActions.editUserDone(response));
  yield put(UserAgreementsActions.postOptInDone());
}

function* updatePreferencesUser(preferences) {
  const response = yield call(UsersApi.editUser, this || preferences);
  yield call(updatePreferences, response);
  yield put(ProfileActions.setProfile(response.user));
}

function* signInFlow(preferences, path) {
  try {
    const goToSignIn = yield goToSignInHandler({
      path,
      action: updatePreferencesUser.bind(preferences),
    });
    if (goToSignIn) return;

    yield updatePreferencesUser(preferences);
  } catch (error) {
    const errorMessage = error.response.data.error;

    yield put(RegistrationActions.editUserError(errorMessage));
    yield put(UserAgreementsActions.postOptInError());
  }
}

function* unsubscribe(token, email, preferences) {
  try {
    const response = yield call(UnsubscriptionsApi.unsubscribe, token, email, preferences);
    yield call(updatePreferences, response);
  } catch (error) {
    const path = window.location.pathname;
    yield call(signInFlow, preferences, path);
  }
}

function* unsubscribePreUsers(token, type) {
  try {
    yield call(UnsubscriptionsApi.unsubscribePreUsers, token, type);
    yield put(UserAgreementsActions.postOptInDone());
  } catch (error) {
    yield put(UserAgreementsActions.postOptInError());
  }
}

export function* marketingOptInHandler({ preferences, isPreUser }) {
  const query = yield select((state) => state.router.location.query);

  if (query.token && query.email) {
    if (isPreUser) {
      yield call(unsubscribePreUsers, query.token, 'NearbyDealAlert');
      return;
    }

    yield call(unsubscribe, query.token, query.email, preferences);
    return;
  }

  yield call(signInFlow, preferences, window.location.pathname);
}

export function* goToPreferencePageHandler() {
  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }
  const goToSignIn = yield goToSignInHandler({
    path: '/my-account/communication-preferences',
    newWindow: false,
    trackEvent: null,
  });
  if (!goToSignIn) yield put(push('/my-account/communication-preferences'));
}

function* goToPreferencePageWatcher() {
  yield takeLatest(UserAgreementsTypes.GO_TO_PREFERENCE_PAGE, goToPreferencePageHandler);
}

function* marketingOptInWatcher() {
  yield takeLatest(UserAgreementsTypes.POST_OPT_IN, marketingOptInHandler);
}

export default [
  goToPreferencePageWatcher,
  marketingOptInWatcher,
];
