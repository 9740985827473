import {
  call, put, takeLatest, select,
  getContext,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import ProfileActions from 'reducers/profile';
import SessionActions from 'reducers/session';
import RegistrationActions from 'reducers/registrations';
import WelcomeActions, { WelcomeTypes } from 'reducers/welcome';

import UsersApi from 'apis/supremeGolfApi/UsersApi';

import { SIGN_IN, HOME } from 'utils/routes';

export function* optInPreferencesAfterSignup({ preferences }) {
  try {
    const response = yield call(UsersApi.editUser, preferences);
    yield put(RegistrationActions.editUserDone(response));
    yield put(ProfileActions.setProfile(response.user));
  } catch (error) {
    const errorMessage = error.response.data.error;

    yield put(RegistrationActions.editUserError(errorMessage));
  }
}

export function* redirectAfterWelcome() {
  const { path, action } = yield select((state) => state.session.redirectTo);
  if (path) {
    yield put(push(path));
  } else {
    yield put(SessionActions.redirectTo({ path: HOME }));
    yield put(push(HOME));
  }
  if (action) {
    yield action();
  }
}

export function* welcome() {
  try {
    const LS = yield getContext('localStorage');
    const showWelcomeScreen = yield call(LS.getValue, 'showWelcomeScreen');

    if (showWelcomeScreen || showWelcomeScreen === 'true') {
      yield put(WelcomeActions.welcomeLoaded());
      return;
    }
    yield put(WelcomeActions.redirectAfterWelcome());
  } catch (error) {
    yield put(SessionActions.redirectTo({ path: HOME }));
    yield put(push(SIGN_IN));
  }
}

export function* welcomeOptInHandler({ preferences }) {
  try {
    yield put(WelcomeActions.optInPreferencesAfterSignup(preferences));

    yield put(WelcomeActions.welcomeOptInDone());

    yield put(WelcomeActions.redirectAfterWelcome());
  } catch (error) {
    yield put(WelcomeActions.welcomeOptInError());
  }
}

function* welcomeWatcher() {
  yield takeLatest(WelcomeTypes.WELCOME, welcome);
}

function* redirectAfterWelcomeWatcher() {
  yield takeLatest(WelcomeTypes.REDIRECT_AFTER_WELCOME, redirectAfterWelcome);
}

function* welcomeOptInWatcher() {
  yield takeLatest(WelcomeTypes.WELCOME_OPT_IN, welcomeOptInHandler);
}

function* optInPreferencesAfterSignupWatcher() {
  yield takeLatest(WelcomeTypes.OPT_IN_PREFERENCES_AFTER_SIGNUP, optInPreferencesAfterSignup);
}

export default [
  welcomeWatcher,
  welcomeOptInWatcher,
  redirectAfterWelcomeWatcher,
  optInPreferencesAfterSignupWatcher,
];
