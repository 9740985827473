module.exports = {
  theme: {
    colors: {
      primary: '#cf0a2c',
      secondary: '#1e428a',
      first: '#000000',
      twentieth: '#041f3b',
    },
  },
};
