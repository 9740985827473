import { call, put, takeLatest } from 'redux-saga/effects';

import DealDetailActions, { DealDetailTypes } from 'reducers/dealDetail';
import DealsApi from 'apis/supremeGolfApi/DealsApi';

export function* requestDealDetailHandler({ dealId }) {
  try {
    const dealDetail = yield call(DealsApi.getDealDetail, dealId);
    yield put(DealDetailActions.getDealDetailDone(dealDetail));
  } catch (error) {
    yield put(DealDetailActions.getDealDetailError(error.message));
  }
}

function* requestDealDetailWatcher() {
  yield takeLatest(DealDetailTypes.GET_DEAL_DETAIL, requestDealDetailHandler);
}

export default [
  requestDealDetailWatcher,
];
