module.exports = {
  theme: {
    colors: {
      primary: '#f56708',
      'primary-light': '#fe7b64',
      'primary-light-2': '#fde0cd',
      secondary: '#041f3b',
      'secondary-hover': '#f2f3f7',
      first: '#041f3b',
      twentieth: '#041f3b',
    },
  },
};
