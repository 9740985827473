import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setMembershipRedirectTo: ['redirectTo'],
  redirectToMembership: [],
  redirectToMembershipDone: [],
  redirectToMembershipError: [],
  setProgramData: [],
  setProgramDataDone: ['membershipId', 'membershipBillingPlans'],
  setProgramDataError: [],
  getMemberships: [],
  getMembershipsDone: ['memberships'],
  getMembershipsError: [],
  redirectToManageMembership: ['membershipUrl'],
  redirectToManageMembershipError: [],
});

export const MembershipTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isError: false,
  isLoading: false,
  membershipId: null,
  redirectTo: null,
  memberships: null,
  membershipBillingPlans: [],
  defaultMembershipBillingPlan: {},
});

const setMembershipRedirectTo = (state, { redirectTo }) => state.merge({
  redirectTo,
});

const redirectToMembership = (state) => state.merge({
  isError: false,
  isLoading: true,
  oneTimeToken: null,
});

const redirectToMembershipDone = (state) => state.merge({
  isError: false,
  isLoading: false,
});

const redirectToMembershipError = (state) => state.merge({
  isError: true,
  isLoading: false,
});

const setProgramData = (state) => state.merge({
  isError: false,
  isLoading: true,
});

const setProgramDataDone = (state, { membershipId, membershipBillingPlans }) => state.merge({
  isError: false,
  isLoading: false,
  membershipId,
  membershipBillingPlans,
  defaultMembershipBillingPlan: membershipBillingPlans[0],
});

const setProgramDataError = (state) => state.merge({
  isError: true,
  isLoading: false,
  membershipId: null,
  membershipBillingPlans: [],
  defaultMembershipBillingPlan: {},
});

const getMemberships = (state) => state.merge({
  memberships: null,
  isLoading: true,
  isError: false,
});

const getMembershipsDone = (state, { memberships }) => state.merge({
  memberships,
  isLoading: false,
  isError: false,
});

const getMembershipsError = (state) => state.merge({
  memberships: null,
  isLoading: false,
  isError: true,
});

const redirectToManageMembershipError = (state) => state.merge({
  isError: true,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_MEMBERSHIP_REDIRECT_TO]: setMembershipRedirectTo,
  [Types.REDIRECT_TO_MEMBERSHIP]: redirectToMembership,
  [Types.REDIRECT_TO_MEMBERSHIP_DONE]: redirectToMembershipDone,
  [Types.REDIRECT_TO_MEMBERSHIP_ERROR]: redirectToMembershipError,
  [Types.SET_PROGRAM_DATA]: setProgramData,
  [Types.SET_PROGRAM_DATA_DONE]: setProgramDataDone,
  [Types.SET_PROGRAM_DATA_ERROR]: setProgramDataError,
  [Types.GET_MEMBERSHIPS]: getMemberships,
  [Types.GET_MEMBERSHIPS_DONE]: getMembershipsDone,
  [Types.GET_MEMBERSHIPS_ERROR]: getMembershipsError,
  [Types.REDIRECT_TO_MANAGE_MEMBERSHIP_ERROR]: redirectToManageMembershipError,
});
