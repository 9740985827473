import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import PriceAlertsActions, { PriceAlertsTypes } from 'reducers/priceAlerts';
import ProfileActions from 'reducers/profile';
import AlertsApi from 'apis/supremeGolfApi/AlertsApi';
import UsersApi from 'apis/supremeGolfApi/UsersApi';
import { goToSignInHandler } from './session';

export function* getPriceAlerts() {
  const location = yield select((state) => state.router.location);
  const profile = yield select((state) => state.profile);
  const { pathname } = location;

  try {
    const userProfile = yield call(
      UsersApi.userProfile,
    );
    yield put(ProfileActions.setProfile(userProfile.user));
  } catch (error) {
    yield put(ProfileActions.getProfileError());
  }

  if (!profile.loggedIn) {
    const goToSignIn = yield goToSignInHandler({
      path: pathname,
      newWindow: false,
      trackEvent: null,
    });

    if (goToSignIn) return;
  }

  try {
    const priceAlerts = yield call(AlertsApi.getPriceAlerts);

    yield put(PriceAlertsActions.getPriceAlertsDone(priceAlerts));
  } catch (error) {
    yield put(PriceAlertsActions.getPriceAlertsError(error.message));
  }
}

function* getPriceAlertsWatcher() {
  yield takeLatest(PriceAlertsTypes.GET_PRICE_ALERTS, getPriceAlerts);
}

export default [
  getPriceAlertsWatcher,
];
