import { call, put, takeLatest } from 'redux-saga/effects';

import BannersApi from 'apis/supremeGolfApi/BannersApi';
import BannersActions, { BannersTypes } from 'reducers/banners';

export function* setBannersHandler({ courseId }) {
  try {
    const banners = yield call(BannersApi.getBanners, courseId);
    yield put(BannersActions.setBannersDone(banners));
  } catch (error) {
    yield put(BannersActions.setBannersError(error.message));
  }
}

function* setBannersWatcher() {
  yield takeLatest(BannersTypes.SET_BANNERS, setBannersHandler);
}

export default [
  setBannersWatcher,
];
