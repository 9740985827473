export const UPCOMING_TAB_ID = 0;
export const PAST_TAB_ID = 1;

export const UPCOMING = 'upcoming';
export const PAST = 'past';
export const CANCELLED = 'cancelled';

export function tabIdFromStatus(status) {
  const mapping = {};

  mapping[UPCOMING] = UPCOMING_TAB_ID;
  mapping[PAST] = PAST_TAB_ID;

  return mapping[status];
}

export function statusFromTabId(tabId) {
  const mapping = {};

  mapping[UPCOMING_TAB_ID] = UPCOMING;
  mapping[PAST_TAB_ID] = PAST;

  return mapping[tabId];
}
