import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setCriteria: ['criteria'],
  setSearchId: ['searchId'],
  getInitialData: ['params'],
  getInitialDataDone: ['data'],
  getInitialDataError: ['message'],
  getExploreContent: ['params'],
  getExploreContentDone: ['explore'],
  getExploreContentError: ['message'],
  clearExplore: null,
});

export const ExploreTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  criteria: 'country',
  searchId: null,
  countries: [],
  states: [],
  state: null,
  country: null,
  statesFeatured: [],
  citiesFeatured: [],
});

const setCriteria = (state, { criteria }) => state.merge({
  criteria,
});

const setSearchId = (state, { searchId }) => state.merge({
  searchId,
});

const getInitialData = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  errorMessage: '',
});

const getInitialDataDone = (state, { data }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  ...data,
});

const getExploreContent = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  errorMessage: '',
});

const getExploreContentDone = (state, { explore }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  ...explore,
});

const error = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const clearExplore = (state) => state.merge({
  ...INITIAL_STATE,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CRITERIA]: setCriteria,
  [Types.SET_SEARCH_ID]: setSearchId,
  [Types.GET_INITIAL_DATA]: getInitialData,
  [Types.GET_INITIAL_DATA_DONE]: getInitialDataDone,
  [Types.GET_INITIAL_DATA_ERROR]: error,
  [Types.GET_EXPLORE_CONTENT]: getExploreContent,
  [Types.GET_EXPLORE_CONTENT_DONE]: getExploreContentDone,
  [Types.GET_EXPLORE_CONTENT_ERROR]: error,
  [Types.CLEAR_EXPLORE]: clearExplore,
});
