import { createReducer, createActions } from 'reduxsauce';

import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  displayAlert: ['title', 'description'],
  closeAlert: null,
});

export const AppTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isVisible: false,
  title: '',
  description: '',
});

const displayAlert = (state, { title, description }) => state.merge({
  isVisible: true,
  title,
  description,
});

const closeAlert = (state) => state.merge({
  ...INITIAL_STATE,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DISPLAY_ALERT]: displayAlert,
  [Types.CLOSE_ALERT]: closeAlert,
});
