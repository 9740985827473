import ApiClient from 'apis/ApiClient';

class IterableApiClient extends ApiClient {
  constructor() {
    super();

    this.isConfigured = !!this.constructor.headers()['api-key'] && !!this.constructor.apiBaseUrl();
  }

  static API_BASE_URL = 'https://api.iterable.com';

  static headers() {
    return {
      'api-key': process.env.REACT_APP_ITERABLE_API_KEY,
    };
  }

  static withCredentials() {
    return true;
  }

  static apiBaseUrl() {
    return IterableApiClient.API_BASE_URL;
  }

  static formatParams(params) {
    return params;
  }
}

export default new IterableApiClient();
