import SupremeGolfApiClient from './SupremeGolfApiClient';

const DYNAMIC_LINK_ENDPOINT = 'v6/firebase/dynamic_link';

class DynamicLinkApi {
  static async getDynamicLink(params) {
    const response = await SupremeGolfApiClient.get(
      `${DYNAMIC_LINK_ENDPOINT}`,
      params,
    );
    const { data } = response;

    return data;
  }
}

export default DynamicLinkApi;
