import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getFaqs: null,
  getFaqsDone: ['faqs'],
  getFaqsError: ['message'],
});

export const FaqsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
  faqs: [],
});

const getFaqs = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const getFaqsDone = (state, { faqs }) => state.merge({
  isLoading: false,
  isError: false,
  faqs,
});

const getFaqsError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_FAQS]: getFaqs,
  [Types.GET_FAQS_DONE]: getFaqsDone,
  [Types.GET_FAQS_ERROR]: getFaqsError,
});
