import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { HOME } from 'utils/routes';
import UnlockActions, { UnlockTypes } from 'reducers/unlock';
import UsersApi from 'apis/supremeGolfApi/UsersApi';
import { goToSignInHandler } from './session';

export function* requestResendUnlockInstructionsHandler({ email }) {
  try {
    yield call(
      UsersApi.resendUnlockInstructions,
      email,
    );
    yield put(UnlockActions.resendUnlockInstructionsDone());
  } catch (error) {
    yield put(UnlockActions.resendUnlockInstructionsError(
      error?.response?.data?.error || error?.message,
    ));
  }
}

function* resendUnlockInstructionsWatcher() {
  yield takeLatest(UnlockTypes.RESEND_UNLOCK_INSTRUCTIONS, requestResendUnlockInstructionsHandler);
}

function* unlock({ unlockToken }) {
  try {
    yield call(
      UsersApi.unlock,
      unlockToken,
    );
    yield put(UnlockActions.unlockDone());
    yield goToSignInHandler({
      path: HOME,
      newWindow: false,
      trackEvent: null,
    });
  } catch (error) {
    yield put(UnlockActions.unlockError());
  }
}

function* unlockWatcher() {
  yield takeLatest(UnlockTypes.UNLOCK, unlock);
}

export default [
  resendUnlockInstructionsWatcher,
  unlockWatcher,
];
