import SupremeGolfApiClient from './SupremeGolfApiClient';

class SiteApi {
  static async fetchSiteStatus() {
    const response = await SupremeGolfApiClient.get('v6/status/site');
    return response?.data;
  }
}

export default SiteApi;
