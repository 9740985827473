import SupremeGolfApiClient from './SupremeGolfApiClient';

const USERS_ENDPOINT = 'v6/users';
const ALERTS_ENDPOINT = 'v6/alerts';

class UnsubscriptionsApi {
  static async unsubscribe(token, email, preferences) {
    const body = {
      email,
      ...preferences,
    };
    const response = await SupremeGolfApiClient.patch(`${USERS_ENDPOINT}/${token}/unsubscribe`, body);
    const { data } = response;
    return data;
  }

  static async unsubscribePreUsers(token, type) {
    const body = {
      type,
      token,
    };
    const response = await SupremeGolfApiClient.post(`${ALERTS_ENDPOINT}/unsubscribe`, body);
    const { data } = response;
    return data;
  }
}

export default UnsubscriptionsApi;
