import {
  call, put, takeLatest, select, delay,
} from 'redux-saga/effects';

import { push } from 'react-router-redux';
import AlertActions from 'reducers/alert';
import CourseReviewsActions, {
  CourseReviewsTypes,
} from 'reducers/courseReviews';
import CourseReviewsApi from 'apis/supremeGolfApi/CourseReviewsApi';
import DynamicLinkApi from 'apis/supremeGolfApi/DynamicLinkApi';
import {
  COURSE_REVIEWS, NEW_COURSE_REVIEW, NOT_FOUND, MODERATOR_REVIEWS,
} from 'utils/routes';
import qs from 'qs';
import { HEADER_ROW, ERROR_ROW, BANNED_ERROR_MESSAGE } from 'utils/constants';
import DateHelper from 'utils/dateHelper';
import moderatorReviewStatuses from '../utils/moderatorReviewStatuses';
import { goToSignInHandler } from './session';

export const headerRow = (message) => ({
  label: message,
  ...HEADER_ROW,
});

export const errorRow = (message) => ([{
  label: message,
  ...ERROR_ROW,
}]);

function* performGetReviewsHandler({ tagId }) {
  const searchValues = yield select((state) => state.search.searchParams.searchValues);
  const filterValues = yield select((state) => state.search.searchParams.filterValues);

  const {
    date: searchDate,
    players,
    holes,
    location,
  } = searchValues;

  const {
    cart,
    dealsOnly,
    previousRateTypes,
    price,
    rate,
    time,
    timeSlot,
  } = filterValues;

  if (location) {
    const queryString = qs.stringify({
      cart,
      date: DateHelper.formatDateWithoutTime(searchDate),
      dealsOnly,
      tag_id: tagId,
      holes,
      location,
      players,
      previousRateTypes,
      price,
      rate,
      time,
      timeSlot,
    });

    const path = COURSE_REVIEWS.replace('/:countryId/:stateId/:cityId/:courseId', location.hierarchizedUrl);
    yield put(push(`${path}?${queryString}`));
  }
}

function* newCourseReviewHandler(data) {
  const searchValues = yield select((state) => state.search.searchParams.searchValues);
  const filterValues = yield select((state) => state.search.searchParams.filterValues);

  const {
    date: searchDate,
    players,
    holes,
    location,
  } = searchValues;

  const {
    cart,
    dealsOnly,
    previousRateTypes,
    price,
    rate,
    time,
    timeSlot,
  } = filterValues;

  if (location) {
    const queryString = qs.stringify({
      cart,
      date: DateHelper.formatDateWithoutTime(searchDate),
      tag_id: data?.id,
      dealsOnly,
      holes,
      location,
      players,
      previousRateTypes,
      price,
      rate,
      time,
      timeSlot,
    });

    const path = NEW_COURSE_REVIEW.replace('/:countryId/:stateId/:cityId/:courseId', data.hierarchizedUrl);

    const goToSignIn = yield goToSignInHandler({
      path: `${path}?${queryString}`,
      newWindow: false,
      trackEvent: null,
    });
    if (goToSignIn) return;
    const isBanned = yield select((state) => state.profile.isBanned);
    if (isBanned) {
      yield put(AlertActions.displayAlert('Error', BANNED_ERROR_MESSAGE));
      return;
    }
    yield put(push(`${path}?${queryString}`));
  }
}

function* performGetCourseReviewsHandler({ hierarchizedUrl }) {
  const searchValues = yield select((state) => state.search.searchParams.searchValues);
  const filterValues = yield select((state) => state.search.searchParams.filterValues);

  const {
    date: searchDate,
    players,
    holes,
    location,
  } = searchValues;

  const {
    cart,
    dealsOnly,
    previousRateTypes,
    price,
    rate,
    time,
    timeSlot,
  } = filterValues;

  if (location) {
    const queryString = qs.stringify({
      cart,
      date: DateHelper.formatDateWithoutTime(searchDate),
      dealsOnly,
      holes,
      location,
      players,
      previousRateTypes,
      price,
      rate,
      time,
      timeSlot,
    });

    const path = COURSE_REVIEWS.replace('/:countryId/:stateId/:cityId/:courseId', hierarchizedUrl);
    yield put(push(`${path}?${queryString}`));
  }
}

export function* getCourseReviewsHandler({
  courseId, sortBy, tagId, reviewId,
}) {
  try {
    let highlightedReview;
    let reviews = [];
    if (reviewId) {
      try {
        highlightedReview = yield call(CourseReviewsApi.getCourseReview, { reviewId });
        reviews.push(highlightedReview);
      } catch (error) {
        yield put(push(NOT_FOUND));
      }
    }
    const page = 1;
    const {
      reviewsList, hasMore, courseReviewsCount,
    } = yield call(CourseReviewsApi.getCourseReviews, {
      courseId,
      page,
      sortBy: sortBy.replace(/-/g, '_'),
      excludeIds: reviewId ? [reviewId] : [],
      tagId,
    });

    reviews = reviews.concat(reviewsList);

    yield put(CourseReviewsActions.getCourseReviewsDone(reviews, hasMore, courseReviewsCount));
  } catch (error) {
    yield put(CourseReviewsActions.getCourseReviewsError(error.message));
  }
}

export function* helpfulnessHandler({ id, helpful }) {
  try {
    const goToSignIn = yield goToSignInHandler({
      path: '',
      newWindow: false,
      trackEvent: null,
      action: helpfulnessHandler.bind(this, { id, helpful }),
    });
    if (goToSignIn) return;

    yield call(CourseReviewsApi.helpfulness, {
      id,
      isHelpful: helpful,
    });
    yield put(CourseReviewsActions.helpfulnessDone(id));
  } catch (error) {
    const { response: { data: { error: niceError } = {} } = {} } = error;
    const errorMessage = niceError || error.message;

    yield put(CourseReviewsActions.helpfulnessError(id, errorMessage));
  }
}

export function* reportItHandler({ id }) {
  try {
    const goToSignIn = yield goToSignInHandler({
      path: '',
      newWindow: false,
      trackEvent: null,
      action: reportItHandler.bind(this, { id }),
    });
    if (goToSignIn) return;
    yield call(CourseReviewsApi.reportIt, { id });
    yield put(CourseReviewsActions.reportItDone(id));
  } catch (error) {
    const { response: { data: { error: niceError } = {} } = {} } = error;
    const errorMessage = niceError || error.message;

    yield put(CourseReviewsActions.reportItError(id, errorMessage));
  }
}

export function* createCourseReviewHandler(params) {
  try {
    const {
      rating: courseRating, title, courseReview: comments,
    } = params;
    const courseId = yield select((state) => state.courseDetail.course.id);
    yield call(CourseReviewsApi.createCourseReview, {
      courseId,
      courseRating,
      title,
      comments,
    });
    yield put(CourseReviewsActions.createReviewDone());
  } catch (error) {
    yield put(CourseReviewsActions.createReviewError(error.message));
  }
}

export function* getNextPageReviewsHandler({ courseId, excludeId }) {
  try {
    const { sortBy } = yield select((state) => state.courseReviews);
    let { page } = yield select((state) => state.courseReviews);
    page += 1;
    const {
      reviewsList, hasMore, courseReviewsCount,
    } = yield call(CourseReviewsApi.getCourseReviews, {
      courseId,
      page,
      sortBy: sortBy.replace(/-/g, '_'),
      excludeIds: excludeId ? [excludeId] : [],
    });
    delay(1500);
    yield put(CourseReviewsActions.getNextPageReviewsDone(
      reviewsList,
      hasMore,
      page,
      courseReviewsCount,
    ));
  } catch (error) {
    yield put(CourseReviewsActions.getNextPageReviewsError(error.message));
  }
}

export function* setModeratorReviewTab({ selectedTab }) {
  yield put(push(MODERATOR_REVIEWS.replace(':status', selectedTab)));
}

export function* getModeratorReviewsHandler({ status }) {
  const { reviewFilters } = yield select((state) => state.courseReviews);

  try {
    const page = 1;
    const reviewScope = moderatorReviewStatuses[status].scope;
    const reviewsList = yield call(
      CourseReviewsApi.getModeratorReviews,
      page,
      reviewScope,
      reviewFilters.courseSlug,
      reviewFilters.from,
      reviewFilters.to,
      reviewFilters.userID,
    );
    let hasMore = true;
    if (reviewsList.length < 20) {
      hasMore = false;
    }

    yield put(CourseReviewsActions.getCourseReviewsDone(reviewsList, hasMore));
  } catch (error) {
    yield put(CourseReviewsActions.getCourseReviewsError(error.message));
  }
}

export function* getNextPageModeratorReviewsHandler({ status }) {
  const { reviewFilters } = yield select((state) => state.courseReviews);

  try {
    let { page } = yield select((state) => state.courseReviews);
    page += 1;
    const reviewScope = moderatorReviewStatuses[status].scope;
    const reviewsList = yield call(
      CourseReviewsApi.getModeratorReviews,
      page,
      reviewScope,
      reviewFilters.courseSlug,
      reviewFilters.from,
      reviewFilters.to,
      reviewFilters.userID,
    );
    let hasMore = true;
    if (reviewsList.length < 20) {
      hasMore = false;
    }
    delay(1500);
    yield put(CourseReviewsActions.getNextPageReviewsDone(reviewsList, hasMore, page));
  } catch (error) {
    yield put(CourseReviewsActions.getNextPageReviewsError(error.message));
  }
}

export function* getDynamicLinkHandler({ id, resourceType }) {
  try {
    const links = yield call(DynamicLinkApi.getDynamicLink, {
      resourceId: id,
      resourceType,
    });
    yield put(CourseReviewsActions.getDynamicLinkDone(links.shortLink));
  } catch (error) {
    yield put(CourseReviewsActions.getDynamicLinkError(error.message));
  }
}

export function* getCourseAutocompleteHandler({ filterText }) {
  try {
    const filterAutocomplete = yield call(
      CourseReviewsApi.getCoursesAutocomplete,
      filterText,
    );

    if (filterAutocomplete.length > 0) {
      filterAutocomplete.unshift(headerRow('Search Results'));
    }

    if (filterAutocomplete.length) {
      yield put(CourseReviewsActions.getAutocompleteDone(filterAutocomplete));
    } else {
      yield put(CourseReviewsActions.getAutocompleteDone(
        errorRow('We couldn\'t find results for your search'),
      ));
    }
  } catch (error) {
    yield put(CourseReviewsActions.getAutocompleteError(error.message));
  }
}

export function* getUserEmailAutocompleteHandler({ filterText }) {
  try {
    const filterAutocomplete = yield call(
      CourseReviewsApi.getUsersAutocomplete,
      filterText,
    );

    if (filterAutocomplete.length > 0) {
      filterAutocomplete.unshift(headerRow('Search Results'));
    }

    if (filterAutocomplete.length) {
      yield put(CourseReviewsActions.getAutocompleteDone(filterAutocomplete));
    } else {
      yield put(CourseReviewsActions.getAutocompleteDone(
        errorRow('We couldn\'t find results for your search'),
      ));
    }
  } catch (error) {
    yield put(CourseReviewsActions.getAutocompleteError(error.message));
  }
}

export function* setReviewStatusHandler({ reviewID, isApproved }) {
  try {
    yield call(CourseReviewsApi.setReviewStatus, reviewID, isApproved);

    let { reviewsList } = yield select((state) => state.courseReviews);
    reviewsList = reviewsList.filter((review) => review.id !== reviewID);
    yield put(CourseReviewsActions.getCourseReviewsDone(reviewsList, false));
  } catch (error) {
    yield put(CourseReviewsActions.getCourseReviewsError(error.message));
  }
}

function* performGetReviewsWatcher() {
  yield takeLatest(CourseReviewsTypes.PERFORM_GET_REVIEWS, performGetReviewsHandler);
}

function* performGetCourseReviewsWatcher() {
  yield takeLatest(CourseReviewsTypes.PERFORM_GET_COURSE_REVIEWS, performGetCourseReviewsHandler);
}

function* getReviewsWatcher() {
  yield takeLatest(CourseReviewsTypes.GET_COURSE_REVIEWS, getCourseReviewsHandler);
}

function* helpfulnessWatcher() {
  yield takeLatest(CourseReviewsTypes.HELPFULNESS, helpfulnessHandler);
}

function* reportItWatcher() {
  yield takeLatest(CourseReviewsTypes.REPORT_IT, reportItHandler);
}

function* newCourseReviewWatcher() {
  yield takeLatest(CourseReviewsTypes.NEW_COURSE_REVIEW, newCourseReviewHandler);
}

function* createCourseReviewWatcher() {
  yield takeLatest(CourseReviewsTypes.CREATE_REVIEW, createCourseReviewHandler);
}

function* getNextPageWatcher() {
  yield takeLatest(CourseReviewsTypes.GET_NEXT_PAGE_REVIEWS, getNextPageReviewsHandler);
}

function* setModerateContentTabWatcher() {
  yield takeLatest(CourseReviewsTypes.SET_MODERATOR_REVIEW_TAB, setModeratorReviewTab);
}

function* getModeratorReviewsWatcher() {
  yield takeLatest(CourseReviewsTypes.GET_MODERATOR_REVIEWS, getModeratorReviewsHandler);
}

function* getNextPageModeratorReviewsWatcher() {
  yield takeLatest(
    CourseReviewsTypes.GET_NEXT_PAGE_MODERATOR_REVIEWS,
    getNextPageModeratorReviewsHandler,
  );
}

function* getDynamicLinkWatcher() {
  yield takeLatest(CourseReviewsTypes.GET_DYNAMIC_LINK, getDynamicLinkHandler);
}

function* getCourseAutocompleteWatcher() {
  yield takeLatest(CourseReviewsTypes.GET_COURSE_AUTOCOMPLETE, getCourseAutocompleteHandler);
}

function* getUserEmailAutocompleteWatcher() {
  yield takeLatest(CourseReviewsTypes.GET_USER_EMAIL_AUTOCOMPLETE, getUserEmailAutocompleteHandler);
}

function* setReviewStatusWatcher() {
  yield takeLatest(CourseReviewsTypes.SET_REVIEW_STATUS, setReviewStatusHandler);
}

export default [
  performGetReviewsWatcher,
  performGetCourseReviewsWatcher,
  getModeratorReviewsWatcher,
  getNextPageModeratorReviewsWatcher,
  getReviewsWatcher,
  helpfulnessWatcher,
  reportItWatcher,
  newCourseReviewWatcher,
  createCourseReviewWatcher,
  getNextPageWatcher,
  getCourseAutocompleteWatcher,
  getUserEmailAutocompleteWatcher,
  setModerateContentTabWatcher,
  getDynamicLinkWatcher,
  setReviewStatusWatcher,
];
