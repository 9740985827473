import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  addFavoriteCity: ['id'],
  addFavoriteCityDone: null,
  addFavoriteCityError: ['message'],
  removeFavoriteCity: ['id'],
  removeFavoriteCityDone: ['removedCityId'],
  removeFavoriteCityError: ['message'],
  getFavoriteCities: [],
  getFavoriteCitiesDone: ['favoriteCities'],
  getFavoriteCitiesError: ['message'],
});

export const FavoriteCitiesTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  favoriteCities: [],
});

const toggleFavoriteCity = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const toggleFavoriteCityDone = (state, { removedCityId }) => {
  const favoriteCities = state.favoriteCities.filter(
    (city) => city.id !== removedCityId,
  );

  return state.merge({
    isLoading: false,
    isError: false,
    favoriteCities,
  });
};

const toggleFavoriteCityError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const getFavorites = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  errorMessage: '',
});

const getFavoritesDone = (state, { favoriteCities }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  errorMessage: '',
  favoriteCities,
});

const getFavoritesError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_FAVORITE_CITY]: toggleFavoriteCity,
  [Types.ADD_FAVORITE_CITY_DONE]: toggleFavoriteCityDone,
  [Types.ADD_FAVORITE_CITY_ERROR]: toggleFavoriteCityError,
  [Types.REMOVE_FAVORITE_CITY]: toggleFavoriteCity,
  [Types.REMOVE_FAVORITE_CITY_DONE]: toggleFavoriteCityDone,
  [Types.REMOVE_FAVORITE_CITY_ERROR]: toggleFavoriteCityError,
  [Types.GET_FAVORITE_CITIES]: getFavorites,
  [Types.GET_FAVORITE_CITIES_DONE]: getFavoritesDone,
  [Types.GET_FAVORITE_CITIES_ERROR]: getFavoritesError,
});
