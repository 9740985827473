export const focusOptions = [
  {
    label: 'Recreation',
    value: 'recreation',
  },
  {
    label: 'Business',
    value: 'business',
  },
  {
    label: 'Pro',
    value: 'pro',
  },
  {
    label: 'Sport',
    value: 'sport',
  },
];

export const frequencyOptions = [
  {
    label: 'Once a year',
    value: 'once_a_year',
  },
  {
    label: 'Twice a year',
    value: 'twice_a_year',
  },
  {
    label: 'Once every 3 months',
    value: 'once_every_3_months',
  },
  {
    label: 'Once a month',
    value: 'once_a_month',
  },
  {
    label: 'Once a week',
    value: 'once_a_week',
  },
  {
    label: 'Few times a week',
    value: 'few_times_a_week',
  },
];

export const genderOptions = [
  {
    label: 'Male',
    value: 'm',
  },
  {
    label: 'Female',
    value: 'f',
  },
  {
    label: 'Prefer not to say',
    value: 'n',
  },
];

export const skillLevelOptions = [
  {
    label: 'Beginner',
    value: 'beginner',
  },
  {
    label: 'Intermediate',
    value: 'intermediate',
  },
  {
    label: 'Advanced',
    value: 'advanced',
  },
  {
    label: 'Expert',
    value: 'expert',
  },
];

export const isProfileComplete = (user) => !!(
  user.firstName && user.lastName && user.addressCountryIso2 && user.addressZipcode
);
