import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import FeedbackActions, { FeedbackTypes } from 'reducers/feedback';
import FeedbacksApi from 'apis/supremeGolfApi/FeedbacksApi';

export function* requestFeedbackHandler({ foundEverything, message }) {
  try {
    const feedback = yield call(FeedbacksApi.postFeedback, foundEverything, message);

    yield put(FeedbackActions.postFeedbackDone(feedback));
  } catch (error) {
    yield put(FeedbackActions.postFeedbackError(error.message));
  }
}

function* requestFeedbackWatcher() {
  yield takeLatest(
    FeedbackTypes.POST_FEEDBACK,
    requestFeedbackHandler,
  );
}

export default [
  requestFeedbackWatcher,
];
