import { call, put, takeLatest } from 'redux-saga/effects';

import FaqsActions, { FaqsTypes } from 'reducers/faqs';
import FaqsApi from 'apis/supremeGolfApi/FaqsApi';

export function* requestFaqs() {
  try {
    const faqs = yield call(FaqsApi.getFaqs);
    yield put(FaqsActions.getFaqsDone(faqs));
  } catch (error) {
    yield put(FaqsActions.getFaqsError(error.message));
  }
}

function* requestFaqsWatcher() {
  yield takeLatest(FaqsTypes.GET_FAQS, requestFaqs);
}

export default [
  requestFaqsWatcher,
];
