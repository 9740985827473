import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  signUp: [
    'firstName',
    'lastName',
    'addressZipcode',
    'addressCountryIso2',
    'email',
    'password',
    'gRecaptchaResponseData',
    'referralCode',
    'callback',
  ],
  signUpDone: null,
  signUpError: ['message'],
  socialSignIn: [
    'provider',
    'socialParams',
    'isGoogleOneTapLogin',
  ],
  oneTimeTokenSignIn: ['ott'],
  socialSignInDone: null,
  socialSignInError: ['message'],
  editUser: ['params'],
  editUserDone: null,
  editUserError: ['message'],
  resetRegistrations: null,
});

export const RegistrationTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const resetRegistrations = (state) => state.merge({
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const signUp = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const signUpDone = (state) => state.merge({
  isLoading: false,
  isError: false,
});

const signUpError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const socialSignIn = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const socialSignInDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const socialSignInError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const editUser = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const editUserDone = (state) => state.merge({
  isLoading: false,
  isError: false,
});

const editUserError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_UP]: signUp,
  [Types.SIGN_UP_DONE]: signUpDone,
  [Types.SIGN_UP_ERROR]: signUpError,
  [Types.SOCIAL_SIGN_IN]: socialSignIn,
  [Types.SOCIAL_SIGN_IN_DONE]: socialSignInDone,
  [Types.SOCIAL_SIGN_IN_ERROR]: socialSignInError,
  [Types.EDIT_USER]: editUser,
  [Types.EDIT_USER_DONE]: editUserDone,
  [Types.EDIT_USER_ERROR]: editUserError,
  [Types.RESET_REGISTRATIONS]: resetRegistrations,
});
