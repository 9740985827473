import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  resetCountry: null,
  getCountriesList: null,
  getCountriesListDone: ['countriesList'],
  getCountriesListError: ['message'],
});

export const CountryTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  countriesList: [],
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const resetCountry = (state) => state.merge(INITIAL_STATE);

const getCountriesList = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const getCountriesListDone = (state, { countriesList }) => state.merge({
  isLoading: false,
  isError: false,
  countriesList,
});

const getCountriesListError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_COUNTRY]: resetCountry,
  [Types.GET_COUNTRIES_LIST]: getCountriesList,
  [Types.GET_COUNTRIES_LIST_DONE]: getCountriesListDone,
  [Types.GET_COUNTRIES_LIST_ERROR]: getCountriesListError,
});
