export const LOAD_RETRY_TIMES = 3;

export function componentLoader(
  lazyComponent,
  attemptsLeft = LOAD_RETRY_TIMES,
) {
  const RETRY_THRESHOLD = 1000;

  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // retry after 1000 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject,
          );
        }, RETRY_THRESHOLD);
      });
  });
}
