import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import SupportActions, { SupportTypes } from 'reducers/support';
import FeedbacksApi from 'apis/supremeGolfApi/FeedbacksApi';

export function* requestSupportHandler({ params, callback }) {
  try {
    const support = yield call(FeedbacksApi.postSupport, params);
    if (callback) callback();
    yield put(SupportActions.sendSupportDone(support));
  } catch (error) {
    const { response: { data: { error: responseError } = {} } = {} } = error;
    const { response: { status: responseCode } = {} } = error;
    const errorMessage = responseError || error.message;
    if (callback) callback(responseCode, errorMessage);
    yield put(SupportActions.sendSupportError(errorMessage));
  }
}

function* requestSupportWatcher() {
  yield takeLatest(
    SupportTypes.SEND_SUPPORT,
    requestSupportHandler,
  );
}

export default [
  requestSupportWatcher,
];
