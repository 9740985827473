import theme from 'utils/theme';

export const isMobileScreen = () => {
  const tailwindMdSize = theme.screens.md.match(/(\d+)px/);

  if (tailwindMdSize.length === 0) {
    throw Error('Tailwind Md Size undefined on config');
  }

  const mdScreenSize = parseInt(tailwindMdSize[0], 10);

  return window.innerWidth < mdScreenSize;
};

export const isTabletScreen = () => {
  const tailwindMdSize = theme.screens.md.match(/(\d+)px/);
  const tailwindLgSize = theme.screens.lg.match(/(\d+)px/);

  if (tailwindMdSize.length === 0 && tailwindLgSize.length === 0) {
    throw Error('Tailwind Md/Lg Size undefined on config');
  }

  const mdScreenSize = parseInt(tailwindMdSize[0], 10);
  const lgScreenSize = parseInt(tailwindLgSize[0], 10);

  return mdScreenSize <= window.innerWidth && window.innerWidth < lgScreenSize;
};

export const isDesktopScreen = () => {
  const tailwindLgSize = theme.screens.lg.match(/(\d+)px/);

  if (tailwindLgSize.length === 0) {
    throw Error('Tailwind Lg Size undefined on config');
  }

  const lgScreenSize = parseInt(tailwindLgSize[0], 10);

  return window.innerWidth >= (lgScreenSize);
};

export const isXlScreen = () => {
  const tailwindXlSize = theme.screens.xl.match(/(\d+)px/);

  if (tailwindXlSize.length === 0) {
    throw Error('Tailwind Xl Size undefined on config');
  }

  const xlScreenSize = parseInt(tailwindXlSize[0], 10);

  return window.innerWidth >= (xlScreenSize - 1);
};
