import {
  call, put, takeLatest, getContext, select, take,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import Route from 'route-parser';

import UsersApi from 'apis/supremeGolfApi/UsersApi';

import SessionActions from 'reducers/session';
import ProfileActions from 'reducers/profile';
import WelcomeActions from 'reducers/welcome';
import RegistrationActions, { RegistrationTypes } from 'reducers/registrations';
import { goToVerifyCustomerDetailsHandler } from 'sagas/session';

import { GTM_USER_SIGN_UP_VALUE } from 'utils/constants';
import { features } from 'utils/features';
import { matchRoute } from 'utils/routeHelpers';
import getPreferences from 'utils/preferences';
import {
  WELCOME_TO_SUPREME,
  HOME, SIGN_IN, SIGN_UP,
  BOOKINGS_INVITE,
} from 'utils/routes';

const FIRST_SIGN_IN = 1;

export function* signUp({
  firstName,
  lastName,
  addressZipcode,
  addressCountryIso2,
  email,
  password,
  gRecaptchaResponseData,
  referralCode,
  callback,
}) {
  const referralAttributes = {
    code: referralCode,
  };
  let invitationToken = null;

  try {
    const gtm = yield getContext('gtm');
    const { path, action } = yield select((state) => state.session.redirectTo);

    const bookingsInviteRoute = new Route(BOOKINGS_INVITE);
    const { inviteId } = bookingsInviteRoute.match(path);
    if (inviteId) invitationToken = inviteId;

    yield call(gtm.trackEvent, {
      eventCategory: 'sign up', eventAction: 'click', eventLabel: 'sign-up-top-nav', event: 'click-sign-up',
    });

    const LS = yield getContext('localStorage');
    const utmParams = yield call(LS.getUtmParams);
    const rwgParams = yield call(LS.getRwgParams);
    const iterable = yield getContext('iterable');
    const iterableTracking = yield call(iterable.getTrackingParams);
    const response = yield call(
      UsersApi.signUp,
      firstName,
      lastName,
      addressZipcode,
      addressCountryIso2,
      email,
      password,
      gRecaptchaResponseData,
      referralAttributes,
      iterableTracking,
      invitationToken,
      rwgParams,
      utmParams,
    );

    yield call(LS.setToken, response.token);

    if (features.marketingCommunication) {
      const preferences = getPreferences(response?.isUsZipcode ? ['all'] : []);
      yield put(WelcomeActions.optInPreferencesAfterSignup(preferences));
    }
    const { isDoneProfile } = yield select((state) => state.profile);
    if (!isDoneProfile) {
      const userProfile = yield call(UsersApi.userProfile);
      yield put(ProfileActions.setProfile(userProfile.user));
    }

    yield put(RegistrationActions.signUpDone(response));

    if (callback) callback();

    const { id, signInCount } = yield select((state) => state.profile);
    yield call(gtm.changeDataLayer, {
      transactionId: `USER-${id}`,
      transactionTotal: GTM_USER_SIGN_UP_VALUE,
      transactionProducts: undefined,
    });

    yield call(gtm.trackEvent, {
      eventCategory: 'sign up', eventAction: 'submit', eventLabel: 'success', event: 'sign-up-success',
    });

    if (features.marketingCommunication && signInCount === FIRST_SIGN_IN) {
      yield call(LS.setValue, 'showWelcomeScreen', true);
      yield put(push(WELCOME_TO_SUPREME));
    } else if (inviteId) {
      yield put(push(path));
    } else if (path) {
      yield put(push(path));
    } else {
      yield put(push(HOME));
    }

    if (!features.marketingCommunication && action) {
      yield action();
    }
  } catch (error) {
    const { response: { data: { error: niceError } = {} } = {} } = error;
    if (callback) callback(error);
    yield put(RegistrationActions.signUpError(niceError || error.message));
  }
}

export function* socialSignIn({ provider, socialParams, isGoogleOneTapLogin }) {
  let accessToken = null;
  let userID = null;
  let email = null;
  let rawInfo = null;
  let invitationToken = null;
  const location = yield select((state) => state.router.location);

  if (provider === 'facebook') {
    ({ accessToken, email, userID } = socialParams);
  } else if (provider === 'google') {
    accessToken = socialParams.tokenId;
    userID = socialParams.profileObj.googleId;
    email = socialParams.profileObj.email;
  } else if (provider === 'apple') {
    const { authorization, user } = socialParams;
    userID = authorization.id_token;
    accessToken = authorization.code;
    if (user) {
      email = user.email;
      rawInfo = JSON.stringify(user.name);
    }
  }

  try {
    const gtm = yield getContext('gtm');
    const { action, path } = yield select((state) => state.session.redirectTo);

    const bookingsInviteRoute = new Route(BOOKINGS_INVITE);
    const { inviteId } = bookingsInviteRoute.match(path);
    if (inviteId) invitationToken = inviteId;

    yield call(gtm.trackEvent, {
      eventCategory: `social sign in - ${provider}`, eventAction: 'click', eventLabel: `sign-in-${provider}-button`, event: `click-${provider}-sign-in`,
    });

    const LS = yield getContext('localStorage');
    const utmParams = yield call(LS.getUtmParams);
    const rwgParams = yield call(LS.getRwgParams);
    const iterable = yield getContext('iterable');
    const iterableTracking = yield call(iterable.getTrackingParams);
    const socialSignInResponse = yield call(
      UsersApi.signInSocially,
      provider,
      accessToken,
      userID,
      email,
      iterableTracking,
      invitationToken,
      rwgParams,
      utmParams,
      rawInfo,
    );

    yield put(RegistrationActions.socialSignInDone(socialSignInResponse));
    yield call(LS.setToken, socialSignInResponse.token);
    const userProfile = yield call(UsersApi.userProfile);

    if (userProfile.user.signInCount === FIRST_SIGN_IN) {
      yield call(gtm.trackEvent, {
        eventCategory: 'sign up', eventAction: 'submit', eventLabel: 'success', event: `sign-up-${provider}-success`,
      });
    } else {
      yield call(gtm.trackEvent, {
        eventCategory: 'sign in', eventAction: 'submit', eventLabel: 'success', event: `sign-in-${provider}-success`,
      });
    }

    if (userProfile.user.signInCount !== FIRST_SIGN_IN) {
      const { restoreSessionFinished } = yield select((state) => state.session);
      if (!restoreSessionFinished) {
        yield take((innerAction) => (
          innerAction.type === SessionActions.restoreSessionDone().type
          || innerAction.type === SessionActions.restoreSessionError().type
        ));
      }
    }

    yield put(ProfileActions.setProfile(userProfile.user));
    if (userProfile.user.signInCount !== FIRST_SIGN_IN) {
      const goToVerifyCustomerDetails = yield goToVerifyCustomerDetailsHandler(userProfile.user, {
        pathname: path || HOME, search: '',
      });
      if (goToVerifyCustomerDetails) return;
    }

    if (action) {
      yield action();
    }

    if (features.marketingCommunication && userProfile.user.signInCount === FIRST_SIGN_IN) {
      if (isGoogleOneTapLogin && !path) {
        yield put(SessionActions.redirectTo({
          path: `${location.pathname}${location.search}`,
          newWindow: false,
          trackEvent: null,
        }));
      }
      const preferences = getPreferences([]);
      yield put(WelcomeActions.optInPreferencesAfterSignup(preferences));
      yield call(LS.setValue, 'showWelcomeScreen', true);
      yield put(push(WELCOME_TO_SUPREME));
    } else if (path) {
      yield put(SessionActions.redirectTo({ path: '' }));
      if (isGoogleOneTapLogin && matchRoute(HOME, location.pathname)) {
        yield put(push(HOME));
      } else if (inviteId) {
        yield put(push(path));
      } else {
        yield put(push(path));
      }
    } else if (
      !isGoogleOneTapLogin
      || matchRoute(SIGN_IN, location.pathname)
      || matchRoute(SIGN_UP, location.pathname)
    ) {
      yield put(push(HOME));
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.error || error?.message;

    if (isGoogleOneTapLogin) {
      const { path } = yield select((state) => state.session.redirectTo);
      if (!path) {
        yield put(SessionActions.redirectTo({
          path: `${location.pathname}${location.search}`,
          newWindow: false,
          trackEvent: null,
        }));
      }
      yield put(push('/sign-in'));
    }

    yield put(SessionActions.loginError(errorMessage));
    yield put(RegistrationActions.socialSignInError(errorMessage));
  }
}

function* signUpWatcher() {
  yield takeLatest(RegistrationTypes.SIGN_UP, signUp);
}

function* socialSignInWatcher() {
  yield takeLatest(RegistrationTypes.SOCIAL_SIGN_IN, socialSignIn);
}

export default [signUpWatcher, socialSignInWatcher];
