import {
  put, takeLatest, getContext, select, call, all,
} from 'redux-saga/effects';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import pickBy from 'lodash/pickBy';

import AppActions, { AppTypes } from 'reducers/app';
import AffiliateActions from 'reducers/affiliate';

import LocationActions from 'reducers/location';
import SessionActions from 'reducers/session';

function* generateUUID() {
  const LS = yield getContext('localStorage');
  let uuid = yield call(LS.getValue, 'uuid');
  if (!uuid) {
    uuid = uuidv4();
    yield call(LS.setValue, 'uuid', uuid);
    return uuid;
  }
  return uuid;
}

function* setUtmParams() {
  const { location } = yield select(({ router }) => router);
  const LS = yield getContext('localStorage');

  const queryParams = pickBy(
    qs.parse(location.search, { ignoreQueryPrefix: true }),
    (_value, key) => key.includes('utm') || key.includes('gclid') || key.includes('msclkid'),
  );

  if (Object.keys(queryParams).length) {
    LS.removeUtmParams();
    const currentTime = new Date().toISOString();
    yield call(LS.setValue, 'utm_timestamp', currentTime);
  }
  yield all(Object.keys(queryParams).map((key) => call(LS.setValue, key, queryParams[key])));
}

export function* setRwgParams() {
  const { location } = yield select(({ router }) => router);
  const LS = yield getContext('localStorage');

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const course = yield select(({ courseDetail }) => courseDetail.course);

  if (queryParams?.rwg_token && course?.id) {
    yield call(LS.removeKey, 'rwg_token');
    yield call(LS.removeKey, 'rwg_course_id');
    const currentTime = new Date().toISOString();
    yield call(LS.setValue, 'rwg_timestamp', currentTime);
    yield call(LS.setValue, 'rwg_token', queryParams.rwg_token);
    yield call(LS.setValue, 'rwg_course_id', course.id);
  }
}

export function* initAppHandler() {
  try {
    const { isWebView } = yield select(({ app }) => app);
    if (!isWebView) {
      yield put(LocationActions.getLocation());
    }
    yield put(SessionActions.restoreSession());
    yield* setUtmParams();
    yield* generateUUID();
    yield put(AffiliateActions.getAffiliate());
    yield put(SessionActions.getSessionToken());
    yield put(AppActions.initAppDone());
  } catch (error) {
    yield put(AppActions.initAppError(error.message));
  }
}

function* initAppWatcher() {
  yield takeLatest(AppTypes.INIT_APP, initAppHandler);
}

export default [
  initAppWatcher,
];
