import { call, put, takeLatest } from 'redux-saga/effects';
import FavoriteCitiesActions, { FavoriteCitiesTypes } from 'reducers/favoriteCities';
import CityActions from 'reducers/city';
import FavoriteCitiesApi from 'apis/supremeGolfApi/FavoriteCitiesApi';
import { goToSignInHandler } from './session';

export function* addFavoriteCityHandler({ id }) {
  try {
    const goToSignIn = yield goToSignInHandler();
    if (goToSignIn) return;

    yield call(FavoriteCitiesApi.addFavoriteCity, id);
    yield put(FavoriteCitiesActions.addFavoriteCityDone());
    yield put(CityActions.getCity({ id }));
  } catch (error) {
    yield put(FavoriteCitiesActions.addFavoriteCityError(error.message));
  }
}

export function* removeFavoriteCityHandler({ id }) {
  try {
    const goToSignIn = yield goToSignInHandler();
    if (goToSignIn) return;

    yield call(FavoriteCitiesApi.removeFavoriteCity, id);
    yield put(FavoriteCitiesActions.removeFavoriteCityDone(id));
    yield put(CityActions.getCity({ id }));
  } catch (error) {
    yield put(FavoriteCitiesActions.removeFavoriteCityError(error.message));
  }
}

export function* getFavoritesHandler() {
  try {
    const favoriteCities = yield call(FavoriteCitiesApi.getFavorites);

    yield put(FavoriteCitiesActions.getFavoriteCitiesDone(favoriteCities));
  } catch (error) {
    yield put(FavoriteCitiesActions.getFavoriteCitiesError(error.message));
  }
}

function* addFavoriteCityWatcher() {
  yield takeLatest(FavoriteCitiesTypes.ADD_FAVORITE_CITY, addFavoriteCityHandler);
}

function* removeFavoriteCityWatcher() {
  yield takeLatest(FavoriteCitiesTypes.REMOVE_FAVORITE_CITY, removeFavoriteCityHandler);
}

function* getFavoritesWatcher() {
  yield takeLatest(FavoriteCitiesTypes.GET_FAVORITE_CITIES, getFavoritesHandler);
}

export default [
  addFavoriteCityWatcher,
  removeFavoriteCityWatcher,
  getFavoritesWatcher,
];
