import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getPartnerOffers: null,
  getPartnerOffersSilent: null,
  getPartnerOffersDone: ['partnerOffers'],
  getPartnerOffersError: ['errorMessage'],
  generateCode: ['id'],
  generateCodeDone: ['claimCode'],
  generateCodeError: ['errorMessage'],
  setClaimCode: ['claimCode'],
  resetClaimCode: null,
  getPartnerVendors: ['featured'],
  getPartnerVendorsDone: ['partnerVendors'],
  getPartnerVendorsError: ['errorMessage'],
  trackGetPromotion: ['id'],
});

export const PartnerOffersTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  partnerOffers: [],
  partnerVendors: [],
  claimCode: null,
  isFetchingPartnerOffers: false,
  isFetchingPartnerOffersDone: false,
  fetchingPartnerOffersError: '',
  isGeneratingCode: false,
  isGeneratingCodeDone: false,
  generatingCodeError: '',
  isFetchingPartnerVendors: false,
  isFetchingPartnerVendorsDone: false,
  fetchingPartnerVendorsError: '',
});

const getPartnerOffers = (state) => state.merge({
  isFetchingPartnerOffers: true,
  isFetchingPartnerOffersDone: false,
  fetchingPartnerOffersError: '',
});

const getPartnerOffersSilent = (state) => state.merge({
  isFetchingPartnerOffers: false,
  isFetchingPartnerOffersDone: true,
  fetchingPartnerOffersError: '',
});

const getPartnerOffersDone = (state, { partnerOffers }) => state.merge({
  partnerOffers,
  isFetchingPartnerOffers: false,
  isFetchingPartnerOffersDone: true,
  fetchingPartnerOffersError: '',
});

const getPartnerOffersError = (state, { errorMessage }) => state.merge({
  isFetchingPartnerOffers: false,
  isFetchingPartnerOffersDone: true,
  fetchingPartnerOffersError: errorMessage,
});

const generateCode = (state) => state.merge({
  isGeneratingCode: true,
  isGeneratingCodeDone: false,
  generatingCodeError: '',
});

const generateCodeDone = (state, { claimCode }) => state.merge({
  claimCode,
  isGeneratingCode: false,
  isGeneratingCodeDone: true,
  generatingCodeError: '',
});

const generateCodeError = (state, { errorMessage }) => state.merge({
  isGeneratingCode: false,
  isGeneratingCodeDone: true,
  generatingCodeError: errorMessage,
});

const setClaimCode = (state, { claimCode }) => state.merge({
  claimCode,
  isGeneratingCode: false,
  isGeneratingCodeDone: true,
  generatingCodeError: '',
});

const resetClaimCode = (state) => state.merge({
  claimCode: '',
  isGeneratingCode: false,
  isGeneratingCodeDone: true,
  generatingCodeError: '',
});

const getPartnerVendors = (state) => state.merge({
  isFetchingPartnerVendors: true,
  isFetchingPartnerVendorsDone: false,
  fetchingPartnerVendorsError: '',
});

const getPartnerVendorsDone = (state, { partnerVendors }) => state.merge({
  partnerVendors,
  isFetchingPartnerVendors: false,
  isFetchingPartnerVendorsDone: true,
  fetchingPartnerVendorsError: '',
});

const getPartnerVendorsError = (state, { errorMessage }) => state.merge({
  isFetchingPartnerVendors: false,
  isFetchingPartnerVendorsDone: true,
  fetchingPartnerVendorsError: errorMessage,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PARTNER_OFFERS]: getPartnerOffers,
  [Types.GET_PARTNER_OFFERS_SILENT]: getPartnerOffersSilent,
  [Types.GET_PARTNER_OFFERS_DONE]: getPartnerOffersDone,
  [Types.GET_PARTNER_OFFERS_ERROR]: getPartnerOffersError,
  [Types.GENERATE_CODE]: generateCode,
  [Types.GENERATE_CODE_DONE]: generateCodeDone,
  [Types.GENERATE_CODE_ERROR]: generateCodeError,
  [Types.SET_CLAIM_CODE]: setClaimCode,
  [Types.RESET_CLAIM_CODE]: resetClaimCode,
  [Types.GET_PARTNER_VENDORS]: getPartnerVendors,
  [Types.GET_PARTNER_VENDORS_DONE]: getPartnerVendorsDone,
  [Types.GET_PARTNER_VENDORS_ERROR]: getPartnerVendorsError,
});
