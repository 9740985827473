import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import AutocompleteActions, { AutocompleteTypes } from 'reducers/autocomplete';
import SearchAutocompleteApi from 'apis/supremeGolfApi/SearchAutocompleteApi';
import { HEADER_ROW, ERROR_ROW, CURRENT_LOCATION } from 'utils/constants';

export const headerRow = (message) => ({
  label: message,
  ...HEADER_ROW,
});

export const errorRow = (message) => ([{
  label: message,
  ...ERROR_ROW,
}]);

export const matchWithCurrentLocation = (searchText) => (
  CURRENT_LOCATION.label.toLocaleLowerCase()
    .search(searchText && searchText.toLocaleLowerCase()) !== -1
);

export function* requestSearchSuggestionsHandler({ searchText }) {
  try {
    const pathname = yield select(({ router }) => router?.location?.pathname);
    const { latitude, longitude } = yield select(({ location }) => ({
      latitude: location.lat,
      longitude: location.lon,
    }));

    const response = yield call(
      SearchAutocompleteApi.getSearchSuggestions,
      searchText,
      latitude,
      longitude,
      pathname === '/test-new-search-logic',
    );

    const searchSuggestions = response.map((search) => {
      if (search.type !== 'Course') return search;
      const hierarchizedUrl = search.hierarchizedUrl.replace(/\/(?=[^/]*$)/, '?searchedCourse=');
      return { ...search, hierarchizedUrl };
    });

    if (searchSuggestions.length > 0) {
      searchSuggestions.unshift(headerRow('Search Results'));
    }

    if (matchWithCurrentLocation(searchText)) {
      const { lat, lon, hierarchizedUrl } = yield select((state) => state.location);
      if (lat && lon) {
        searchSuggestions.unshift({
          ...CURRENT_LOCATION,
          latitude: lat,
          longitude: lon,
          hierarchizedUrl,
        });
      }
    }

    if (searchSuggestions.length) {
      yield put(AutocompleteActions.getSearchSuggestionsDone(searchSuggestions));
    } else {
      yield put(AutocompleteActions.getSearchSuggestionsDone(
        errorRow('We couldn\'t find results for your search'),
      ));
    }
  } catch (error) {
    yield put(AutocompleteActions.getSearchSuggestionsError(error.message));
  }
}

function* requestSearchSuggestionsWatcher() {
  yield takeLatest(AutocompleteTypes.GET_SEARCH_SUGGESTIONS, requestSearchSuggestionsHandler);
}

export default [
  requestSearchSuggestionsWatcher,
];
