import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  subscribe: ['email', 'zipCode', 'gRecaptchaResponseData', 'callback'],
  subscribeDone: null,
  subscribeError: ['message'],
});

export const SubscriptionsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
  success: false,
});

const subscribe = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const subscribeDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  success: true,
});

const subscribeError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
  success: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBSCRIBE]: subscribe,
  [Types.SUBSCRIBE_DONE]: subscribeDone,
  [Types.SUBSCRIBE_ERROR]: subscribeError,
});
