export const isWebView = () => {
  const { navigator } = window;
  const { userAgent } = navigator;
  const normalizedUserAgent = userAgent.toLowerCase();
  const { standalone } = navigator;

  const isIos = /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const isAndroid = /android/.test(normalizedUserAgent);
  const isSafari = /safari/.test(normalizedUserAgent);

  return (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);
};

export default isWebView;
