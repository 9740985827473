import {
  takeLatest, select, put, getContext, call,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import qs from 'qs';
import humps from 'humps';
import moment from 'moment';
import SearchAutocompleteApi from 'apis/supremeGolfApi/SearchAutocompleteApi';

import SearchParamsActions, { SearchParamsTypes } from 'reducers/searchParams';
import TrackingActions from 'reducers/tracking';
import CheckoutActions from 'reducers/checkout';
import TimeSlotsActions from 'reducers/timeSlots';
import FlowActions from 'reducers/flow';
import {
  CHECKOUT,
  DEALS,
  EXPLORE_PREFIX,
  RATE_TYPES,
  SECTION_COURSES,
  SELECT_PROVIDER,
} from 'utils/routes';
import { routerActionHelper, matchRoute } from 'utils/routeHelpers';
import DateHelper from 'utils/dateHelper';
import { goToSignInHandler } from './session';

function* getCommonTrackingDetails(values) {
  const { course } = yield select(({ courseDetail }) => courseDetail);
  const { firstName, lastName, id: userId } = yield select(({ profile }) => profile);
  const iterable = yield getContext('iterable');
  const iterableTracking = yield call(iterable.getTrackingParams);
  const trackingPhotos = course?.photos?.map((photo) => photo?.photoMediumUrl);

  return {
    campaignId: iterableTracking?.campaign_id,
    templateId: iterableTracking?.template_id,
    messageId: iterableTracking?.message_id,
    userId,
    courseURL: course?.courseUrl,
    firstName,
    lastName,
    dateOfPlay: values?.dateFilter ? moment(values.dateFilter).format('yyyy-MM-DD') : null,
    isHotDeal: values?.dealsOnlyFilter,
    platform: 'web',
    teeOffAtLocal: DateHelper.formatDate(
      moment(values.dateFilter)
        .add(moment(values.timeSlotFilter, '"HH:mm A"').hour(), 'hours')
        .add(moment(values.timeSlotFilter, '"HH:mm A"').minutes(), 'minutes'),
      DateHelper.COMPLETE_FORMAT,
      false,
    ),
    course: {
      url: course?.url,
      courseID: course?.id,
      slug: course?.slug,
      name: course?.name,
      address: {
        formatted: course?.formattedAddress,
        city: course?.addressCity,
        state: course?.addressState,
        zipcode: course?.addressZipcode,
      },
      hasRealPhoto: course?.hasRealPhoto,
      photoUrl: trackingPhotos,
      reviewUrl: course?.courseReviewsUrl,
      description: course?.description,
      contactPhone: course?.phone,
      numHoles: course?.numHoles,
      parScore: course?.parScore,
      slope: course?.slope,
      rating: course?.rating?.value,
      distance: course?.distance,
      zipcode: course?.addressZipcode,
      isNationalMemberCourse: course?.hasNetworkMembership,
      isBarstoolBest: course?.isBarstoolBest ?? false,
      isForeplayReviewed: !!course?.hasForeplayReviewsImageUrl ?? false,
      isBarstoolVip: course?.isBarstoolVip ?? false,
    },
    teeOffAt: values.teeOffAtFilter,
  };
}

function* getSearchAndFilterValues() {
  const filterValues = yield select(({ search }) => search.searchParams.filterValues);
  const searchValues = yield select(({ search }) => search.searchParams.searchValues);
  return {
    cartFilter: filterValues.cart === false ? 'false' : filterValues.cart,
    dateFilter: filterValues.dateFilter,
    dateSearch: searchValues?.date,
    dealsOnlyFilter: filterValues.dealsOnly,
    foreplayReviewedOnlySearch: searchValues?.foreplayReviewedOnly,
    holesFilter: filterValues.holesFilter,
    holesSearch: searchValues?.holes,
    hotDealsSearch: searchValues?.hotDealsSearch,
    isBestDealFilter: filterValues.isBestDeal,
    isPrepaidOnlyFilter: filterValues.isPrepaidOnly,
    isBarstoolBestOnlyFilter: filterValues.isBarstoolBestOnly,
    isBarstoolVipOnlyFilter: filterValues.isBarstoolVipOnly,
    networkMembershipOnlyFilter: filterValues.networkMembershipOnly,
    isRecommendedDateFilter: filterValues.isRecommendedDate,
    locationFilter: filterValues.locationFilter,
    locationRangeFilter: filterValues.locationRange,
    locationSearch: searchValues?.location,
    nearMeSearch: searchValues?.nearMeSearch,
    playersFilter: filterValues.playersFilter,
    playersRangeFilter: filterValues.playersRange,
    playersSearch: searchValues?.players,
    previousRateTypesFilter: filterValues.previousRateTypes,
    priceFilter: filterValues.price,
    rateFilter: filterValues.rate,
    rateTypesFilter: filterValues.rateTypes,
    searchedCourseFilter: filterValues.searchedCourse,
    selectedCartFilter: filterValues.selectedCart,
    selectedHolesFilter: filterValues.selectedHoles,
    selectedRateTypeFilter: filterValues.selectedRateType,
    skipProviderFilter: filterValues.skipProvider,
    skipRateFilter: filterValues.skipRate,
    teeOffAtFilter: filterValues.teeOffAt,
    timeFilter: filterValues.time,
    timeSlotFilter: filterValues.timeSlot,
  };
}

function* trackSearchEvents(values, location, onlySearch) {
  const gtm = yield getContext('gtm');
  yield call(gtm.trackEvent, {
    eventCategory: 'search',
    eventAction: 'click',
    eventLabel: location.slug,
    event: 'search-tee-times',
  });
  if (!onlySearch) return;
  const iterable = yield getContext('iterable');
  const iterableTracking = yield call(iterable.getTrackingParams);
  const { firstName, lastName, id: userId } = yield select(({ profile }) => profile);

  yield put(TrackingActions.trackEvent('search', {
    campaignId: iterableTracking?.campaign_id,
    templateId: iterableTracking?.template_id,
    messageId: iterableTracking?.message_id,
    userId,
    firstName,
    lastName,
    searchResultsURL: values?.locationSearch?.hierarchizedUrl,
    dateOfPlay: values?.dateSearch ? moment(values.dateSearch).format('yyyy-MM-DD') : null,
    platform: 'web',
    holes: values.holesSearch,
    players: values.playersSearch,
    term: values.locationSearch.label === 'Current Location' ? location.currentLocationLabel : values.locationSearch.label,
    [values.locationSearch.type.toLowerCase()]: {
      slug: values.locationSearch.label === 'Current Location' ? location.currentLocationSlug : values.locationSearch.slug,
      name: values.locationSearch.label === 'Current Location' ? location.currentLocationName : values.locationSearch.name,
    },
  }));
}

function* trackSearchRateTypesEvents(values) {
  const gtm = yield getContext('gtm');
  yield call(gtm.trackEvent, {
    eventCategory: 'search',
    eventAction: 'click',
    eventLabel: values.locationFilter.slug,
    event: 'search-rate-types',
  });

  const commonTrackingData = yield getCommonTrackingDetails(values);

  yield put(TrackingActions.trackEvent('teeTimeClicked', {
    ...commonTrackingData,
    players: values.playersFilter,
    holes: values.holesFilter,
    cart: values.cartFilter,
    showDealsOnly: values.dealsOnlyFilter,
  }));
}

function* trackSearchProvidersEvents(values) {
  const gtm = yield getContext('gtm');
  const commonGtmParams = {
    eventCategory: 'search',
    eventAction: 'click',
    eventLabel: values.locationFilter.slug,
  };
  yield call(gtm.trackEvent, { ...commonGtmParams, event: 'search-providers' });
  if (values.isBestDealFilter) {
    const commonTrackingData = yield getCommonTrackingDetails(values);
    yield put(TrackingActions.trackEvent('bestDealClicked', {
      ...commonTrackingData,
    }));
    yield call(gtm.trackEvent, { ...commonGtmParams, event: 'bestDealClicked' });
    return;
  }
  if (!values.skipRateFilter) {
    const commonTrackingData = yield getCommonTrackingDetails(values);

    yield put(TrackingActions.trackEvent('rateTypeClicked', {
      ...commonTrackingData,
      players: values.playersFilter,
      holes: values.selectedHolesFilter,
      isHotDeal: values.selectedRateTypeFilter.majorRateType === 'lightning',
      cart: values.selectedCartFilter === 'true',
      rateType: {
        major: values.selectedRateTypeFilter.majorRateType,
        minor: values.selectedRateTypeFilter.minorRateType,
      },
    }));
  }
}

function* trackPerformCheckoutPreparationEvents({
  values, reservationUrl, prepaymentRuleId, players, provider, sourceProvider, trackEvent,
}) {
  const gtm = yield getContext('gtm');
  yield call(gtm.trackEvent, trackEvent);
  if (!values.skipProviderFilter) {
    const commonTrackingData = yield getCommonTrackingDetails(values);
    yield put(TrackingActions.trackEvent('providerClicked', {
      ...commonTrackingData,
      players,
      holes: values.selectedHolesFilter,
      isHotDeal: values.selectedRateTypeFilter.majorRateType === 'lightning',
      cart: values.selectedCartFilter,
      rateType: {
        major: values.selectedRateTypeFilter.majorRateType,
        minor: values.selectedRateTypeFilter.minorRateType,
      },
      isPrepaid: !!prepaymentRuleId,
      displayProvider: { name: provider.name, code: provider.code },
      sourceProvider: { name: sourceProvider.name, code: sourceProvider.code },
      isLinkOff: !!reservationUrl,
    }));
  }
}

export function* requestSearchParamsHandler({
  forcedTargetEntity,
  routerAction,
  onlySearch,
  callback,
}) {
  const values = yield getSearchAndFilterValues();

  const query = yield select((state) => state.router?.location.query);
  let goPlayGolfParams = {};
  if (query?.utm_campaign === 'goplaygolf') {
    goPlayGolfParams = {
      showDisclaimer: query?.showDisclaimer,
    };
  }
  const extraQueries = {};
  if (query?.teeTimeId) extraQueries.teeTimeId = query.teeTimeId;
  if (query?.isAnchoredTeeTime) extraQueries.isAnchoredTeeTime = query.isAnchoredTeeTime;

  const queryString = qs.stringify({
    searchedCourse: values.searchedCourseFilter,
    cart: values.cartFilter,
    date: DateHelper.formatDateWithoutTime(onlySearch ? values.dateSearch : values.dateFilter),
    dealsOnly: values.dealsOnlyFilter,
    foreplayReviewedOnly: values.foreplayReviewedOnlySearch,
    holes: onlySearch ? values.holesSearch : values.holesFilter,
    hotDealsSearch: values.hotDealsSearch,
    isPrepaidOnly: values.isPrepaidOnlyFilter,
    barstoolBestOnly: values.isBarstoolBestOnlyFilter,
    marketingPromotionOnly: values.isBarstoolVipOnlyFilter,
    networkMembershipOnly: values.networkMembershipOnlyFilter,
    isRecommendedDate: values.isRecommendedDateFilter,
    locationRange: values.locationRangeFilter,
    nearMeSearch: values.nearMeSearch,
    onlySearch: values.onlySearch,
    players: onlySearch ? values.playersSearch : values.playersFilter,
    playersRange: values.playersRangeFilter,
    previousRateTypes: values.previousRateTypesFilter,
    price: values.priceFilter,
    rate: values.rateFilter,
    rateTypes: values.rateTypesFilter,
    time: values.timeFilter,
    ...goPlayGolfParams,
    cityId: values.locationSearch?.id,
    ...extraQueries,
  });

  const location = onlySearch ? values.locationSearch : values.locationFilter;
  if (onlySearch) {
    yield put(FlowActions.setfSearchedLocation(values.locationSearch));
    yield put(FlowActions.setfSearchedDate(values.dateSearch));
  }

  if (location) {
    yield trackSearchEvents(values, location, onlySearch);

    const searchLocation = values.locationSearch || location;
    switch (forcedTargetEntity || searchLocation.type) {
      case 'Current':
      case 'City': {
        yield routerActionHelper(
          routerAction,
          `${EXPLORE_PREFIX}${location.hierarchizedUrl}?${queryString}`,
        );
        break;
      }
      case 'Course': {
        yield put(TimeSlotsActions.setSortByTimeSlots('time'));
        const isAnchoredCourse = location?.hierarchizedUrl.includes('searchedCourse');
        yield routerActionHelper(
          routerAction,
          `${EXPLORE_PREFIX}${location.hierarchizedUrl}${isAnchoredCourse ? '&' : '?'}${queryString}`,
        );
        break;
      }
      case 'Deal': {
        yield put(push(`${DEALS}?${queryString}`));
        break;
      }
      case 'Zipcode': {
        const searchSuggestions = yield call(
          SearchAutocompleteApi.getSearchSuggestions,
          searchLocation.label,
        );

        if (searchSuggestions.length) {
          const suggestion = searchSuggestions.filter(
            (sugg) => sugg.type !== 'Zipcode',
          )[0];
          yield put(SearchParamsActions.setLocation(suggestion));
          yield put(
            SearchParamsActions.performSearch(undefined, undefined, true),
          );
        }
        break;
      }
      case 'Section': {
        yield routerActionHelper(
          routerAction,
          `${SECTION_COURSES.replace(':courseSectionSlug', location.slug)}?${queryString}`,
        );
        break;
      }
      default:
        break;
    }
    if (callback) callback();
  } else {
    yield put(SearchParamsActions.showSearchFormError(true));
  }
}

export function* requestSearchRateTypesParamsHandler() {
  const values = yield getSearchAndFilterValues();

  if (values.locationFilter) {
    yield trackSearchRateTypesEvents(values);

    const queryString = qs.stringify({
      cart: values.cartFilter,
      date: DateHelper.formatDateWithoutTime(values.dateFilter),
      dealsOnly: values.dealsOnlyFilter,
      holes: values.holesFilter,
      isPrepaidOnly: values.isPrepaidOnlyFilter,
      isRecommendedDate: values.isRecommendedDateFilter,
      players: values.playersFilter,
      playersRange: values.playersRangeFilter,
      previousRateTypes: values.previousRateTypesFilter,
      price: values.priceFilter,
      rate: values.rateFilter,
      rateTypes: values.rateTypesFilter,
      time: values.timeFilter,
      timeSlot: values.timeSlotFilter,
    });
    const path = `/rate-types/at/${values.locationFilter.slug}`;

    const goToSignIn = yield goToSignInHandler({
      path: `${path}?${queryString}`,
      newWindow: false,
      trackEvent: null,
    });
    if (goToSignIn) return;

    yield put(FlowActions.setfRateTypes({ URL: `${path}?${queryString}` }));
    sessionStorage.setItem('rateTypesURL', `${path}?${queryString}`);

    yield put(push(`${path}?${queryString}`));
  }
}

export function* requestSearchMembershipParamsHandler() {
  const values = yield getSearchAndFilterValues();
  const { pathname, search } = yield select((state) => state.router.location);
  const backToUrl = yield select(({ courseMembership }) => courseMembership.backToUrl);

  if (values.locationFilter.slug) {
    const queryString = qs.stringify({
      redirectToUrl: `${pathname}${search}&isRedirectedToMembership=true`,
      backToUrl,
    });
    const path = `/membership-page/at/${values.locationFilter.slug}?${queryString}`;
    yield put(push(path));
  }
}

export function* requestSearchProvidersParamsHandler() {
  const values = yield getSearchAndFilterValues();

  if (values.locationFilter) {
    yield trackSearchProvidersEvents(values);

    const queryString = qs.stringify({
      cart: values.cartFilter,
      date: DateHelper.formatDateWithoutTime(values.dateFilter),
      dealsOnly: values.dealsOnlyFilter,
      holes: values.holesFilter,
      isBestDeal: values.isBestDealFilter,
      isPrepaidOnly: values.isPrepaidOnlyFilter,
      isRecommendedDate: values.isRecommendedDateFilter,
      players: values.playersFilter,
      playersRange: values.playersRangeFilter,
      previousRateTypes: values.previousRateTypesFilter,
      price: values.priceFilter,
      rate: values.rateFilter,
      rateTypes: values.rateTypesFilter,
      selectedCart: values.selectedCartFilter,
      selectedHoles: values.selectedHolesFilter,
      selectedRateType: values.selectedRateTypeFilter,
      skipRate: values.skipRateFilter,
      time: values.timeFilter,
      timeSlot: values.timeSlotFilter,
    });

    const path = `/select-provider/at/${values.locationFilter.slug}`;

    yield put(FlowActions.setfProvider({ URL: `${path}?${queryString}` }));
    sessionStorage.setItem('providerURL', `${path}?${queryString}`);

    yield put(push(`${path}?${queryString}`));
  }
}

export function* requestPerformCheckoutPreparationParamsHandler({
  id,
  prepaymentRuleId,
  reservationUrl,
  provider,
  sourceProvider,
}) {
  const values = yield getSearchAndFilterValues();
  const players = !(typeof values.playersFilter === 'number') ? 1 : values.playersFilter;

  if (values.locationFilter) {
    const queryString = qs.stringify({
      cart: values.cartFilter,
      date: DateHelper.formatDateWithoutTime(values.dateFilter),
      dealsOnly: values.dealsOnlyFilter,
      holes: values.holesFilter,
      id,
      isRecommendedDate: values.isRecommendedDateFilter,
      isBestDeal: values.isBestDealFilter,
      players,
      playersRange: values.playersRangeFilter,
      prepaymentRuleId,
      previousRateTypes: values.previousRateTypesFilter,
      price: values.priceFilter,
      rate: values.rateFilter,
      rateType: values.rateTypeFilter,
      selectedCart: values.selectedCartFilter,
      selectedHoles: values.selectedHolesFilter,
      selectedRateType: values.selectedRateTypeFilter,
      skipProvider: values.skipProviderFilter,
      skipRate: values.skipRateFilter,
      time: values.timeFilter,
      timeSlot: values.timeSlotFilter,

    });
    const pathBasic = CHECKOUT.replace(':courseId', values.locationFilter.slug);
    const path = reservationUrl || `${pathBasic}?${queryString}`;

    const trackEvent = {
      eventCategory: 'booking',
      eventAction: reservationUrl ? 'link-off' : 'click',
      eventLabel: reservationUrl ? `tee time id: ${id}` : `book-it tee time id: ${id}`,
      event: reservationUrl ? 'clicks-book-it-link-off' : 'clicks-book-it',
    };

    const goToSignIn = yield goToSignInHandler({
      path,
      newWindow: !!reservationUrl,
      isLinkOff: !!reservationUrl,
      trackEvent,
    });
    if (goToSignIn) return;
    if (provider) {
      yield trackPerformCheckoutPreparationEvents({
        values, reservationUrl, prepaymentRuleId, players, provider, sourceProvider, trackEvent,
      });
    }

    if (reservationUrl) {
      let reservationUrlWithUtmParams = reservationUrl;
      const LS = yield getContext('localStorage');
      let utmParams = yield call(LS.getUtmParams);
      let rwgParams = yield call(LS.getRwgParams, true);
      utmParams = humps.decamelizeKeys(utmParams);
      utmParams = qs.stringify(utmParams, { skipNulls: true });
      rwgParams = qs.stringify(humps.decamelizeKeys(rwgParams), { skipNulls: true });

      reservationUrlWithUtmParams = `${reservationUrlWithUtmParams}${utmParams && `&${utmParams}`}${rwgParams && `&${rwgParams}`}`;

      window.open(reservationUrlWithUtmParams, '_blank');
      return;
    }

    yield put(FlowActions.setfCheckout({ URL: path }));
    sessionStorage.setItem('checkoutURL', path);
    yield put(push(path));
  }
}

export function* performFilterHandler() {
  const pathname = yield select((state) => state.router.location.pathname);
  const checkout = yield select((state) => state.checkout);
  const values = yield getSearchAndFilterValues();
  if (matchRoute(RATE_TYPES, pathname)) {
    yield put(SearchParamsActions.performRateTypeSearch());
  }
  if (matchRoute(SELECT_PROVIDER, pathname)) {
    yield put(SearchParamsActions.performProvidersSearch());
  }
  if (matchRoute(CHECKOUT, pathname)) {
    yield put(CheckoutActions.updatePrepare(
      values.playersFilter,
      checkout.preparedTeeTime.teeTimeId,
      checkout.preparedTeeTime.teeTimeReservationId,
      checkout.promoCode,
      checkout.rewardsPointsAmount,
      checkout.giftCardCode,
      checkout.giftCardAmount,
      checkout.preparedTeeTime.teeTime.prepaymentRuleId,
    ));
  }
}

export function* performGoBack({ backToUrl }) {
  if (backToUrl) {
    yield put(push(backToUrl));
  }
  // TODO: Handle dynamic backTo
}

function* requestSearchParamsWatcher() {
  yield takeLatest(
    SearchParamsTypes.PERFORM_SEARCH,
    requestSearchParamsHandler,
  );
}

function* requestSearchRateTypesParamsWatcher() {
  yield takeLatest(
    SearchParamsTypes.PERFORM_RATE_TYPE_SEARCH,
    requestSearchRateTypesParamsHandler,
  );
}

function* requestSearchMembershipParamsWatcher() {
  yield takeLatest(
    SearchParamsTypes.PERFORM_MEMBERSHIP_SEARCH,
    requestSearchMembershipParamsHandler,
  );
}

function* requestSearchProvidersParamsWatcher() {
  yield takeLatest(
    SearchParamsTypes.PERFORM_PROVIDERS_SEARCH,
    requestSearchProvidersParamsHandler,
  );
}

function* requestPerformCheckoutPreparationWatcher() {
  yield takeLatest(
    SearchParamsTypes.PERFORM_CHECKOUT_PREPARATION,
    requestPerformCheckoutPreparationParamsHandler,
  );
}

function* performFilterWatcher() {
  yield takeLatest(SearchParamsTypes.PERFORM_FILTER, performFilterHandler);
}

function* performGoBackWatcher() {
  yield takeLatest(SearchParamsTypes.GO_BACK, performGoBack);
}

export default [
  requestSearchParamsWatcher,
  requestSearchRateTypesParamsWatcher,
  requestSearchMembershipParamsWatcher,
  requestSearchProvidersParamsWatcher,
  requestPerformCheckoutPreparationWatcher,
  performFilterWatcher,
  performGoBackWatcher,
];
