import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getSearchSuggestions: ['searchText'],
  getSearchSuggestionsDone: ['searchSuggestions'],
  getSearchSuggestionsError: ['message'],
  resetSearchSuggestions: null,
});

export const AutocompleteTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
  searchSuggestions: [],
  searchText: '',
});

const resetSearchSuggestions = () => INITIAL_STATE;

const getSearchSuggestions = (state, { searchText }) => state.merge({
  isLoading: true,
  isError: false,
  searchText,
});

const getSearchSuggestionsDone = (state, { searchSuggestions }) => state.merge({
  isLoading: false,
  isError: false,
  searchSuggestions,
});

const getSearchSuggestionsError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_SEARCH_SUGGESTIONS]: resetSearchSuggestions,
  [Types.GET_SEARCH_SUGGESTIONS]: getSearchSuggestions,
  [Types.GET_SEARCH_SUGGESTIONS_DONE]: getSearchSuggestionsDone,
  [Types.GET_SEARCH_SUGGESTIONS_ERROR]: getSearchSuggestionsError,
});
