import { createReducer, createActions } from 'reduxsauce';
import { isWebView } from 'utils/userAgent';

import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  initApp: null,
  initAppDone: [],
  initAppError: ['message'],
  setIsUnderMaintenance: ['value'],
});

export const AppTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isUnderMaintenance: false,
  isError: false,
  isWebView: window.location?.search?.includes('webView=true') || isWebView(),
});

const initApp = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const initAppDone = (state) => state.merge({
  isLoading: false,
  isError: false,
});

const initAppError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const setIsUnderMaintenance = (state, { value }) => state.merge({
  isUnderMaintenance: value,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT_APP]: initApp,
  [Types.INIT_APP_DONE]: initAppDone,
  [Types.INIT_APP_ERROR]: initAppError,
  [Types.SET_IS_UNDER_MAINTENANCE]: setIsUnderMaintenance,
});
