import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import MarketingBannerActions, { MarketingBannerTypes } from 'reducers/marketingBanner';
import MarketingBannersApi from 'apis/supremeGolfApi/marketingBannersApi';

export function* requestMarketingBannerHandler({ context }) {
  try {
    const marketingBanner = yield call(MarketingBannersApi.getMarketingBannerFromContext, context);

    yield put(MarketingBannerActions.getMarketingBannerDone(marketingBanner));
  } catch (error) {
    yield put(MarketingBannerActions.getMarketingBannerDone(null));
  }
}

function* requestMarketingBannerWatcher() {
  yield takeLatest(
    MarketingBannerTypes.GET_MARKETING_BANNER,
    requestMarketingBannerHandler,
  );
}

export default [
  requestMarketingBannerWatcher,
];
