import SupremeGolfApiClient from './SupremeGolfApiClient';

const COURSE_REVIEWS_BASE = 'v6/course_reviews';
const COURSES_AUTOCOMPLETE_ENDPOINT = 'v6/course_reviews/courses';
const USERS_AUTOCOMPLETE_ENDPOINT = 'v6/course_reviews/users';

class CourseReviewsApi {
  static async getCourseReview({ reviewId }) {
    const response = await SupremeGolfApiClient.get(
      `${COURSE_REVIEWS_BASE}/${reviewId}`,
    );
    return response.data.courseReview;
  }

  static async getCourseReviews({
    courseId, page, sortBy, excludeIds, tagId,
  }) {
    const params = {
      page,
      sortBy,
      excludeIds,
      tagId: tagId || null,
    };

    const response = await SupremeGolfApiClient.get(
      `${COURSE_REVIEWS_BASE}/at/${courseId}`,
      params,
    );
    return {
      reviewsList: response.data.courseReviews,
      courseReviewsCount: response.data.courseReviewCount,
      hasMore: !!response.headers.xNextPage,
    };
  }

  static async helpfulness({ id, isHelpful }) {
    const params = {
      id,
      isHelpful,
    };

    await SupremeGolfApiClient.post(`${COURSE_REVIEWS_BASE}/helpful`, params);
  }

  static async reportIt({ id }) {
    const params = {
      id,
    };

    const response = await SupremeGolfApiClient.post(
      `${COURSE_REVIEWS_BASE}/report_it`,
      params,
    );
    return response.data.courseReviews;
  }

  static async createCourseReview({
    courseId,
    courseRating,
    title,
    comments,
  }) {
    const params = {
      courseId,
      courseRating,
      title,
      comments,
    };
    await SupremeGolfApiClient.post(COURSE_REVIEWS_BASE, params);
  }

  static async getModeratorReviews(page, scope, courseSlug, from, to, userId) {
    const params = {
      page,
      scope,
      courseSlug,
      from,
      to,
      userId,
    };

    const response = await SupremeGolfApiClient.get(
      `${COURSE_REVIEWS_BASE}`,
      params,
    );

    return response.data.courseReviews;
  }

  static async getCoursesAutocomplete(searchText) {
    const response = await SupremeGolfApiClient.get(
      COURSES_AUTOCOMPLETE_ENDPOINT,
      {
        q: searchText,
        withReviews: true,
      },
    );

    const { data } = response;
    return data.courses;
  }

  static async getUsersAutocomplete(searchText) {
    const response = await SupremeGolfApiClient.get(
      USERS_AUTOCOMPLETE_ENDPOINT,
      {
        q: searchText,
      },
    );

    const { data } = response;
    return data.users;
  }

  static async setReviewStatus(id, isApproved) {
    const params = {
      id,
      isApproved,
    };

    const response = await SupremeGolfApiClient.patch(
      `${COURSE_REVIEWS_BASE}/${id}`,
      params,
    );

    return response.data;
  }
}

export default CourseReviewsApi;
