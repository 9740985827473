import SupremeGolfApiClient from './SupremeGolfApiClient';

const MARKETING_BANNER_ENDPOINT = 'v6/marketing_banner/';

class MarketingBannersApi {
  static async getMarketingBannerFromContext(context) {
    const response = await SupremeGolfApiClient.get(
      `${MARKETING_BANNER_ENDPOINT}?context=${context}`,
    );
    const { data } = response;

    return data.marketingBanner;
  }
}

export default MarketingBannersApi;
