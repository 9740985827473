import SupremeGolfApiClient from './SupremeGolfApiClient';

const FAVORITE_CITIES_ENDPOINT = 'v6/favorite_cities';

class FavoriteCitiesApi {
  static async addFavoriteCity(id) {
    const response = await SupremeGolfApiClient.post(FAVORITE_CITIES_ENDPOINT, {
      id,
    });
    const { data } = response;
    return data;
  }

  static async removeFavoriteCity(id) {
    const response = await SupremeGolfApiClient.delete(
      FAVORITE_CITIES_ENDPOINT,
      { id },
    );
    const { data } = response;
    return data;
  }

  static async getFavorites() {
    const response = await SupremeGolfApiClient.get(FAVORITE_CITIES_ENDPOINT);
    const { data } = response;
    return data.cities;
  }
}

export default FavoriteCitiesApi;
