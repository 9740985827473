export const DEFAULT_HOURS_BEFORE_SUNSET = 2;

// ERRORs CONSTANTS

export const CITY_NOT_FOUND = {
  code: 412,
  message: "The city for this alert doesn't seem to exits anymore.",
};

export const COURSE_NOT_FOUND = {
  code: 413,
  message: "The course for this alert doesn't seem to exits anymore.",
};

export const INVALID_FREQUENCY = {
  code: 414,
  message: 'Please select the frequency you want to be notified.',
};

export const INVALID_PLAYERS_QTY = {
  code: 415,
  message: 'Please select at least one player quantity to set the alert.',
};

export const INVALID_DAYS = {
  code: 416,
  message: 'Please provide on which days you want to set the alert.',
};

export const INVALID_HOLES_NUMBER = {
  code: 417,
  message: 'Please select the number of holes you want.',
};

export const UNABLE_TO_UPDATE = {
  code: 422,
  message: 'Ups! There has been an error trying to update the alert.',
};
