import { call, put, takeLatest } from 'redux-saga/effects';

import LocationActions, { LocationTypes } from 'reducers/location';
import FlowActions from 'reducers/flow';
import CitiesApi from 'apis/supremeGolfApi/CitiesApi';
import LocationHelper from 'utils/locationHelper';

export function* requestLocationHandler() {
  try {
    const userLocation = yield call(LocationHelper.getUserLocation);
    const { latitude: lat, longitude: lon } = userLocation.coords;
    const city = yield call(CitiesApi.getCityByCoord, lat, lon);
    yield put(FlowActions.setfUserLocation(city));
    yield put(LocationActions.getLocationDone({
      city: city.name,
      hierarchizedUrl: city.hierarchizedUrl,
      label: `${city.name}-${city.state?.name}`,
      lat,
      lon,
      slug: city.slug,
      state: city.state?.name,
    }));
  } catch (error) {
    yield put(LocationActions.getLocationError(error.message));
  }
}

function* requestLocationWatcher() {
  yield takeLatest(LocationTypes.GET_LOCATION, requestLocationHandler);
}

export default [
  requestLocationWatcher,
];
