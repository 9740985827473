import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { injectStore } from 'apis/ApiClient';

import rootReducer from 'reducers/rootReducer';
import rootSaga from 'sagas/rootSaga';
import LocalStorage from 'utils/LocalStorage';
import Gtm from 'utils/tracking/gtm';
import Iterable from 'utils/tracking/iterable';
import history from './history';

const sagaMiddleware = createSagaMiddleware({
  context: {
    localStorage: LocalStorage,
    gtm: Gtm,
    iterable: Iterable,
  },
});

const enhancer = applyMiddleware(
  routerMiddleware(history),
  sagaMiddleware,
);

const store = createStore(
  rootReducer(history),
  composeWithDevTools(enhancer),
);

sagaMiddleware.run(rootSaga);

export default store;

injectStore(store);
