import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setActiveNavItem: ['activeNavItem'],
});

export const NavbarTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  activeNavItem: 'search',
});

const setActiveNavItem = (state, { activeNavItem }) => state.merge({
  activeNavItem,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ACTIVE_NAV_ITEM]: setActiveNavItem,
});
