import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  login: [
    'email',
    'password',
    'rememberMe',
    'callback',
  ],
  loginDone: ['response'],
  loginError: ['message'],
  resetError: [],
  restoreSessionDone: ['response'],
  restoreSessionError: ['message'],
  restoreSession: [],
  loginFinish: ['finish'],
  redirectTo: ['redirectTo'],
  signOut: ['withRedirection'],
  signOutDone: [],
  signOutError: ['message'],
  redirectIfSession: ['redirectTo'],
  unlockError: [],
  unlockErrorDone: [],
  getSessionToken: [],
  getSessionTokenDone: [],
  getSessionTokenError: ['error'],
});

export const SessionTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
  response: false,
  redirectTo: {
    path: '',
    newWindow: false,
    trackEvent: null,
    action: null,
  },
  isLoadingSession: false,
  isErrorSession: false,
  responseSession: null,
  restoreSessionFinished: false,
  gettingSessionToken: false,
  gettingSessionTokenDone: false,
  gettingSessionTokenError: null,
});

const login = (state) => state.merge({
  isLoading: true,
  isError: false,
  response: false,
});

const loginDone = (state, { response }) => state.merge({
  isLoading: false,
  isError: false,
  response,
});

const setRedirectTo = (state, { redirectTo }) => state.merge({
  redirectTo,
});

const loginError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  response: false,
  errorMessage: message,
});

const resetError = (state) => state.merge({ errorMessage: '' });

const restoreSession = (state) => state.merge({
  isLoadingSession: true,
  isErrorSession: false,
  responseSession: null,
  restoreSessionFinished: false,
});

const restoreSessionDone = (state, { response }) => state.merge({
  isLoadingSession: false,
  isErrorSession: false,
  responseSession: response,
  restoreSessionFinished: true,
});

const restoreSessionError = (state, { message }) => state.merge({
  isLoadingSession: false,
  isErrorSession: true,
  responseSession: false,
  errorMessage: message,
  restoreSessionFinished: true,
});

const loginFinish = (state) => state.merge({
  response: false,
});

const signOut = (state) => state.merge({
  isLoading: true,
  isError: false,
  response: false,
});

const signOutDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  response: false,
});

const signOutError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  response: false,
  errorMessage: message,
});

const unlockError = (state) => state.merge({
  showUnlockErrorMessage: true,
});

const unlockErrorDone = (state) => state.merge({
  showUnlockErrorMessage: false,
});

const getSessionToken = (state) => state.merge({
  gettingSessionToken: true,
});

const getSessionTokenDone = (state) => state.merge({
  gettingSessionToken: false,
  gettingSessionTokenDone: true,
});

const getSessionTokenError = (state, { error }) => state.merge({
  gettingSessionToken: false,
  gettingSessionTokenDone: true,
  gettingSessionTokenError: error,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN]: login,
  [Types.LOGIN_DONE]: loginDone,
  [Types.LOGIN_ERROR]: loginError,
  [Types.RESET_ERROR]: resetError,
  [Types.RESTORE_SESSION]: restoreSession,
  [Types.RESTORE_SESSION_DONE]: restoreSessionDone,
  [Types.RESTORE_SESSION_ERROR]: restoreSessionError,
  [Types.LOGIN_FINISH]: loginFinish,
  [Types.REDIRECT_TO]: setRedirectTo,
  [Types.SIGN_OUT]: signOut,
  [Types.SIGN_OUT_DONE]: signOutDone,
  [Types.SIGN_OUT_ERROR]: signOutError,
  [Types.UNLOCK_ERROR]: unlockError,
  [Types.UNLOCK_ERROR_DONE]: unlockErrorDone,
  [Types.GET_SESSION_TOKEN]: getSessionToken,
  [Types.GET_SESSION_TOKEN_DONE]: getSessionTokenDone,
  [Types.GET_SESSION_TOKEN_ERROR]: getSessionTokenError,
});
