import MembershipApiClient from './MembershipApiClient';

const MEMBERSHIP_PURCHASE_BASE = 'MembershipPurchase';

class MembershipPurchaseApi {
  static async putMembershipPurchase(membershipSubscriptionId, creditCardId) {
    const response = await MembershipApiClient.put(
      `${MEMBERSHIP_PURCHASE_BASE}/updatePaymentMethod`,
      {
        creditCardId,
        membershipSubscriptionId,
      },
    );

    return response.data;
  }
}

export default MembershipPurchaseApi;
