module.exports = {
  theme: {
    colors: {
      primary: '#c72133',
      'primary-light': '#e04253',
      'primary-light-2': '#f8d7d7',
      secondary: '#18314f',
      'secondary-hover': '#f2f3f7',
      '50-black': '#7f7f7f',
      // Palette:
      first: '#000000',
      second: '#7f7f7f',
      third: '#7f7f7f',
      fourth: '#000000',
      fifth: '#18314f',
      sixth: '#ffffff',
      seventh: '#7f7f7f',
      eighth: '#c72133',
      ninth: '#7f7f7f',
      tenth: '#7f7f7f',
      eleventh: '#7f7f7f',
      twelfth: '#000000',
      thirteenth: '#7f7f7f',
      fourteenth: '#000000',
      fifteenth: '#7f7f7f',
      sixteenth: '#000000',
      seventeenth: '#18314f',
      eighteenth: '#18314f',
      nineteenth: '#7f7f7f',
      twentieth: '#000000',
      twentyFirst: '#041f3b',
      twentySecond: '#ffffff',
      twentyThird: '#c72133',
      twentyFourth: '#c72133',
      twentyFifth: '#feedea',
      twentySixth: '#7f7f7f',
      twentySeventh: '#1c48b4',
      twentyEighth: '#bfbfbf',
      twentyNinth: '#43576c',
      thirtieth: '#43576c',
      thirtyFirst: '#c0c7ce',
      thirtySecond: '#7f7f7f',
      thirtyThird: '#ffffff',
      thirtyFifth: '#c72133',
      thirtySixth: '#ffffff',
      thirtySeventh: '#e04253',
      thirtyEighth: '#13273f',
      thirtyNinth: '#7f7f7f',
      fortieth: '#18314f',
      fortyFirst: '#56565a',
      fortySecond: '#18314f',
      fortyThird: 'transparent',
      fortyFourth: '#adb9c5',
      fortyFifth: '#7f7f7f',
      fortySixth: '#c72133',
      fortySeventh: '#7f7f7f',
      fortyEighth: '#f1f3f7',
      fortyNinth: 'transparent',
      fiftieth: '#feedea',
      fiftyFirst: '#7f7f7f',
      fiftySecond: '#484848',
      fiftyThird: '#484848',
      fiftyFourth: '#1d1d22',
    },
  },
};
