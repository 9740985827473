import SupremeGolfApiClient from './SupremeGolfApiClient';

const VENDORS_PROMOTIONS = 'v6/vendor_promotions';
const VENDORS = 'v6/vendors';

class PartnerOffers {
  static async fetchPartnerOffers() {
    const response = await SupremeGolfApiClient.get(`${VENDORS_PROMOTIONS}/user`);

    return response.data.vendorPromotions;
  }

  static async generateClaimCode(id) {
    const response = await SupremeGolfApiClient.post(`${VENDORS_PROMOTIONS}/${id}/assign`, { id });
    return response.data;
  }

  static async fetchPartnerVendors(featured = false) {
    const response = await SupremeGolfApiClient.get(`${VENDORS}/public`, { featured });
    return response.data;
  }

  static async trackGetPromotion(id) {
    await SupremeGolfApiClient.post(`${VENDORS_PROMOTIONS}/${id}/track`, { id });
  }
}

export default PartnerOffers;
