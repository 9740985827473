import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getInviteDetails: ['id'],
  getInviteDetailsDone: ['invitation'],
  getInviteDetailsError: ['error'],
  acceptInvite: ['id', 'callback'],
  acceptInviteDone: null,
  acceptInviteError: ['error'],
  declineInvite: ['id', 'callback'],
  declineInviteDone: null,
  declineInviteError: ['error'],
  addGuestByEmail: ['guestInfo', 'callback'],
  addGuestByEmailDone: null,
  addGuestByEmailError: ['error'],
  inviteGuest: ['reservationId', 'emails', 'callback'],
  inviteGuestDone: null,
  inviteGuestError: ['error'],
  getRecentContacts: ['reservationId'],
  getRecentContactsDone: ['recentContacts'],
  getRecentContactsError: ['error'],
  setIsLoadingPageDone: ['value'],
  resetInviteStatus: null,
});

export const InviteTypes = Types;

export default Creators;

export const INITIAL_STATE = Immutable.from({
  invitation: {},
  recentContacts: {},
  isLoading: false,
  isLoadingPageDone: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  isAccepting: false,
  isAcceptingDone: false,
  isAcceptingError: '',
  isDeclining: false,
  isDecliningDone: false,
  isDecliningError: '',
  isAddingGuestByEmail: false,
  isAddingGuestByEmailDone: false,
  isAddingGuestByEmailError: '',
  isInviting: false,
  isInvitingDone: false,
  isInvitingError: '',
  isGettingContacts: false,
  isGettingContactsDone: false,
  isGettingContactsError: '',
});

const getInviteDetails = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
});

const getInviteDetailsDone = (state, { invitation }) => state.merge({
  invitation,
  isLoading: false,
  isDone: true,
  isError: false,
});

const getInviteDetailsError = (state, { error }) => state.merge({
  errorMessage: error,
  isLoading: false,
  isDone: true,
  isError: true,
});

const acceptInvite = (state) => state.merge({
  isAccepting: true,
  isAcceptingDone: false,
  isAcceptingError: '',
});

const acceptInviteDone = (state) => state.merge({
  isAccepting: false,
  isAcceptingDone: true,
  isAcceptingError: '',
});

const acceptInviteError = (state, { error }) => state.merge({
  isAccepting: false,
  isAcceptingDone: true,
  isAcceptingError: error,
});

const declineInvite = (state) => state.merge({
  isDeclining: true,
  isDecliningDone: false,
  isDecliningError: '',
});

const declineInviteDone = (state) => state.merge({
  isDeclining: false,
  isDecliningDone: true,
  isDecliningError: '',
});

const declineInviteError = (state, { error }) => state.merge({
  isDeclining: false,
  isDecliningDone: true,
  isDecliningError: error,
});

const addGuestByEmail = (state) => state.merge({
  isAddingGuestByEmail: true,
  isAddingGuestByEmailDone: false,
  isAddingGuestByEmailError: '',
});

const addGuestByEmailDone = (state) => state.merge({
  isAddingGuestByEmail: false,
  isAddingGuestByEmailDone: true,
  isAddingGuestByEmailError: '',
});

const addGuestByEmailError = (state, { error }) => state.merge({
  isAddingGuestByEmail: false,
  isAddingGuestByEmailDone: true,
  isAddingGuestByEmailError: error,
});

const inviteGuest = (state) => state.merge({
  isInviting: true,
  isInvitingDone: false,
  isInvitingError: '',
});

const inviteGuestDone = (state) => state.merge({
  isInviting: false,
  isInvitingDone: true,
  isInvitingError: '',
});

const inviteGuestError = (state, { error }) => state.merge({
  isInviting: false,
  isInvitingDone: true,
  isInvitingError: error,
});

const getRecentContacts = (state) => state.merge({
  isGettingContacts: false,
  isGettingContactsDone: false,
  isGettingContactsError: '',
});

const getRecentContactsDone = (state, { recentContacts }) => state.merge({
  recentContacts,
  isGettingContacts: false,
  isGettingContactsDone: true,
  isGettingContactsError: '',
});

const getRecentContactsError = (state, { error }) => state.merge({
  isGettingContacts: false,
  isGettingContactsDone: true,
  isGettingContactsError: error,
});

const setIsLoadingPageDone = (state, { value }) => state.merge({
  isLoadingPageDone: value,
});

const resetInviteStatus = (state) => state.merge(INITIAL_STATE);

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INVITE_DETAILS]: getInviteDetails,
  [Types.GET_INVITE_DETAILS_DONE]: getInviteDetailsDone,
  [Types.GET_INVITE_DETAILS_ERROR]: getInviteDetailsError,
  [Types.ACCEPT_INVITE]: acceptInvite,
  [Types.ACCEPT_INVITE_DONE]: acceptInviteDone,
  [Types.ACCEPT_INVITE_ERROR]: acceptInviteError,
  [Types.DECLINE_INVITE]: declineInvite,
  [Types.DECLINE_INVITE_DONE]: declineInviteDone,
  [Types.DECLINE_INVITE_ERROR]: declineInviteError,
  [Types.ADD_GUEST_BY_EMAIL]: addGuestByEmail,
  [Types.ADD_GUEST_BY_EMAIL_DONE]: addGuestByEmailDone,
  [Types.ADD_GUEST_BY_EMAIL_ERROR]: addGuestByEmailError,
  [Types.INVITE_GUEST]: inviteGuest,
  [Types.INVITE_GUEST_DONE]: inviteGuestDone,
  [Types.INVITE_GUEST_ERROR]: inviteGuestError,
  [Types.GET_RECENT_CONTACTS]: getRecentContacts,
  [Types.GET_RECENT_CONTACTS_DONE]: getRecentContactsDone,
  [Types.GET_RECENT_CONTACTS_ERROR]: getRecentContactsError,
  [Types.SET_IS_LOADING_PAGE_DONE]: setIsLoadingPageDone,
  [Types.RESET_INVITE_STATUS]: resetInviteStatus,
});
