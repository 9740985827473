import {
  call, put, takeLatest, select, getContext,
} from 'redux-saga/effects';

import UsersApi from 'apis/supremeGolfApi/UsersApi';
import ProgramApi from 'apis/membershipApi/ProgramApi';
import GetMembershipsApi from 'apis/membershipApi/GetMembershipsApi';
import MembershipActions, { MembershipTypes } from 'reducers/membership';
import { SG_CLUB } from 'utils/routes';
import { goToSignInHandler } from './session';

export function* redirectToMembershipHandler() {
  try {
    const goToSignIn = yield goToSignInHandler({
      path: SG_CLUB,
      newWindow: false,
      trackEvent: null,
      action: redirectToMembershipHandler.bind(this),
    });
    if (goToSignIn) return;

    const { oneTimeToken } = yield call(
      UsersApi.oneTimeToken,
    );
    const membership = yield select((state) => state.membership);
    const params = new URLSearchParams({
      billingPlan: membership.defaultMembershipBillingPlan.id,
      redirectSupremeUrl: `${membership.redirectTo ? membership.redirectTo : window.location.origin}`,
      oneTimeToken,
    });
    yield put(MembershipActions.redirectToMembershipDone());

    const gtm = yield getContext('gtm');

    yield call(gtm.trackEvent, {
      eventCategory: 'sg-club', eventAction: 'click', eventLabel: 'success', event: 'join-today',
    });

    window.location.href = `${process.env.REACT_APP_MEMBERSHIP_BASE_URL}/${process.env.REACT_APP_MEMBERSHIP_API_PROGRAM_SLUG}/membershipDetail/${membership.membershipId}?${params}`;
  } catch (error) {
    yield put(MembershipActions.redirectToMembershipError());
  }
}

export function* setProgramDataHandler() {
  try {
    const data = yield call(
      ProgramApi.programWithMembershipFromSlug,
      process.env.REACT_APP_MEMBERSHIP_API_PROGRAM_SLUG,
    );
    yield put(MembershipActions.setProgramDataDone(
      data.membershipId,
      data.membershipBillingPlans,
    ));
  } catch (error) {
    yield put(MembershipActions.setProgramDataError());
  }
}

export function* getMembershipsHandler() {
  try {
    const { memberships } = yield call(GetMembershipsApi.getMemberships);
    yield put(MembershipActions.getMembershipsDone(memberships));
  } catch (error) {
    yield put(MembershipActions.getMembershipsError());
  }
}

export function* redirectToManageMembershipHandler({ membershipUrl }) {
  try {
    const { oneTimeToken } = yield call(UsersApi.oneTimeToken);
    const url = new URL(membershipUrl);
    url.searchParams.append('oneTimeToken', oneTimeToken);
    window.open(url.toString(), '_blank');
  } catch (error) {
    yield put(MembershipActions.redirectToManageMembershipError());
  }
}

function* redirectToMembershipWatcher() {
  yield takeLatest(
    MembershipTypes.REDIRECT_TO_MEMBERSHIP,
    redirectToMembershipHandler,
  );
}

function* setProgramDataWatcher() {
  yield takeLatest(
    MembershipTypes.SET_PROGRAM_DATA,
    setProgramDataHandler,
  );
}

function* getMembershipsWatcher() {
  yield takeLatest(
    MembershipTypes.GET_MEMBERSHIPS,
    getMembershipsHandler,
  );
}

function* redirectToManageMembershipWatcher() {
  yield takeLatest(
    MembershipTypes.REDIRECT_TO_MANAGE_MEMBERSHIP,
    redirectToManageMembershipHandler,
  );
}

export default [
  redirectToMembershipWatcher,
  setProgramDataWatcher,
  getMembershipsWatcher,
  redirectToManageMembershipWatcher,
];
