import SupremeGolfApiClient from './SupremeGolfApiClient';

const COURSES_ENDPOINT = 'v6/courses';

class CourseMembershipApi {
  static async getCourseMembership(courseId) {
    const response = await SupremeGolfApiClient.get(
      `${COURSES_ENDPOINT}/${courseId}/membership-upsell`,
    );

    const { data } = response;
    return data;
  }
}

export default CourseMembershipApi;
