import SupremeGolfApiClient from './SupremeGolfApiClient';

const AVATAR_ENDPOINT = 'v6/users/avatar';

class AvatarApi {
  static async updateAvatar(formData) {
    try {
      const response = await SupremeGolfApiClient.post(AVATAR_ENDPOINT, formData, true);
      return response.status;
    } catch (error) {
      return error;
    }
  }
}

export default AvatarApi;
