import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';

import RateTypesActions, { RateTypesTypes } from 'reducers/rateTypes';
import flowActions from 'reducers/flow';
import SearchParamsActions from 'reducers/searchParams';
import TimeSlotsApi from 'apis/supremeGolfApi/TimeSlotsApi';
import DateHelper from 'utils/dateHelper';

export function* requestRateTypesHandler({ courseId, params }) {
  try {
    const {
      date,
      players: qty,
      holes: numHoles,
      price,
      time,
      cart: isRiding,
      rate,
      timeSlot,
      rateTypes,
      isPrepaidOnly,
    } = params;
    const [minHour, maxHour] = time || [null, null];
    const [minPrice, maxPrice] = price || [null, null];
    const searchParams = {
      courseId,
      date: date && DateHelper.formatDate(date),
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      isRiding,
      numHoles,
      timeSlot,
      rateTypes,
      isPrepaidOnly,
    };
    if (rate) {
      searchParams[rate] = true;
    }

    const teeTimeToken = yield select(({ flow }) => flow?.teeTimes?.authToken);
    const {
      summary,
      rates: rateTypesList,
      authToken,
    } = yield call(TimeSlotsApi.getRateTypes, searchParams, teeTimeToken);
    yield put(flowActions.setfRateTypes({ authToken }));
    sessionStorage.setItem('rateTypesToken', authToken);

    yield put(RateTypesActions.getRateTypesDone(summary, rateTypesList));
  } catch (error) {
    yield put(RateTypesActions.getRateTypesError(error.message));
    if (error?.response?.data?.error === 'Authorization Failed') {
      yield put(SearchParamsActions.performSearch());
    }
  }
}

function* requestRateTypesWatcher() {
  yield takeLatest(RateTypesTypes.GET_RATE_TYPES, requestRateTypesHandler);
}

export default [
  requestRateTypesWatcher,
];
