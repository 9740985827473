import { call, put, takeLatest } from 'redux-saga/effects';

import SiteApi from 'apis/supremeGolfApi/SiteApi';
import SiteActions, { SiteTypes } from 'reducers/site';

export function* fetchSiteStatus() {
  try {
    const data = yield call(SiteApi.fetchSiteStatus);
    sessionStorage.networkMembershipEnabled = data?.networkMembershipEnabled ? '1' : '0';
    sessionStorage.siteStatusExp = Date.now() + (15 * 60 * 1000);
    yield put(SiteActions.fetchSiteStatusDone(data));
  } catch (error) {
    yield put(SiteActions.fetchSiteStatusError(error.message));
  }
}

function* fetchSiteStatusWatcher() {
  yield takeLatest(SiteTypes.FETCH_SITE_STATUS, fetchSiteStatus);
}

export default [fetchSiteStatusWatcher];
