module.exports = {
  theme: {
    colors: {
      primary: '#ED3E49',
      'primary-light': '#FF414D',
      secondary: '#151517',
      'secondary-light': '#1F2022',
      // Palette:
      first: '#000000',
      second: '#ADB4B7',
      third: '#43576c',
      fourth: '#000000',
      fifth: '#002d43',
      sixth: '#ffffff',
      seventh: '#ADB4B7',
      eighth: '#ED3E49',
      ninth: '#ADB4B7',
      tenth: '#ADB4B7',
      eleventh: '#43576c',
      twelfth: '#000000',
      thirteenth: '#ADB4B7',
      fourteenth: '#000000',
      fifteenth: '#ADB4B7',
      sixteenth: '#000000',
      seventeenth: '#002d43',
      eighteenth: '#ED3E49',
      nineteenth: '#ADB4B7',
      twentieth: '#041f3b',
      twentyFirst: '#ADB4B7',
      twentySecond: '#ffffff',
      twentyThird: '#ED3E49',
      twentyFourth: '#ED3E49',
      twentyFifth: '#feedea',
      twentySixth: '#ADB4B7',
      twentySeventh: '#ED3E49',
      twentyEighth: '#bfbfbf',
      twentyNinth: '#43576c',
      thirtieth: '#000000',
      thirtyFirst: '#c0c7ce',
      thirtySecond: '#ADB4B7',
      thirtyThird: '#ffffff',
      thirtyFifth: '#ED3E49',
      thirtySixth: '#ffffff',
      thirtySeventh: '#151517',
      thirtyEighth: '#d6dce2',
      thirtyNinth: '#ADB4B7',
      fortieth: '#002d43',
      fortyFirst: '#56565a',
      fortySecond: '#151517',
      fortyThird: 'transparent',
      fortyFourth: '#adb9c5',
      fortyFifth: '#7f7f7f',
      fortySixth: '#ED3E49',
      fortySeventh: '#43576c',
      fortyEighth: '#f1f3f7',
      fortyNinth: 'transparent',
      fiftieth: '#feedea',
      fiftyFirst: '#ADB4B7',
      fiftySecond: '#484848',
      fiftyThird: '#484848',
      fiftyFourth: '#1d1d22',
    },
  },
};
