import SupremeGolfApiClient from './SupremeGolfApiClient';

const AFFILIATE_ENDPOINT = 'v6/status/affiliate';

class AffiliateApi {
  static async getAffiliate() {
    const response = await SupremeGolfApiClient.get(
      AFFILIATE_ENDPOINT,
    );

    return response.data;
  }
}

export default AffiliateApi;
