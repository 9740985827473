import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import ContestActions, { ContestTypes } from 'reducers/contest';
import ContestsApi from 'apis/supremeGolfApi/ContestsApi';

export function* requestContestHandler() {
  try {
    const data = yield call(ContestsApi.getOpenContest);
    const { contests, pinned } = data;

    yield put(ContestActions.getContestDone(contests, pinned));
  } catch (error) {
    yield put(ContestActions.getContestError(error.message));
  }
}

export function* requestContestOnlyHandler() {
  try {
    const data = yield call(ContestsApi.getOpenContest);
    const { contests } = data;

    yield put(ContestActions.getContestOnlyDone(contests));
  } catch (error) {
    yield put(ContestActions.getContestError(error.message));
  }
}

function* requestContestWatcher() {
  yield takeLatest(ContestTypes.GET_CONTEST, requestContestHandler);
}

function* requestContestOnlyWatcher() {
  yield takeLatest(ContestTypes.GET_CONTEST_ONLY, requestContestOnlyHandler);
}

export default [
  requestContestWatcher,
  requestContestOnlyWatcher,
];
