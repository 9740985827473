import DateHelper from 'utils/dateHelper';
import SupremeGolfApiClient from './SupremeGolfApiClient';

const COURSES_ENDPOINT = 'v6/courses';

class CoursesApi {
  static async getCourses(
    lat,
    lon,
    {
      date,
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      minRadius,
      maxRadius,
      isRiding,
      numHoles,
      rateTypes,
      ...searchParams
    },
  ) {
    const params = {
      lat,
      lon,
      date: DateHelper.formatDate(date),
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      minRadius,
      maxRadius,
      isRiding,
      numHoles,
      ...searchParams,
    };
    const response = await SupremeGolfApiClient.get(
      `${COURSES_ENDPOINT}/location_list`,
      params,
    );
    const { data } = response;

    return { courses: data.locationResults, banners: data?.banners || [] };
  }

  static async getSimilarCourses(courseId, {
    date,
    qty,
    minHour,
    maxHour,
    minPrice,
    maxPrice,
    minRadius,
    maxRadius,
    isRiding,
    numHoles,
    rateTypes,
    ...searchParams
  }) {
    const params = {
      date: DateHelper.formatDate(date),
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      minRadius,
      maxRadius,
      isRiding,
      numHoles,
      rateTypes,
      ...searchParams,
    };

    const response = await SupremeGolfApiClient.get(
      `${COURSES_ENDPOINT}/${courseId}/similar`,
      params,
    );

    const { data } = response;

    return data;
  }

  static async getCoursesByCityUrlHierarchy(
    hierarchizedUrl,
    {
      date,
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      minRadius,
      maxRadius,
      isRiding,
      numHoles,
      ...searchParams
    },
  ) {
    const params = {
      hierarchizedUrl,
      date: DateHelper.formatDate(date),
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      minRadius,
      maxRadius,
      isRiding,
      numHoles,
      ...searchParams,
      includeWithoutTeeTimes: true,
    };
    const response = await SupremeGolfApiClient.get(
      `${COURSES_ENDPOINT}/location_list`,
      params,
    );
    const { data } = response;
    const coursesWithTeeTimes = data.locationResults;
    const coursesWithoutTeeTimes = data.coursesWithoutTeeTimes || [];
    const courses = [...coursesWithTeeTimes, ...coursesWithoutTeeTimes];
    return { courses, banners: data?.banners || [] };
  }

  static async getSectionCourses(
    lat,
    lon,
    {
      courseSectionSlug,
      date,
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      minRadius,
      maxRadius,
      isRiding,
      numHoles,
      rateTypes,
      ...searchParams
    },
  ) {
    const params = {
      courseSectionSlug,
      lat,
      lon,
      date: DateHelper.formatDate(date),
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      minRadius,
      maxRadius,
      isRiding,
      numHoles,
      rateTypes,
      ...searchParams,
      includeWithoutTeeTimes: true,
    };
    const response = await SupremeGolfApiClient.get(
      `${COURSES_ENDPOINT}/location_list`,
      params,
    );
    const { data } = response;

    return data;
  }

  static async getCourse(courseId) {
    const response = await SupremeGolfApiClient.get(
      `${COURSES_ENDPOINT}/${courseId}`,
    );
    const { data } = response;
    return data;
  }
}

export default CoursesApi;
