import SupremeGolfApiClient from './SupremeGolfApiClient';

const REQUEST_RESET_PASSWORD_ENDPOINT = 'v6/users/password-reset';

class RequestResetPasswordApi {
  static async requestResetPassword(email) {
    const response = await SupremeGolfApiClient.post(
      REQUEST_RESET_PASSWORD_ENDPOINT,
      {
        email,
      },
    );
    const { data } = response;
    return data;
  }
}

export default RequestResetPasswordApi;
