import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';

import CourseDetailActions, { CourseDetailTypes } from 'reducers/courseDetail';
import CourseDetailApi from 'apis/supremeGolfApi/CourseDetailApi';

import DateHelper from 'utils/dateHelper';

export function* requestCourseDetailHandler({ courseId, iterableTracking }) {
  try {
    const search = yield select(({ router }) => router.location.search);
    const { date } = qs.parse(search, { ignoreQueryPrefix: true });
    const courseDetail = yield call(
      CourseDetailApi.getCourseDetail,
      courseId,
      DateHelper.formatDate(date, 'YYYY/MM/DD'),
      iterableTracking,
    );
    const courseScorecard = yield call(CourseDetailApi.getCourseScorecard, courseId);
    if (courseScorecard) {
      yield put(CourseDetailActions.getCourseScorecardDone(courseScorecard));
    }
    yield put(CourseDetailActions.getCourseDetailDone(courseDetail));
  } catch (error) {
    yield put(CourseDetailActions.getCourseDetailError(error.message));
  }
}

function* requestCourseDetailWatcher() {
  yield takeLatest(CourseDetailTypes.GET_COURSE_DETAIL, requestCourseDetailHandler);
}

function* requestCourseDetailSilentWatcher() {
  yield takeLatest(CourseDetailTypes.GET_COURSE_DETAIL_SILENT, requestCourseDetailHandler);
}

export default [
  requestCourseDetailWatcher,
  requestCourseDetailSilentWatcher,
];
