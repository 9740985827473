import { isMobileScreen } from 'utils/screenHelper';
import { DESKTOP_SIZE, MOBILE_SIZE } from 'utils/constants';
import SupremeGolfApiClient from './SupremeGolfApiClient';

const CONTESTS_ENDPOINT = 'v6/contests';
const OPEN_CONTEST_ENDPOINT = `${CONTESTS_ENDPOINT}`;

class ContestsApi {
  static async getOpenContest() {
    let bannerImageSize;

    if (isMobileScreen()) {
      bannerImageSize = MOBILE_SIZE;
    } else {
      bannerImageSize = DESKTOP_SIZE;
    }

    const response = await SupremeGolfApiClient.get(
      `${OPEN_CONTEST_ENDPOINT}`,
      {
        bannerImageSize,
      },
    );
    const { data } = response;

    return data;
  }
}

export default ContestsApi;
