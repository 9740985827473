import ApiClient from 'apis/ApiClient';
import LocalStorage from 'utils/LocalStorage';
import humps from 'humps';

class SupremeGolfApiClient extends ApiClient {
  static headers() {
    const token = LocalStorage.getToken();
    const sessionToken = LocalStorage.getSessionToken();
    const reqHeaders = {
      'X-Api-Key': process.env.REACT_APP_X_API_KEY,
    };
    if (token) {
      reqHeaders['X-Api-User-Token'] = token;
    }

    if (sessionToken) {
      reqHeaders['X-Api-Session'] = sessionToken;
    }

    return reqHeaders;
  }

  static withCredentials() {
    return true;
  }

  static apiBaseUrl() {
    return process.env.REACT_APP_SUPREME_GOLF_API_BASE_URL;
  }

  static formatParams(params) {
    return humps.decamelizeKeys(params);
  }
}

export default new SupremeGolfApiClient();
