import { createReducer, createActions } from 'reduxsauce';

import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getLocation: null,
  getLocationDone: ['location'],
  getLocationError: ['message'],
});

export const LocationTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  isDone: false,
  city: undefined,
  state: undefined,
  lat: undefined,
  lon: undefined,
});

const getLocation = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
});

const getLocationDone = (state, { location }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  ...location,
});

const getLocationError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LOCATION]: getLocation,
  [Types.GET_LOCATION_DONE]: getLocationDone,
  [Types.GET_LOCATION_ERROR]: getLocationError,
});
