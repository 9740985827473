import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  fetchSiteStatus: [],
  fetchSiteStatusDone: ['data'],
  fetchSiteStatusError: ['error'],
});

export const SiteTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  data: {
    networkMembershipEnabled: sessionStorage.networkMembershipEnabled === '1',
  },
  isFetchingStatus: false,
  isFetchingStatusDone: false,
  isFetchingStatusError: false,
  fetchingStatusError: '',
});

const fetchSiteStatus = (state) => state.merge({
  isFetchingStatus: true,
  isFetchingStatusDone: false,
  isFetchingStatusError: false,
  fetchingStatusError: '',
});

const fetchSiteStatusDone = (state, { data }) => state.merge({
  data,
  isFetchingStatus: false,
  isFetchingStatusDone: true,
  isFetchingStatusError: false,
  fetchingStatusError: '',
});

const fetchSiteStatusError = (state, { error }) => state.merge({
  isFetchingStatus: false,
  isFetchingStatusDone: false,
  isFetchingStatusError: true,
  fetchingStatusError: error,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_SITE_STATUS]: fetchSiteStatus,
  [Types.FETCH_SITE_STATUS_DONE]: fetchSiteStatusDone,
  [Types.FETCH_SITE_STATUS_ERROR]: fetchSiteStatusError,
});
