import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getMarketingBanner: ['context'],
  getMarketingBannerDone: ['marketingBanner'],
});

export const MarketingBannerTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({ marketingBanner: null });

const getMarketingBanner = (state) => state.merge({});

const getMarketingBannerDone = (state, { marketingBanner }) => state.merge({
  marketingBanner,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MARKETING_BANNER]: getMarketingBanner,
  [Types.GET_MARKETING_BANNER_DONE]: getMarketingBannerDone,
});
