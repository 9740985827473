import { createReducer, createActions } from 'reduxsauce';

import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  passwordChange: ['currentPassword', 'password', 'passwordConfirmation', 'callback'],
  passwordChangeDone: null,
  passwordChangeError: ['message'],
});

export const PasswordChangeTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const passwordChange = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const passwordChangeDone = (state) => state.merge({
  isLoading: false,
});

const passwordChangeError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PASSWORD_CHANGE]: passwordChange,
  [Types.PASSWORD_CHANGE_DONE]: passwordChangeDone,
  [Types.PASSWORD_CHANGE_ERROR]: passwordChangeError,
});
