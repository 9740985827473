import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getPage: ['slug'],
  getPageDone: ['content'],
  getPageError: ['message'],
});

export const PageTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  content: null,
});

const getPage = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const getPageDone = (state, { content }) => state.merge({
  isLoading: false,
  isError: true,
  content,
});

const getPageError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAGE]: getPage,
  [Types.GET_PAGE_DONE]: getPageDone,
  [Types.GET_PAGE_ERROR]: getPageError,
});
