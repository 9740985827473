import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  deleteCreditCard: ['id', 'callback'],
  deleteCreditCardDone: ['id'],
  deleteCreditCardError: ['message'],
  getCreditCards: [],
  getCreditCardsDone: ['creditCards'],
  getCreditCardsError: ['message'],
  resetCreditCards: null,
});

export const PaymentMethodsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDoneGet: false,
  isDeleting: false,
  isError: false,
  error: {
    cardId: null,
    message: '',
  },
  creditCards: [],
});

const deleteCreditCard = (state) => state.merge({
  isDeleting: true,
  isError: false,
  error: {
    cardId: null,
    message: '',
  },
});

const deleteCreditCardDone = (state, { id }) => state.merge({
  isDeleting: false,
  isError: false,
  creditCards: state.creditCards.filter((creditCard) => creditCard.id !== id),
});

const deleteCreditCardError = (state, { message }) => state.merge({
  isError: true,
  error: message,
});

const getCreditCards = (state) => state.merge({
  isLoading: true,
  isDoneGet: false,
  isError: false,
  error: {
    cardId: null,
    message: '',
  },
});

const getCreditCardsDone = (state, { creditCards }) => state.merge({
  isLoading: false,
  isDoneGet: true,
  isError: false,
  creditCards,
});

const getCreditCardsError = (state, { message }) => state.merge({
  isLoading: false,
  isDoneGet: true,
  isError: true,
  error: message,
});

const resetCreditCards = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_CREDIT_CARD]: deleteCreditCard,
  [Types.DELETE_CREDIT_CARD_DONE]: deleteCreditCardDone,
  [Types.DELETE_CREDIT_CARD_ERROR]: deleteCreditCardError,
  [Types.GET_CREDIT_CARDS]: getCreditCards,
  [Types.GET_CREDIT_CARDS_DONE]: getCreditCardsDone,
  [Types.GET_CREDIT_CARDS_ERROR]: getCreditCardsError,
  [Types.RESET_CREDIT_CARDS]: resetCreditCards,
});
