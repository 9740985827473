import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  addTeeTimePriceAlert: ['courseId', 'values'],
  addTeeTimePriceAlertDone: null,
  addTeeTimePriceAlertError: ['message'],
  editTeeTimePriceAlert: ['alertId', 'courseId', 'values'],
  editTeeTimePriceAlertDone: ['alert'],
  editTeeTimePriceAlertError: ['message'],
  removePriceAlert: ['courseId', 'alertId'],
  removePriceAlertDone: null,
  removePriceAlertError: ['message'],
  setShowPriceAlertModal: ['show'],
  setShowUpsellMembershipModal: ['show'],
  cleanLastAlertIdEdited: null,
});

export const CourseAlertsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  editTeeTimePriceAlertErrorMessage: '',
  lastAlertIdEdited: undefined,
  showPriceAlertModal: false,
  showUpsellMembershipModal: false,
});

const toggleTeeTimePriceAlert = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
});

const toggleTeeTimePriceAlertDone = (state) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
});

const toggleTeeTimePriceAlertError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const editTeeTimePriceAlert = (state) => state.merge({
  isLoading: true,
  isError: false,
  editTeeTimePriceAlertErrorMessage: '',
  lastAlertIdEdited: undefined,
});

const editTeeTimePriceAlertDone = (state, { alert }) => state.merge({
  isLoading: false,
  isError: false,
  lastAlertIdEdited: alert.id,
});

const cleanLastAlertIdEdited = (state) => state.merge({
  lastAlertIdEdited: undefined,
});

const editTeeTimePriceAlertError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  editTeeTimePriceAlertErrorMessage: message,
});

const setShowPriceAlertModal = (state, { show }) => state.merge({
  showPriceAlertModal: show,
});

const setShowUpsellMembershipModal = (state, { show }) => state.merge({
  showUpsellMembershipModal: show,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_TEE_TIME_PRICE_ALERT]: toggleTeeTimePriceAlert,
  [Types.ADD_TEE_TIME_PRICE_ALERT_DONE]: toggleTeeTimePriceAlertDone,
  [Types.ADD_TEE_TIME_PRICE_ALERT_ERROR]: toggleTeeTimePriceAlertError,
  [Types.EDIT_TEE_TIME_PRICE_ALERT]: editTeeTimePriceAlert,
  [Types.EDIT_TEE_TIME_PRICE_ALERT_DONE]: editTeeTimePriceAlertDone,
  [Types.EDIT_TEE_TIME_PRICE_ALERT_ERROR]: editTeeTimePriceAlertError,
  [Types.REMOVE_PRICE_ALERT]: toggleTeeTimePriceAlert,
  [Types.REMOVE_PRICE_ALERT_DONE]: toggleTeeTimePriceAlertDone,
  [Types.REMOVE_PRICE_ALERT_ERROR]: toggleTeeTimePriceAlertError,
  [Types.SET_SHOW_PRICE_ALERT_MODAL]: setShowPriceAlertModal,
  [Types.SET_SHOW_UPSELL_MEMBERSHIP_MODAL]: setShowUpsellMembershipModal,
  [Types.CLEAN_LAST_ALERT_ID_EDITED]: cleanLastAlertIdEdited,
});
