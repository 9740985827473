import { call, put, takeLatest } from 'redux-saga/effects';
import PartnerOffers from 'apis/supremeGolfApi/PartnerOffers';

import PartnerOffersActions, { PartnerOffersTypes } from 'reducers/partnerOffers';

export function* requestPartnerOffersHandler() {
  try {
    const partnerOffers = yield call(PartnerOffers.fetchPartnerOffers);

    yield put(PartnerOffersActions.getPartnerOffersDone(partnerOffers));
  } catch (error) {
    const errorMessage = error?.response?.data?.error || error?.message;
    yield put(PartnerOffersActions.getPartnerOffersError(errorMessage));
  }
}

export function* requestGenerateCodeHandler({ id }) {
  try {
    const claimCode = yield call(PartnerOffers.generateClaimCode, id);
    yield put(PartnerOffersActions.generateCodeDone(claimCode));
    yield put(PartnerOffersActions.getPartnerOffersSilent());
  } catch (error) {
    const errorMessage = error?.response?.data?.error || error?.message;
    yield put(PartnerOffersActions.generateCodeError(errorMessage));
  }
}

export function* requestTrackGetPromotionHandler({ id }) {
  yield call(PartnerOffers.trackGetPromotion, id);
}

export function* requestPartnerVendorsHandler({ featured }) {
  try {
    const data = yield call(PartnerOffers.fetchPartnerVendors, featured);

    yield put(PartnerOffersActions.getPartnerVendorsDone(data.vendors));
  } catch (error) {
    const errorMessage = error?.response?.data?.error || error?.message;
    yield put(PartnerOffersActions.getPartnerVendorsError(errorMessage));
  }
}

function* requestPartnerOffersWatcher() {
  yield takeLatest(PartnerOffersTypes.GET_PARTNER_OFFERS, requestPartnerOffersHandler);
}

function* requestPartnerOffersSilentWatcher() {
  yield takeLatest(PartnerOffersTypes.GET_PARTNER_OFFERS_SILENT, requestPartnerOffersHandler);
}

function* requestPartnerVendorsWatcher() {
  yield takeLatest(PartnerOffersTypes.GET_PARTNER_VENDORS, requestPartnerVendorsHandler);
}

function* requestGenerateCodeWatcher() {
  yield takeLatest(PartnerOffersTypes.GENERATE_CODE, requestGenerateCodeHandler);
}

function* requestGetPromotionWatcher() {
  yield takeLatest(PartnerOffersTypes.TRACK_GET_PROMOTION, requestTrackGetPromotionHandler);
}

export default [
  requestPartnerOffersWatcher,
  requestPartnerOffersSilentWatcher,
  requestPartnerVendorsWatcher,
  requestGenerateCodeWatcher,
  requestGetPromotionWatcher,
];
