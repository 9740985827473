import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import BestDealsActions, { BestDealsTypes } from 'reducers/bestDeals';
import TimeSlotsApi from 'apis/supremeGolfApi/TimeSlotsApi';
import DateHelper from 'utils/dateHelper';

export function* requestBestDealsHandler({ courseId, params }) {
  try {
    const { date } = params;

    const { teeTimes } = yield call(TimeSlotsApi.getFeaturedTimeSlots, {
      courseId,
      date: date && DateHelper.formatDate(date),
    });

    yield put(BestDealsActions.getBestDealsDone(teeTimes));
  } catch (error) {
    yield put(BestDealsActions.getBestDealsError(error.message));
  }
}

function* requestBestDealsWatcher() {
  yield takeLatest(
    BestDealsTypes.GET_BEST_DEALS,
    requestBestDealsHandler,
  );
}

export default [
  requestBestDealsWatcher,
];
