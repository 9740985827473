
/* eslint-disable global-require, import/no-dynamic-require */
const merge = require('lodash/merge');
const mainTheme = require('./tailwind-main-theme');

exports.getAppConfig = (appName) => {
  let customTheme = {};
  let features = {};

  try {
    customTheme = require(`./${appName}/theme.js`);
    features = require(`./${appName}/features.js`);
  } catch (error) {
    customTheme = {};
    features = {};
  }

  return {
    features,
    theme: merge(mainTheme, customTheme),
  };
};
