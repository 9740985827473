import React, {
  useLayoutEffect, lazy, Suspense, useEffect,
} from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import container from 'containers/App';
import GoogleOneTapLogin from 'components/shared/GoogleOneTapLogin';
import Spinner from 'components/shared/Spinner';

import history from 'config/history';
import { componentLoader } from 'utils/componentLoader';
import { matchRoute } from 'utils/routeHelpers';
import { UNSUBSCRIBE, UNSUBSCRIBE_PRE_USERS } from 'utils/routes';
import siteStyle from 'utils/siteStyle';

const Alert = lazy(() => componentLoader(() => import(/* webpackChunkName: "Alert" */ 'components/Alert')));
const Routes = lazy(() => componentLoader(() => import(/* webpackChunkName: "Routes" */ 'components/routes/Routes')));
const CookieBannerModal = lazy(() => componentLoader(() => import(/* webpackChunkName: "CookieBannerModal" */ 'components/shared/CookieBannerModal')));

const App = ({
  initApp,
  isLoggedIn,
  restoreSessionFinished,
  smallHeaderMenuOpen,
  socialSignIn,
  store,
  path,
}) => {
  useLayoutEffect(() => { initApp(); }, [initApp]);

  // MNTN Tracking Pixel
  // INSTALL ON ALL PAGES OF SITE
  useEffect(() => {
    if (['https://supremegolf.com', 'https://preprod.app.supremegolf.com'].includes(window.location.origin)) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
        (function(){"use strict";var e=null,b="4.0.0",
        n="30828",
        additional="term=value",
        t,r,i;try{t=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,2048)):""}catch(o){t=document.referrer!==null?document.referrer.toString().substring(0,2048):""}try{r=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location}catch(u){r=document.location}try{i=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,2048)):""}catch(a){try{i=r!==null?encodeURIComponent(r.toString().substring(0,2048)):""}catch(f){i=""}}var l,c=document.createElement("script"),h=null,p=document.getElementsByTagName("script"),d=Number(p.length)-1,v=document.getElementsByTagName("script")[d];if(typeof l==="undefined"){l=Math.floor(Math.random()*1e17)}h="dx.mountain.com/spx?"+"dxver="+b+"&shaid="+n+"&tdr="+t+"&plh="+i+"&cb="+l+additional;c.type="text/javascript";c.src=("https:"===document.location.protocol?"https://":"http://")+h;v.parentNode.insertBefore(c,v)})()
      `;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <Provider store={store}>
      {process.env.REACT_APP_SIGN_IN_GOOGLE_CLIENT_ID && restoreSessionFinished && !isLoggedIn && (
        <GoogleOneTapLogin
          clientId={process.env.REACT_APP_SIGN_IN_GOOGLE_CLIENT_ID}
          onSuccess={(socialParams) => socialSignIn('google', socialParams, true)}
          disabled={isLoggedIn
            || matchRoute(UNSUBSCRIBE, path)
            || matchRoute(UNSUBSCRIBE_PRE_USERS, path)}
        />
      )}
      <div
        className={siteStyle({
          common: `app ${smallHeaderMenuOpen ? 'fixed top-24 w-full' : ''}`,
          default: 'font-primary',
          foh: 'font-roboto',
          gd: 'font-gotham',
        })}
        data-testid="app-wrapper"
      >
        <ConnectedRouter history={history}>
          <Helmet
            defaultTitle={process.env.REACT_APP_SHORT_DESCRIPTION}
            titleTemplate={`%s | ${process.env.REACT_APP_META_TITLE}`}
          >
            <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`} />
          </Helmet>
          <Suspense fallback={<Spinner />}>
            <Routes />
            <Alert />
            <CookieBannerModal />
          </Suspense>
        </ConnectedRouter>
      </div>
    </Provider>
  );
};

App.defaultProps = {
  isLoggedIn: false,
  path: '',
  restoreSessionFinished: false,
  socialSignIn: null,
};

App.propTypes = {
  initApp: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  path: PropTypes.string,
  restoreSessionFinished: PropTypes.bool,
  smallHeaderMenuOpen: PropTypes.bool.isRequired,
  socialSignIn: PropTypes.func,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
  }).isRequired,
};

export default container(App);
