import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import CityActions, { CityTypes } from 'reducers/city';
import CitiesApi from 'apis/supremeGolfApi/CitiesApi';

export function* requestCityHandler({ params }) {
  try {
    const city = yield call(CitiesApi.findCity, params);

    yield put(CityActions.getCityDone(city));
  } catch (error) {
    yield put(CityActions.getCityError(error.message));
  }
}

function* requestCityWatcher() {
  yield takeLatest(
    CityTypes.GET_CITY,
    requestCityHandler,
  );
}

export default [
  requestCityWatcher,
];
