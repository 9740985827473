import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import PageActions, { PageTypes } from 'reducers/page';
import PagesApi from 'apis/supremeGolfApi/PagesApi';

export function* requestPageHandler({ slug }) {
  try {
    const page = yield call(PagesApi.getPage, slug);

    yield put(PageActions.getPageDone(page));
  } catch (error) {
    yield put(PageActions.getPageError(error.message));
  }
}

function* requestPageWatcher() {
  yield takeLatest(
    PageTypes.GET_PAGE,
    requestPageHandler,
  );
}

export default [
  requestPageWatcher,
];
