import { call, put, takeLatest } from 'redux-saga/effects';

import RewardsActions, { RewardsTypes } from 'reducers/rewards';
import UsersApi from 'apis/supremeGolfApi/UsersApi';

export function* requestRewardsPointsHandler() {
  try {
    const { userRewardsPoints: rewardsPoints } = yield call(UsersApi.getRewardsPoints);

    yield put(RewardsActions.getRewardsPointsDone(rewardsPoints));
  } catch (error) {
    yield put(RewardsActions.getRewardsPointsError(error.message));
  }
}

export function* requestRewardsProfileUrlHandler() {
  try {
    const rewardsProfileUrl = yield call(UsersApi.getRewardsProfileUrl);

    yield put(RewardsActions.getRewardsProfileUrlDone(rewardsProfileUrl));
  } catch (error) {
    yield put(RewardsActions.getRewardsProfileUrlError(error.message));
  }
}

function* requestRewardsPointsHandlerWatcher() {
  yield takeLatest(RewardsTypes.GET_REWARDS_POINTS, requestRewardsPointsHandler);
}

function* requestRewardsProfileUrlWatcher() {
  yield takeLatest(RewardsTypes.GET_REWARDS_PROFILE_URL, requestRewardsProfileUrlHandler);
}

export default [
  requestRewardsPointsHandlerWatcher,
  requestRewardsProfileUrlWatcher,
];
