import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  sendSupport: ['params', 'callback'],
  sendSupportDone: null,
  sendSupportError: ['message'],
  clear: null,
});

export const SupportTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
  success: false,
});

const sendSupport = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const sendSupportDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  success: true,
});

const sendSupportError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
  success: false,
});

const clear = (state) => state.merge(INITIAL_STATE);

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SEND_SUPPORT]: sendSupport,
  [Types.SEND_SUPPORT_DONE]: sendSupportDone,
  [Types.SEND_SUPPORT_ERROR]: sendSupportError,
  [Types.CLEAR]: clear,
});
