import {
  call, put, takeLatest, getContext, select, take, race,
} from 'redux-saga/effects';
import CourseAlertsActions, { CourseAlertsTypes } from 'reducers/courseAlerts';
import CourseDetailActions from 'reducers/courseDetail';
import PriceAlertsActions from 'reducers/priceAlerts';
import AlertsApi from 'apis/supremeGolfApi/AlertsApi';
import {
  CITY_NOT_FOUND,
  COURSE_NOT_FOUND,
  INVALID_DAYS,
  INVALID_FREQUENCY,
  INVALID_HOLES_NUMBER,
  INVALID_PLAYERS_QTY,
  UNABLE_TO_UPDATE,
} from 'utils/priceAlerts/constants';
import { PLAYERS_RANGE } from 'utils/constants';
import { goToSignInHandler } from './session';

function mappedAlertParams(values) {
  const {
    minPrice,
    maxPrice,
    players,
    holes,
    days,
    atLeastNHoursBeforeSunset,
    frequency,
  } = values;

  const params = {
    minPrice,
    maxPrice,
    atLeastNHoursBeforeSunset,
  };

  // price alerts on v1 could be set to more than one player, now is only
  // possible to set it for one or all only
  if (players && typeof players === 'number') {
    params[`for_${players}`] = true;
  } else {
    PLAYERS_RANGE.forEach((player) => {
      params[`for_${player}`] = true;
    });
  }

  holes.forEach((hole) => {
    params[`holes_${hole}`] = true;
  });

  days.forEach((day) => {
    params[day] = true;
  });

  if (frequency) {
    params.frequency = frequency;
  }

  return params;
}

function* setShowPriceAlertModalHandler(show) {
  const profile = yield select((state) => state.profile);
  if (profile.membership) {
    yield put(CourseAlertsActions.setShowPriceAlertModal(show));
  } else {
    yield put(CourseAlertsActions.setShowUpsellMembershipModal(show));
  }
}

export function* addTeeTimePriceAlertHandler({ courseId, values }) {
  try {
    const location = yield select((state) => state.router.location);
    const { pathname, search } = location;
    const queryParams = new URLSearchParams(search);
    const currentUrl = `${pathname}?${queryParams.toString()}`;

    const goToSignIn = yield goToSignInHandler({
      path: currentUrl,
      newWindow: false,
      trackEvent: null,
      action: setShowPriceAlertModalHandler.bind(this, true),
    });
    if (goToSignIn) return;
    const params = mappedAlertParams(values);

    yield call(AlertsApi.addTeeTimePriceAlert, courseId, params);
    yield put(CourseDetailActions.getCourseDetailSilent(courseId));
    yield race([
      take(CourseDetailActions.getCourseDetailDone().type),
      take(CourseDetailActions.getCourseDetailError().type),
    ]);
    yield put(CourseAlertsActions.addTeeTimePriceAlertDone());

    const gtm = yield getContext('gtm');
    yield call(gtm.trackEvent, {
      eventCategory: 'interest', eventAction: 'click', eventLabel: 'add-price-alert', event: 'click-add-price-alert',
    });
  } catch (error) {
    yield put(CourseAlertsActions.addTeeTimePriceAlertError(error.message));
  }
}

export function* editTeeTimePriceAlertHandler({ alertId, courseId, values }) {
  try {
    const goToSignIn = yield goToSignInHandler();
    if (goToSignIn) return;

    const params = mappedAlertParams(values);

    const result = yield call(AlertsApi.editTeeTimePriceAlert, alertId, courseId, params);

    yield put(CourseAlertsActions.editTeeTimePriceAlertDone(result));
    yield put(PriceAlertsActions.updatePriceAlert(result));
  } catch (error) {
    let errorMessage;
    const errorCode = error.response.status;

    const knownErrors = [
      CITY_NOT_FOUND,
      COURSE_NOT_FOUND,
      INVALID_FREQUENCY,
      INVALID_PLAYERS_QTY,
      INVALID_DAYS,
      INVALID_HOLES_NUMBER,
      UNABLE_TO_UPDATE,
    ];

    const knownError = knownErrors.find((e) => e.code === errorCode);

    errorMessage = error.message;
    if (knownError) {
      errorMessage = knownError.message;
    }

    yield put(CourseAlertsActions.editTeeTimePriceAlertError(errorMessage));
  }
}

export function* removePriceAlertHandler({ courseId, alertId }) {
  try {
    const goToSignIn = yield goToSignInHandler();
    if (goToSignIn) return;

    yield call(AlertsApi.removePriceAlert, alertId);

    yield put(CourseDetailActions.getCourseDetailSilent(courseId));
    yield race([
      take(CourseDetailActions.getCourseDetailDone().type),
      take(CourseDetailActions.getCourseDetailError().type),
    ]);
    yield put(CourseAlertsActions.removePriceAlertDone());
    yield put(PriceAlertsActions.getPriceAlerts());
  } catch (error) {
    yield put(CourseAlertsActions.removePriceAlertError(error.message));
  }
}

function* addTeeTimePriceAlertWatcher() {
  yield takeLatest(CourseAlertsTypes.ADD_TEE_TIME_PRICE_ALERT, addTeeTimePriceAlertHandler);
}

function* editTeeTimePriceAlertWatcher() {
  yield takeLatest(CourseAlertsTypes.EDIT_TEE_TIME_PRICE_ALERT, editTeeTimePriceAlertHandler);
}

function* removePriceAlertWatcher() {
  yield takeLatest(CourseAlertsTypes.REMOVE_PRICE_ALERT, removePriceAlertHandler);
}

export default [
  addTeeTimePriceAlertWatcher,
  editTeeTimePriceAlertWatcher,
  removePriceAlertWatcher,
];
