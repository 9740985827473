import { createReducer, createActions } from 'reduxsauce';

import Immutable from 'seamless-immutable';

import { isProfileComplete } from 'utils/profileHelpers';

const { Types, Creators } = createActions({
  setProfile: ['userProfile'],
  resetProfile: null,
  getProfile: [],
  getProfileError: [],
  redirectIfNotSession: [],
  editProfile: ['profile', 'callback'],
  editProfileDone: null,
  editProfileError: ['message'],
  updateAvatar: ['formData', 'callback'],
  updateAvatarDone: null,
  updateAvatarError: ['message'],
  setUserBanStatus: ['userID', 'isBanned'],
  performProfileCompletionTracking: ['eventName'],
  profileDeletionRequest: null,
  profileDeletionRequestDone: null,
  profileDeletionRequestError: ['message'],
  deleteProfile: ['token'],
  deleteProfileDone: null,
  deleteProfileError: ['message'],
  resetDeleteProfile: null,
});

export const ProfileTypes = Types;
export default Creators;

const EMPTY_PROFILE = {
  loggedIn: false,
  id: '',
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  address1: '',
  addressCity: '',
  addressCountry: '',
  addressCountryIso2: '',
  addressState: '',
  addressZipcode: '',
  isModerator: false,
  isUsZipcode: false,
  avatarUrl: '',
  bornOn: '',
  focus: '',
  friendlyFocus: '',
  frequency: '',
  friendlyFrequency: '',
  gender: '',
  handicap: null,
  hasNetworkMembership: null,
  identities: [],
  phone: '',
  signInCount: '',
  skillLevel: '',
  friendlySkillLevel: '',
  consumerPrivacyAgreements: {},
  membership: null,
  isLoading: false,
  isError: false,
  errorMessage: '',
  isBanned: false,
  forceDataCheck: false,
  requiresDataCheck: false,
  isProfileComplete: false,
};

export const INITIAL_STATE = Immutable.from(EMPTY_PROFILE);

const setProfile = (state, { userProfile }) => state.merge({
  loggedIn: true,
  id: userProfile.id,
  name: `${userProfile.firstName} ${userProfile.lastName}`,
  firstName: userProfile.firstName,
  lastName: userProfile.lastName,
  email: userProfile.email,
  address1: userProfile.address1,
  addressCity: userProfile.addressCity,
  addressCountry: userProfile.addressCountry,
  addressCountryIso2: userProfile.addressCountryIso2,
  addressState: userProfile.addressState,
  addressZipcode: userProfile.addressZipcode,
  isModerator: userProfile.isModerator,
  isUsZipcode: userProfile.isUsZipcode,
  avatarUrl: userProfile.avatarUrl,
  bornOn: userProfile.bornOn,
  focus: userProfile.focus,
  friendlyFocus: userProfile.friendlyFocus,
  frequency: userProfile.frequency,
  friendlyFrequency: userProfile.friendlyFrequency,
  gender: userProfile.gender,
  handicap: userProfile.handicap,
  hasNetworkMembership: userProfile.hasNetworkMembership,
  identities: userProfile.identities,
  phone: userProfile.phone,
  skillLevel: userProfile.skillLevel,
  signInCount: userProfile.signInCount,
  friendlySkillLevel: userProfile.friendlySkillLevel,
  consumerPrivacyAgreements: userProfile.consumerPrivacyAgreements,
  membership: userProfile.membership,
  isLoadingProfile: false,
  isDoneProfile: true,
  isErrorProfile: false,
  isBanned: userProfile.isBanned || false,
  forceDataCheck: userProfile.forceDataCheck,
  requiresDataCheck: userProfile.requiresDataCheck,
  isProfileComplete: isProfileComplete(userProfile),
});

const getProfile = (state) => state.merge({
  isLoadingProfile: true,
  isDoneProfile: false,
  isErrorProfile: false,
});

const getProfileError = (state) => state.merge({
  isLoadingProfile: false,
  isDoneProfile: true,
  isErrorProfile: true,
});

const editProfile = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const editProfileDone = (state) => state.merge({
  isLoading: false,
});

const editProfileError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const updateAvatar = (state) => state.merge({
  isUpdatingAvatar: true,
  isDoneUpdatingAvatar: false,
  isErrorUpdatinggAvatar: false,
  updateAvatarErrorMessage: '',
});

const updateAvatarDone = (state) => state.merge({
  isUpdatingAvatar: false,
  isDoneUpdatingAvatar: true,
});

const updateAvatarError = (state, { message }) => state.merge({
  isUpdatingAvatar: false,
  isErrorUpdatingAvatar: true,
  updateAvatarErrorMessage: message,
});

const resetProfile = (state) => state.merge(EMPTY_PROFILE);

const profileDeletionRequest = (state) => state.merge({
  isLoadingProfileDeletionRequest: true,
  isDoneProfileDeletionRequest: false,
  isErrorProfileDeletionRequest: false,
  profileDeletionRequestErrorMessage: '',
});

const profileDeletionRequestDone = (state) => state.merge({
  isLoadingProfileDeletionRequest: false,
  isDoneProfileDeletionRequest: true,
  isErrorProfileDeletionRequest: false,
});

const profileDeletionRequestError = (state, { message }) => state.merge({
  isLoadingProfileDeletionRequest: false,
  isDoneProfileDeletionRequest: false,
  isErrorProfileDeletionRequest: true,
  profileDeletionRequestErrorMessage: message,
});

const deleteProfile = (state) => state.merge({
  isLoadingDeleteProfile: true,
  isDoneDeleteProfile: false,
  isErrorDeleteProfile: false,
  deleteProfileErrorMessage: '',
});

const deleteProfileDone = (state) => state.merge({
  isLoadingDeleteProfile: false,
  isDoneDeleteProfile: true,
  isErrorDeleteProfile: false,
});

const deleteProfileError = (state, { message }) => state.merge({
  isLoadingDeleteProfile: false,
  isDoneDeleteProfile: false,
  isErrorDeleteProfile: true,
  deleteProfileErrorMessage: message,
});

const resetDeleteProfile = (state) => state.merge({
  isLoadingProfileDeletionRequest: false,
  isDoneProfileDeletionRequest: false,
  isErrorProfileDeletionRequest: false,
  isLoadingDeleteProfile: false,
  isDoneDeleteProfile: false,
  isErrorDeleteProfile: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EDIT_PROFILE_DONE]: editProfileDone,
  [Types.EDIT_PROFILE_ERROR]: editProfileError,
  [Types.EDIT_PROFILE]: editProfile,
  [Types.GET_PROFILE]: getProfile,
  [Types.GET_PROFILE_ERROR]: getProfileError,
  [Types.UPDATE_AVATAR]: updateAvatar,
  [Types.UPDATE_AVATAR_DONE]: updateAvatarDone,
  [Types.UPDATE_AVATAR_ERROR]: updateAvatarError,
  [Types.RESET_PROFILE]: resetProfile,
  [Types.SET_PROFILE]: setProfile,
  [Types.PROFILE_DELETION_REQUEST]: profileDeletionRequest,
  [Types.PROFILE_DELETION_REQUEST_DONE]: profileDeletionRequestDone,
  [Types.PROFILE_DELETION_REQUEST_ERROR]: profileDeletionRequestError,
  [Types.DELETE_PROFILE]: deleteProfile,
  [Types.DELETE_PROFILE_DONE]: deleteProfileDone,
  [Types.DELETE_PROFILE_ERROR]: deleteProfileError,
  [Types.RESET_DELETE_PROFILE]: resetDeleteProfile,
});
