import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setSortByCourses: ['sortBy'],
});

export const SearchFilterTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  sortBy: null,
});

const setSortByCourses = (state, { sortBy }) => state.merge({
  sortBy,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SORT_BY_COURSES]: setSortByCourses,
});
