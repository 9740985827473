import {
  call, put, takeLatest, take, getContext,
  select,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import InviteApi from 'apis/supremeGolfApi/InviteApi';
import InviteActions, { InviteTypes } from 'reducers/invite';
import ReservationAction from 'reducers/reservations';
import { BOOKING_CONFIRMATION, NOT_FOUND } from 'utils/routes';
import { matchRoute } from 'utils/routeHelpers';

export function* requestInviteDetails({ id }) {
  try {
    const data = yield call(InviteApi.getInviteDetails, { id });
    yield put(InviteActions.getInviteDetailsDone(data?.invitation));
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(push(NOT_FOUND));
    }
    yield put(InviteActions.getInviteDetailsError(error));
    yield put(InviteActions.getInviteDetailsError(error?.response?.data?.error));
  }
}

export function* acceptInvite({ id, callback }) {
  try {
    const iterable = yield getContext('iterable');
    const iterableTracking = yield call(iterable.getTrackingParams, true);
    yield call(InviteApi.acceptInvite, { id, iterableTracking });

    yield put(InviteActions.acceptInviteDone());

    if (callback) callback();
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(push(NOT_FOUND));
    }
    yield put(InviteActions.acceptInviteError(error?.response?.data?.error));
  }
}

export function* declineInvite({ id, callback }) {
  try {
    const iterable = yield getContext('iterable');
    const iterableTracking = yield call(iterable.getTrackingParams, true);
    yield call(InviteApi.declineInvite, { id, iterableTracking });

    yield put(InviteActions.declineInviteDone());

    if (callback) callback();
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(push(NOT_FOUND));
    }
    yield put(InviteActions.declineInviteError(error?.response?.data?.error));
  }
}

export function* addGuestByEmail({ guestInfo, callback }) {
  try {
    const data = yield call(InviteApi.addGuestByEmail, { ...guestInfo });

    yield put(InviteActions.addGuestByEmailDone());

    const { contact } = data;

    if (callback) callback(contact);
  } catch (error) {
    yield put(InviteActions.addGuestByEmailError(error?.response?.data?.error));
  }
}

export function* inviteGuest({ reservationId, emails, callback }) {
  try {
    const iterable = yield getContext('iterable');
    const iterableTracking = yield call(iterable.getTrackingParams, true);
    yield call(InviteApi.inviteGuest, { reservationId, emails, iterableTracking });
    const location = yield select((state) => state.router.location);

    const bookingStatus = matchRoute(BOOKING_CONFIRMATION, location.pathname) ? 'confirmed' : 'upcoming';

    yield put(ReservationAction.getReservationSilent(reservationId, bookingStatus));
    yield take((innerAction) => (
      innerAction.type === ReservationAction.getReservationDone().type
      || innerAction.type === ReservationAction.getReservationError().type
    ));

    yield put(InviteActions.inviteGuestDone());

    if (callback) callback();
  } catch (error) {
    yield put(InviteActions.inviteGuestError(error?.response?.data?.error));
  }
}

export function* getRecentContacts({ reservationId }) {
  try {
    const data = yield call(InviteApi.getRecentContacts, { reservationId });

    yield put(InviteActions.getRecentContactsDone(data));
  } catch (error) {
    yield put(InviteActions.getRecentContactsError({ error }));
  }
}

function* requestInviteDetailsWatcher() {
  yield takeLatest(InviteTypes.GET_INVITE_DETAILS, requestInviteDetails);
}

function* acceptInviteWatcher() {
  yield takeLatest(InviteTypes.ACCEPT_INVITE, acceptInvite);
}

function* declineInviteWatcher() {
  yield takeLatest(InviteTypes.DECLINE_INVITE, declineInvite);
}

function* addGuestByEmailWatcher() {
  yield takeLatest(InviteTypes.ADD_GUEST_BY_EMAIL, addGuestByEmail);
}

function* inviteGuestWatcher() {
  yield takeLatest(InviteTypes.INVITE_GUEST, inviteGuest);
}

function* getRecentContactsWatcher() {
  yield takeLatest(InviteTypes.GET_RECENT_CONTACTS, getRecentContacts);
}

export default [
  requestInviteDetailsWatcher,
  acceptInviteWatcher,
  declineInviteWatcher,
  addGuestByEmailWatcher,
  inviteGuestWatcher,
  getRecentContactsWatcher,
];
