import SupremeGolfApiClient from './SupremeGolfApiClient';

const FAQS_ENDPOINT = 'v4/faqs';

class FaqsApi {
  static async getFaqs() {
    const response = await SupremeGolfApiClient.get(FAQS_ENDPOINT);
    const { data } = response;
    return data.faqs;
  }
}

export default FaqsApi;
