import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  performGetReviews: ['tagId'],
  performGetCourseReviews: ['hierarchizedUrl'],
  getCourseReviews: ['courseId', 'sortBy', 'tagId', 'reviewId'],
  getCourseReviewsDone: ['reviewsList', 'hasMore', 'courseReviewsCount'],
  getCourseReviewsError: ['message'],
  setSortByAndPage: ['sortBy', 'page'],
  helpfulness: ['id', 'helpful'],
  helpfulnessDone: ['id'],
  helpfulnessError: ['id', 'message'],
  reportIt: ['id'],
  reportItDone: ['id'],
  reportItError: ['id', 'message'],
  newCourseReview: ['hierarchizedUrl', 'id'],
  createReview: ['rating', 'title', 'courseReview'],
  createReviewDone: null,
  createReviewError: ['message'],
  getNextPageReviews: ['courseId', 'excludeId'],
  getNextPageReviewsError: ['message'],
  getNextPageReviewsDone: ['reviewsList', 'hasMore', 'page', 'courseReviewsCount'],
  redirectIfNotModerator: [],
  setModeratorReviewTab: ['selectedTab'],
  getModeratorReviews: ['status'],
  getNextPageModeratorReviews: ['status'],
  getDynamicLink: ['id', 'resourceType'],
  getDynamicLinkDone: ['dynamicLink'],
  getDynamicLinkError: ['message'],
  setReviewFilters: ['reviewFilters'],
  resetFilterAutocomplete: null,
  getCourseAutocomplete: ['filterText'],
  getUserEmailAutocomplete: ['filterText'],
  getAutocompleteDone: ['filterAutocomplete'],
  getAutocompleteError: ['message'],
  setReviewStatus: ['reviewID', 'isApproved'],
});

export const CourseReviewsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  reviewsList: [],
  helpfulness: {},
  reportIt: {},
  sortBy: '',
  page: 1,
  createReview: {
    isLoading: false,
    isError: false,
    errorMessage: '',
    success: false,
  },
  hasMore: true,
  hierarchizedUrl: '',
  selectedTab: 'auto-published',
  dynamicLink: '',
  isOpenReviewModal: false,
  tagId: null,
  courseReviewsCount: null,
  filterAutocomplete: [],
  reviewFilters: {
    courseSlug: '',
    from: '',
    to: '',
    userID: '',
  },
});

const getCourseReviews = (state, { sortBy, tagId }) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  sortBy,
  page: 1,
  courseReviewsCount: null,
  tagId,
});

const getCourseReviewsDone = (state, { reviewsList, hasMore, courseReviewsCount }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  reviewsList,
  hasMore,
  courseReviewsCount,
});

const getCourseReviewsError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const setSortByAndPage = (state, { sortBy, page }) => state.merge({
  sortBy,
  page,
});

const helpfulness = (state, { id }) => {
  const newHelpfulness = { ...state.helpfulness };
  newHelpfulness[id] = {
    isLoading: true,
    isError: false,
    isDone: false,
  };
  return state.merge({
    helpfulness: newHelpfulness,
  });
};

const helpfulnessDone = (state, { id }) => {
  const newHelpfulness = { ...state.helpfulness };
  newHelpfulness[id] = {
    isLoading: false,
    isError: false,
    isDone: true,
  };
  return state.merge({
    helpfulness: newHelpfulness,
  });
};

const helpfulnessError = (state, { id, message }) => {
  const newHelpfulness = { ...state.helpfulness };
  newHelpfulness[id] = {
    isLoading: false,
    isError: true,
    isDone: true,
    errorMessage: message,
  };
  return state.merge({
    helpfulness: newHelpfulness,
  });
};

const reportIt = (state, { id }) => {
  const newReportIt = { ...state.reportIt };
  newReportIt[id] = {
    isLoading: true,
    isError: false,
    isDone: false,
  };
  return state.merge({
    reportIt: newReportIt,
  });
};

const reportItDone = (state, { id }) => {
  const newReportIt = { ...state.reportIt };
  newReportIt[id] = {
    isLoading: false,
    isError: false,
    isDone: true,
  };
  return state.merge({
    reportIt: newReportIt,
  });
};

const reportItError = (state, { id, message }) => {
  const newReportIt = { ...state.reportIt };
  newReportIt[id] = {
    isLoading: false,
    isError: true,
    isDone: true,
    errorMessage: message,
  };
  return state.merge({
    reportIt: newReportIt,
  });
};

const newCourseReview = (state) => state.merge({
  createReview: {
    isLoading: false,
    isError: false,
    errorMessage: '',
    success: false,
  },
});

const createReview = (state) => state.merge({
  isLoading: false,
  isError: false,
  errorMessage: '',
  sortBy: '',
  page: 1,
  reviewsList: [],
  helpfulness: [],
  reportIt: [],
  createReview: {
    isLoading: true,
    isError: false,
    errorMessage: '',
    success: false,
  },
  hasMore: true,
});

const createReviewDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  errorMessage: '',
  sortBy: '',
  page: 1,
  reviewsList: [],
  helpfulness: [],
  reportIt: [],
  createReview: {
    isLoading: false,
    isError: false,
    errorMessage: '',
    success: true,
  },
  hasMore: true,
});

const createReviewError = (state, { message }) => state.merge({
  isLoading: false,
  isError: false,
  errorMessage: '',
  sortBy: '',
  page: 1,
  reviewsList: [],
  helpfulness: [],
  reportIt: [],
  createReview: {
    isLoading: false,
    isError: true,
    errorMessage: message,
    success: false,
  },
  hasMore: true,
});

const getNextPageReviews = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const getNextPageReviewsDone = (state, {
  reviewsList, hasMore, page, courseReviewsCount,
}) => (state.merge({
  isLoading: false,
  isError: false,
  reviewsList: [...state.reviewsList, ...reviewsList],
  hasMore,
  page,
  courseReviewsCount,
}));

const getNextPageReviewsError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const getModeratorReviews = (state, { status }) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  page: 1,
  selectedTab: status,
  reviewsList: [],
  hasMore: true,
});

const setModeratorReviewTab = (state, { selectedTab }) => state.merge({
  selectedTab,
  reviewsList: [],
  hasMore: true,
});

const getDynamicLink = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const getDynamicLinkDone = (state, { dynamicLink }) => state.merge({
  isLoading: false,
  isError: false,
  dynamicLink,
});

const getDynamicLinkError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const performGetReviews = (state, { tagId }) => state.merge({
  tagId,
});

const setReviewFilters = (state, { reviewFilters }) => state.merge({
  reviewFilters,
});

const resetFilterAutocomplete = (state) => state.merge({
  filterAutocomplete: [],
  filterText: '',
  hasMore: false,
});

const getAutocompleteDone = (state, { filterAutocomplete }) => state.merge({
  isLoading: false,
  isError: false,
  filterAutocomplete,
});

const getAutocompleteError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COURSE_REVIEWS]: getCourseReviews,
  [Types.GET_COURSE_REVIEWS_DONE]: getCourseReviewsDone,
  [Types.GET_COURSE_REVIEWS_ERROR]: getCourseReviewsError,
  [Types.SET_SORT_BY_AND_PAGE]: setSortByAndPage,
  [Types.HELPFULNESS]: helpfulness,
  [Types.HELPFULNESS_DONE]: helpfulnessDone,
  [Types.HELPFULNESS_ERROR]: helpfulnessError,
  [Types.REPORT_IT]: reportIt,
  [Types.REPORT_IT_DONE]: reportItDone,
  [Types.REPORT_IT_ERROR]: reportItError,
  [Types.NEW_COURSE_REVIEW]: newCourseReview,
  [Types.CREATE_REVIEW]: createReview,
  [Types.CREATE_REVIEW_DONE]: createReviewDone,
  [Types.CREATE_REVIEW_ERROR]: createReviewError,
  [Types.GET_NEXT_PAGE_REVIEWS]: getNextPageReviews,
  [Types.GET_NEXT_PAGE_REVIEWS_DONE]: getNextPageReviewsDone,
  [Types.GET_NEXT_PAGE_REVIEWS_ERROR]: getNextPageReviewsError,
  [Types.GET_MODERATOR_REVIEWS]: getModeratorReviews,
  [Types.SET_MODERATOR_REVIEW_TAB]: setModeratorReviewTab,
  [Types.GET_DYNAMIC_LINK]: getDynamicLink,
  [Types.GET_DYNAMIC_LINK_DONE]: getDynamicLinkDone,
  [Types.GET_DYNAMIC_LINK_ERROR]: getDynamicLinkError,
  [Types.PERFORM_GET_REVIEWS]: performGetReviews,
  [Types.SET_REVIEW_FILTERS]: setReviewFilters,
  [Types.RESET_FILTER_AUTOCOMPLETE]: resetFilterAutocomplete,
  [Types.GET_AUTOCOMPLETE_DONE]: getAutocompleteDone,
  [Types.GET_AUTOCOMPLETE_ERROR]: getAutocompleteError,
});
