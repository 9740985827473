import { createReducer, createActions } from 'reduxsauce';

import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setIsBannerVisible: ['isBannerVisible'],
});

export const CookiesConsentTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isBannerVisible: false,
});

const setIsBannerVisible = (state, { isBannerVisible }) => state.merge({
  isBannerVisible,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_IS_BANNER_VISIBLE]: setIsBannerVisible,
});
