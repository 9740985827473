import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getBestDeals: ['courseId', 'params'],
  getBestDealsDone: ['bestDealsList'],
  getBestDealsError: ['message'],
});

export const BestDealsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  errorMessage: '',
  isDone: false,
  isError: false,
  isLoading: false,
  bestDealsList: [],
});

const getBestDeals = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  bestDealsList: [],
});

const getBestDealsDone = (state, { bestDealsList }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  bestDealsList,
});

const getBestDealsError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BEST_DEALS]: getBestDeals,
  [Types.GET_BEST_DEALS_DONE]: getBestDealsDone,
  [Types.GET_BEST_DEALS_ERROR]: getBestDealsError,
});
