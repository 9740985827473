import SupremeGolfApiClient from './SupremeGolfApiClient';

const PAGES_ENDPOINT = 'v6/pages';

class PagesApi {
  static async getPage(slug) {
    const response = await SupremeGolfApiClient.get(
      `${PAGES_ENDPOINT}/show/${slug}`,
      {
        full: false,
      },
    );

    return response.data;
  }
}

export default PagesApi;
