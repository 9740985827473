import SupremeGolfApiClient from './SupremeGolfApiClient';

const CREDIT_CARDS_ENDPOINT = 'v6/credit_cards';

class CreditCardsApi {
  static async getCreditCards() {
    const response = await SupremeGolfApiClient.get(`${CREDIT_CARDS_ENDPOINT}`);
    const { data } = response;
    return data.creditCards;
  }

  static async postCreditCard(token, gRecaptchaResponseData) {
    const response = await SupremeGolfApiClient.post(CREDIT_CARDS_ENDPOINT, {
      token, gRecaptchaResponseData,
    });
    const { data } = response;
    return data;
  }

  static async deleteCreditCard(id) {
    const response = await SupremeGolfApiClient.delete(
      `${CREDIT_CARDS_ENDPOINT}/${id}`,
    );
    const { data } = response;
    return data;
  }
}

export default CreditCardsApi;
