import SupremeGolfApiClient from './SupremeGolfApiClient';

const SEARCH_AUTOCOMPLETE_ENDPOINT = 'v6/search/autocomplete';

const SEARCH_LIMIT = 10;
const STRING_LIMIT_SEARCH = 3;
const NUMBER_LIMIT_SEARCH = 3;

function shouldSearch(searchText) {
  const lengthLimit = !Number.isNaN(parseFloat(searchText))
    ? NUMBER_LIMIT_SEARCH : STRING_LIMIT_SEARCH;

  return (searchText.length >= lengthLimit);
}

class SearchAutocompleteApi {
  static async getSearchSuggestions(searchText, lat, lon, isNewSearch = false) {
    const trimmedSearchText = searchText.trim();

    if (shouldSearch(trimmedSearchText)) {
      const response = await SupremeGolfApiClient.get(
        isNewSearch ? 'v6/search/autocomplete-v2' : SEARCH_AUTOCOMPLETE_ENDPOINT,
        {
          includeZipCodes: false,
          limit: SEARCH_LIMIT,
          q: searchText,
          lat,
          lon,
          include_without_tee_times: true,
        },
      );
      const { data } = response;
      return data.autoCompleteResults;
    }
    return null;
  }
}

export default SearchAutocompleteApi;
