import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setBanners: ['courseId'],
  setBannersDone: ['banners'],
  setBannersError: [],
});

export const BannersTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  banners: [],
  isError: false,
  isLoading: false,
});

const setBanners = (state) => state.merge({
  banners: [],
  isError: false,
  isLoading: true,
});

const setBannersDone = (state, { banners }) => state.merge({
  banners,
  isError: false,
  isLoading: false,
});

const setBannersError = (state) => state.merge({
  banners: [],
  isError: true,
  isLoading: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_BANNERS]: setBanners,
  [Types.SET_BANNERS_DONE]: setBannersDone,
  [Types.SET_BANNERS_ERROR]: setBannersError,
});
