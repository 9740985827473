import SupremeGolfApiClient from './SupremeGolfApiClient';

const SUBSCRIPTIONS_ENDPOINT = 'v4/mailing_lists/subscribe.json';

class SubscriptionsApi {
  static async subscribe(email, utmParams = {}) {
    const body = {
      email,
      code: 'coupons',
      source: 'footer',
      ...utmParams,
    };
    await SupremeGolfApiClient.post(SUBSCRIPTIONS_ENDPOINT, body);
  }
}

export default SubscriptionsApi;
