import { connect } from 'react-redux';

import AppActions from 'reducers/app';
import RegistrationActions from 'reducers/registrations';

const mapStateToProps = ({
  ui, profile, session, registrations, router,
}) => ({
  smallHeaderMenuOpen: ui.headerMenuSmall.open,
  isLoggedIn: profile.loggedIn,
  restoreSessionFinished: session.restoreSessionFinished,
  errorMessage: registrations.errorMessage,
  path: router.location.pathname,
});

const mapDispatchToProps = {
  ...AppActions,
  ...RegistrationActions,
};

export default connect(mapStateToProps, mapDispatchToProps);
