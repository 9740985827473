import { PAYMENTS_HISTORY_PAGINATION } from 'utils/constants';
import MembershipApiClient from './MembershipApiClient';

const MEMBERSHIP_SUBSCRIPTION_BASE = 'MembershipSubscription';
const MEMBERSHIP_SUBSCRIPTION_INVOICE = 'MembershipSubscriptionInvoice/list';

class MembershipSubscriptionApi {
  static async getMembershipSubscription(programId, membershipId) {
    const response = await MembershipApiClient.get(
      `${MEMBERSHIP_SUBSCRIPTION_BASE}/${programId}/${membershipId}`,
    );

    return response.data;
  }

  static async deleteMembershipSubscription(membershipId) {
    const response = await MembershipApiClient.delete(
      `${MEMBERSHIP_SUBSCRIPTION_BASE}/${membershipId}`,
    );

    return response.data;
  }

  static async getMembershipSubscriptionInvoice(programId, opts = {}) {
    const response = await MembershipApiClient.get(
      `${MEMBERSHIP_SUBSCRIPTION_INVOICE}/${programId}`,
      {
        pageNumber: opts.pageNumber || 1,
        pageSize: PAYMENTS_HISTORY_PAGINATION,
      },
    );

    return response.data;
  }
}

export default MembershipSubscriptionApi;
