import SupremeGolfApiClient from './SupremeGolfApiClient';

const INVITATION_ENDPOINT = 'v6/invitations';
const CONTACTS = 'v6/contacts';

class InviteApi {
  static async getInviteDetails({ id }) {
    const response = await SupremeGolfApiClient.get(`${INVITATION_ENDPOINT}/${id}`);
    const { data } = response;
    return data;
  }

  static async acceptInvite({ id, iterableTracking }) {
    const response = await SupremeGolfApiClient.post(`${INVITATION_ENDPOINT}/${id}/accept`, { ...iterableTracking });
    const { data } = response;
    return data;
  }

  static async declineInvite({ id, iterableTracking }) {
    const response = await SupremeGolfApiClient.post(`${INVITATION_ENDPOINT}/${id}/decline`, { ...iterableTracking });
    const { data } = response;
    return data;
  }

  static async addGuestByEmail({ firstName, lastName, email }) {
    const payload = {
      firstName,
      lastName,
      email,
      source: 'reservation',
    };
    const response = await SupremeGolfApiClient.post(CONTACTS, payload);
    return response.data;
  }

  static async inviteGuest({ reservationId, emails, iterableTracking }) {
    const payload = {
      reservationId,
      emails,
      ...iterableTracking,
    };
    const response = await SupremeGolfApiClient.post(INVITATION_ENDPOINT, payload);
    return response.data;
  }

  static async getRecentContacts({ reservationId }) {
    const response = await SupremeGolfApiClient.get(`${CONTACTS}/reservation`, {
      reservationId,
    });
    return response.data;
  }
}

export default InviteApi;
