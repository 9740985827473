import { call, put } from 'redux-saga/effects';
import { push, replace } from 'react-router-redux';
import qs from 'qs';
import Route from 'route-parser';

export function matchRoute(expectedPattern, actualPath) {
  let match = true;
  const splitExpectedPattern = expectedPattern?.split('/');
  const splitActualPath = actualPath?.split('/');
  if (splitExpectedPattern?.length !== splitActualPath?.length) {
    return false;
  }
  for (let i = 0; i < splitExpectedPattern?.length; i += 1) {
    if (!splitExpectedPattern[i].includes(':')) {
      match = match && splitExpectedPattern[i] === splitActualPath[i];
    }
  }
  return match;
}

export function onRoute(path) {
  return (action) => {
    if (action.type !== '@@router/LOCATION_CHANGE') return false;

    if (Array.isArray(path)) {
      return path.some((p) => matchRoute(p, action.payload.location.pathname));
    }

    return matchRoute(path, action.payload.location.pathname);
  };
}

export function extractRouteParams(pattern) {
  const worker = (path) => {
    const result = {};
    const splitPattern = pattern.split('/');
    const splitPath = path.split('/');
    if (!matchRoute(pattern, path)) {
      return result;
    }
    for (let i = 0; i < splitPattern.length; i += 1) {
      if (splitPattern[i].includes(':')) {
        result[splitPattern[i].replace(':', '')] = splitPath[i];
      }
    }
    return result;
  };

  // eslint-disable-next-line func-names
  return function* (path) {
    const params = yield call(worker, path);
    return params;
  };
}

export function getQsAndParams(location, path) {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const route = new Route(path);
  const params = route.match(location.pathname);
  return { queryParams, params };
}

export function* routerActionHelper(action, path) {
  switch (action) {
    case 'push': {
      yield put(push(path));
      break;
    }
    case 'replace': {
      yield put(replace(path));
      break;
    }
    case 'none': {
      break;
    }
    default:
      yield put(push(path));
      break;
  }
}
