import SupremeGolfApiClient from './SupremeGolfApiClient';

const BANNERS_ENDPOINT = 'v6/banners';

class BannersApi {
  static async getBanners(courseId) {
    const params = {};
    if (courseId) { params.courseId = courseId; }

    const response = await SupremeGolfApiClient.get(BANNERS_ENDPOINT, params);

    const { data } = response;

    return data.banners;
  }
}

export default BannersApi;
