import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  resetPassword: [
    'token',
    'password',
    'passwordConfirmation',
  ],
  resetPasswordDone: ['response'],
  resetPasswordError: ['message'],
  resetPasswordFinish: ['finish'],
});

export const ResetPasswordTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
  response: false,
});

const resetPassword = (state) => state.merge({
  isLoading: true,
  isError: false,
  response: false,
});

const resetPasswordDone = (state, { response }) => state.merge({
  isLoading: false,
  isError: false,
  response,
});

const resetPasswordError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  response: false,
  errorMessage: message,
});

const resetPasswordFinish = (state) => state.merge({
  response: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_PASSWORD]: resetPassword,
  [Types.RESET_PASSWORD_DONE]: resetPasswordDone,
  [Types.RESET_PASSWORD_ERROR]: resetPasswordError,
  [Types.RESET_PASSWORD_FINISH]: resetPasswordFinish,
});
