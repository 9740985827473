import pickBy from 'lodash/pickBy';
import SupremeGolfApiClient from './SupremeGolfApiClient';

const CITIES_BASE_ROUTE = 'v6/cities';
const CITIES_BY_COORD_ENDPOINT = `${CITIES_BASE_ROUTE}/for_coordinates`;
const FIND_CITY_ENDPOINT = `${CITIES_BASE_ROUTE}/find`;

class CitiesApi {
  static async getCityByCoord(lat, lon) {
    const response = await SupremeGolfApiClient.get(CITIES_BY_COORD_ENDPOINT, {
      lat,
      lon,
    });
    return response.data.city;
  }

  static async findCity({
    id, slug, hierarchizedUrl, zipCode,
  }) {
    const params = pickBy({
      id,
      slug,
      hierarchizedUrl,
      zipCode,
    });
    const response = await SupremeGolfApiClient.get(FIND_CITY_ENDPOINT, params);
    const { data } = response;
    return data.city;
  }
}

export default CitiesApi;
