import humps from 'humps';
import SupremeGolfApiClient from './SupremeGolfApiClient';

const COURSE_DETAIL_ENDPOINT = 'v6/courses';

class CourseDetailApi {
  static async getCourseDetail(courseId, date, iterableTracking) {
    let query = {};
    if (date) query.date = date;
    if (iterableTracking) {
      query.pageVisit = true;
      query = { ...query, ...iterableTracking };
    }

    const response = await SupremeGolfApiClient.get(`${COURSE_DETAIL_ENDPOINT}/${courseId}`, query);
    const { data } = response;
    return data.course;
  }

  static async getMembershipBenefits({ courseId }) {
    const response = await SupremeGolfApiClient.get(
      `${COURSE_DETAIL_ENDPOINT}/${courseId}/membership_benefits`,
      undefined,
      { skipCamelizeKeys: true },
    );
    const { data } = response;
    return {
      ...data,
      banners: humps.camelizeKeys(data?.banners),
    };
  }

  static async getCourseScorecard(courseId) {
    const response = await SupremeGolfApiClient.get(
      `${COURSE_DETAIL_ENDPOINT}/${courseId}/scorecard`,
    );
    const { data } = response;
    return data;
  }
}

export default CourseDetailApi;
