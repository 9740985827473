import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  postOptIn: ['preferences', 'isPreUser'],
  postOptInDone: [],
  postOptInError: ['message'],
  setUnsubscribePage: ['unsubscribePage'],
  goToPreferencePage: [],
});

export const UserAgreementsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  unsubscribePage: '',
});

const postOptIn = (state) => state.merge({
  isLoading: true,
  isError: false,
  isSuccess: false,
  errorMessage: '',
});

const postOptInDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  isSuccess: true,
  errorMessage: '',
});

const postOptInError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  isSuccess: false,
  errorMessage: message,
});

const setUnsubscibePage = (state, { unsubscribePage }) => state.merge({
  unsubscribePage,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_OPT_IN]: postOptIn,
  [Types.POST_OPT_IN_DONE]: postOptInDone,
  [Types.POST_OPT_IN_ERROR]: postOptInError,
  [Types.SET_UNSUBSCRIBE_PAGE]: setUnsubscibePage,
});
