import {
  call, put, takeLatest, getContext, select, take,
} from 'redux-saga/effects';
import FavoriteCoursesActions, { FavoriteCoursesTypes } from 'reducers/favoriteCourses';
import CourseDetailActions from 'reducers/courseDetail';
import CourseSuggestionsActions from 'reducers/courseSuggestions';
import SearchCoursesActions from 'reducers/searchCourses';
import ReservationsActions from 'reducers/reservations';
import FavoriteCoursesApi from 'apis/supremeGolfApi/FavoriteCoursesApi';
import { goToSignInHandler } from './session';

export function* addFavoriteHandler({ id, page, data }) {
  try {
    const location = yield select((state) => state.router.location);
    const { pathname, search } = location;
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('onlySearch')) {
      queryParams.set('onlySearch', false);
    }
    const currentUrl = `${pathname}?${queryParams.toString()}`;

    const goToSignIn = yield goToSignInHandler({
      path: currentUrl,
      newWindow: false,
      trackEvent: null,
      action: addFavoriteHandler.bind(this, { id }),
    });
    if (goToSignIn) return;

    yield call(FavoriteCoursesApi.addFavorite, id);

    const gtm = yield getContext('gtm');
    yield call(gtm.trackEvent, {
      eventCategory: 'interest', eventAction: 'click', eventLabel: 'add-favorite', event: 'click-add-favorite',
    });

    yield put(CourseDetailActions.getCourseDetailSilent(id));
    yield take((innerAction) => (
      innerAction.type === CourseDetailActions.getCourseDetailDone().type
      || innerAction.type === CourseDetailActions.getCourseDetailError().type
    ));

    if (page === 'coursesNearYou') {
      yield put(CourseSuggestionsActions.getCoursesNearYouSilent());
      yield take((innerAction) => (
        innerAction.type === CourseSuggestionsActions.getCoursesNearYouDone().type
        || innerAction.type === CourseSuggestionsActions.getCoursesNearYouError().type
      ));
    } else if (page === 'cityResults') {
      yield put(SearchCoursesActions.toggleCourseFavorite(id));
    } else if (page === 'bookingConfirmation') {
      yield put(ReservationsActions.getReservationSilent(data.reservationId, data.status));
      yield take((innerAction) => (
        innerAction.type === ReservationsActions.getReservationDone().type
        || innerAction.type === ReservationsActions.getReservationError().type
      ));
    } else if (page === 'myBookings') {
      yield put(ReservationsActions.getReservationsSilent(data.tab));
      yield take((innerAction) => (
        innerAction.type === ReservationsActions.getReservationsDone().type
        || innerAction.type === ReservationsActions.getReservationsError().type
      ));
    }

    yield put(FavoriteCoursesActions.addFavoriteDone());
  } catch (error) {
    yield put(FavoriteCoursesActions.addFavoriteError(error.message));
  }
}

export function* removeFavoriteHandler({ id, page, data }) {
  try {
    const goToSignIn = yield goToSignInHandler();
    if (goToSignIn) return;

    yield call(FavoriteCoursesApi.removeFavorite, id);

    yield put(CourseDetailActions.getCourseDetailSilent(id));
    yield take((innerAction) => (
      innerAction.type === CourseDetailActions.getCourseDetailDone().type
      || innerAction.type === CourseDetailActions.getCourseDetailError().type
    ));

    if (page === 'coursesNearYou') {
      yield put(CourseSuggestionsActions.getCoursesNearYouSilent());
      yield take((innerAction) => (
        innerAction.type === CourseSuggestionsActions.getCoursesNearYouDone().type
        || innerAction.type === CourseSuggestionsActions.getCoursesNearYouError().type
      ));
    } else if (page === 'cityResults') {
      yield put(SearchCoursesActions.toggleCourseFavorite(id));
    } else if (page === 'bookingConfirmation') {
      yield put(ReservationsActions.getReservationSilent(data.reservationId, data.status));
      yield take((innerAction) => (
        innerAction.type === ReservationsActions.getReservationDone().type
        || innerAction.type === ReservationsActions.getReservationError().type
      ));
    } else if (page === 'myBookings') {
      yield put(ReservationsActions.getReservationsSilent(data.tab));
      yield take((innerAction) => (
        innerAction.type === ReservationsActions.getReservationsDone().type
        || innerAction.type === ReservationsActions.getReservationsError().type
      ));
    }

    yield put(FavoriteCoursesActions.removeFavoriteDone(id));
  } catch (error) {
    yield put(FavoriteCoursesActions.removeFavoriteError(error.message));
  }
}

export function* getFavoritesHandler() {
  try {
    const favoriteCourses = yield call(FavoriteCoursesApi.getFavorites);

    yield put(FavoriteCoursesActions.getFavoriteCoursesDone(favoriteCourses));
  } catch (error) {
    yield put(FavoriteCoursesActions.getFavoriteCoursesError(error.message));
  }
}

function* addFavoriteWatcher() {
  yield takeLatest(FavoriteCoursesTypes.ADD_FAVORITE, addFavoriteHandler);
}

function* removeFavoriteWatcher() {
  yield takeLatest(FavoriteCoursesTypes.REMOVE_FAVORITE, removeFavoriteHandler);
}

function* getFavoritesWatcher() {
  yield takeLatest(FavoriteCoursesTypes.GET_FAVORITE_COURSES, getFavoritesHandler);
}

export default [
  addFavoriteWatcher,
  getFavoritesWatcher,
  removeFavoriteWatcher,
];
