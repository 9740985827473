import {
  put, takeLatest,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { HOME } from 'utils/routes';

import { ConfirmationTypes } from 'reducers/bookingConfirmation';

export function* goToHomeHandler() {
  try {
    yield put(push(HOME));
  } catch (error) {
    yield put(ConfirmationTypes.getConfirmationError(error.message));
  }
}

function* goToHomeWatcher() {
  yield takeLatest(ConfirmationTypes.GO_TO_HOME, goToHomeHandler);
}

export default [
  goToHomeWatcher,
];
