import TagManager from 'react-gtm-module';
import hasKey from 'lodash/has';
import LocalStorage from '../LocalStorage';

class Gtm {
  static defaultEvent = {
    event: '',
    eventCategory: '',
    eventAction: '',
    eventLabel: '',
    eventValue: '',
  };

  static defaultPageView = {
    event: 'vpv',
    virtualPagePath: '',
    userId: '',
    utmChannel: '',
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmAdgroup: '',
    utmKeyword: '',
    utmCreative: '',
    utmDevice: '',
    utmNetwork: '',
    utmLocation: '',
    gclid: '',
    msclkid: '',
  };

  static createPayload(type, o) {
    const data = o || {};
    const defaultPayload = Gtm[`default${type}`];
    const payload = {};
    Object.keys(defaultPayload).forEach((key) => {
      payload[key] = hasKey(data, key) ? data[key] : defaultPayload[key];
    });
    return { ...payload, ...o?.extraParams };
  }

  static trackEvent(o) {
    TagManager.dataLayer({
      dataLayer: Gtm.createPayload('Event', o),
    });
  }

  static changeDataLayer(data) {
    TagManager.dataLayer({ dataLayer: data });
  }

  static trackPageView(path, currentUserId) {
    const userId = currentUserId || LocalStorage.getValue('uuid');
    const utmParams = LocalStorage.getUtmParams();
    TagManager.dataLayer({
      dataLayer: Gtm.createPayload('PageView', { virtualPagePath: path, userId, ...utmParams }),
    });
  }

  static removeTransactionData() {
    TagManager.dataLayer({
      dataLayer: {
        transactionId: undefined,
        transactionTotal: undefined,
        bookingtype: undefined,
        transactionProducts: undefined,
      },
    });
  }
}

export default Gtm;
