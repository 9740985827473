import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getRewardsProfileUrl: null,
  getRewardsProfileUrlDone: ['user'],
  getRewardsProfileUrlError: ['error'],
  getRewardsPoints: null,
  getRewardsPointsDone: ['rewardsPoints'],
  getRewardsPointsError: ['error'],
});

export const RewardsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  rewardsPoints: null,
  rewardsProfileUrl: '',
});

const getRewardsProfileUrl = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
});

const getRewardsProfileUrlDone = (state, { user }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  ...user,
});

const getRewardsProfileUrlError = (state, { error }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: error,
});

const getRewardsPoints = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
});

const getRewardsPointsDone = (state, { rewardsPoints }) => state.merge({
  isLoading: false,
  isDone: true,
  rewardsPoints,
  isError: false,
});

const getRewardsPointsError = (state, { error }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: error,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_REWARDS_PROFILE_URL]: getRewardsProfileUrl,
  [Types.GET_REWARDS_PROFILE_URL_DONE]: getRewardsProfileUrlDone,
  [Types.GET_REWARDS_PROFILE_URL_ERROR]: getRewardsProfileUrlError,
  [Types.GET_REWARDS_POINTS]: getRewardsPoints,
  [Types.GET_REWARDS_POINTS_DONE]: getRewardsPointsDone,
  [Types.GET_REWARDS_POINTS_ERROR]: getRewardsPointsError,
});
