import SupremeGolfApiClient from './SupremeGolfApiClient';

const COUNTRIES_ENDPOINT = 'v6/countries';

class CountriesApi {
  static async getCountriesList() {
    const response = await SupremeGolfApiClient.get(`${COUNTRIES_ENDPOINT}`);
    const { data } = response;
    return data.countries;
  }

  static async getValidCountries() {
    const response = await SupremeGolfApiClient.get(
      `${COUNTRIES_ENDPOINT}/explore_enabled`,
    );
    const { data } = response;
    return data.countries;
  }
}

export default CountriesApi;
