import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import SearchDealsActions, { SearchDealsTypes } from 'reducers/searchDeals';
import DealsApi from 'apis/supremeGolfApi/DealsApi';

export function* requestSearchDealsHandler({ lat, lon, params }) {
  try {
    const { radius, limit } = params;
    const optionalParams = {
      lat, lon, radius, limit,
    };

    const response = yield call(DealsApi.getDealsNear, lat, lon, optionalParams);

    yield put(SearchDealsActions.getDealsDone(response));
  } catch (error) {
    yield put(SearchDealsActions.getDealsError(error.message));
  }
}

function* requestSearchDealsWatcher() {
  yield takeLatest(
    SearchDealsTypes.GET_DEALS,
    requestSearchDealsHandler,
  );
}

export default [
  requestSearchDealsWatcher,
];
