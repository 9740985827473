import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import ExploreActions, { ExploreTypes } from 'reducers/explore';
import StatesApi from 'apis/supremeGolfApi/StatesApi';
import CountriesApi from 'apis/supremeGolfApi/CountriesApi';
import ExploreApi from 'apis/supremeGolfApi/ExploreApi';

export function* requestInitialDataHandler({ params }) {
  try {
    const { country, state } = params;
    const [states, countries] = yield all([
      call(StatesApi.getCountryStates, `/${country}`),
      call(CountriesApi.getValidCountries),
    ]);
    yield put(ExploreActions.getInitialDataDone({ states, countries }));

    if (state) {
      const hierarchizedUrl = `/${country}/${state}`;
      yield put(ExploreActions.setCriteria('state'));
      yield put(ExploreActions.setSearchId(hierarchizedUrl));
      yield put(ExploreActions.getExploreContent({ stateId: hierarchizedUrl }));
    } else {
      const hierarchizedUrl = `/${country}`;
      yield put(ExploreActions.setCriteria('country'));
      yield put(ExploreActions.setSearchId(hierarchizedUrl));
      yield put(ExploreActions.getExploreContent({ countryId: hierarchizedUrl }));
    }
  } catch (error) {
    yield put(ExploreActions.getInitialDataError(error.message));
    yield put(push('/not-found'));
  }
}

export function* requestExploreContentHandler({ params }) {
  try {
    const explore = yield call(ExploreApi.getExploreContent, params);

    if (!(explore.state || explore.country)) {
      yield put(push('/not-found'));
    }

    yield put(ExploreActions.getExploreContentDone(explore));
  } catch (error) {
    yield put(ExploreActions.getExploreContentError(error.message));
  }
}

function* requestInitialDataWatcher() {
  yield takeLatest(ExploreTypes.GET_INITIAL_DATA, requestInitialDataHandler);
}

function* requestExploreContentWatcher() {
  yield takeLatest(ExploreTypes.GET_EXPLORE_CONTENT, requestExploreContentHandler);
}

export default [
  requestInitialDataWatcher,
  requestExploreContentWatcher,
];
