import SupremeGolfApiClient from './SupremeGolfApiClient';

const ALERTS_ENDPOINT = 'v6/alerts';

class AlertsApi {
  static async dealAlert(email, cityId, zipcodeId, gRecaptchaResponseData) {
    const body = {
      deliverTo: email,
      cityId,
      zipcodeId,
      gRecaptchaResponseData,
      type: 'NearbyDealAlert',
    };
    await SupremeGolfApiClient.post(ALERTS_ENDPOINT, body);
  }

  static async addTeeTimePriceAlert(courseId, params) {
    const body = {
      courseId,
      type: 'TeeTimePriceAlert',
      ...params,
    };
    await SupremeGolfApiClient.post(ALERTS_ENDPOINT, body);
  }

  static async editTeeTimePriceAlert(alertId, courseId, params) {
    const body = {
      courseId,
      type: 'TeeTimePriceAlert',
      ...params,
    };

    const response = await SupremeGolfApiClient.patch(
      `${ALERTS_ENDPOINT}/${alertId}`,
      body,
    );
    const { data } = response;

    return data.alert;
  }

  static async removePriceAlert(alertId) {
    await SupremeGolfApiClient.delete(`${ALERTS_ENDPOINT}/${alertId}`);
  }

  static async getPriceAlerts() {
    const response = await SupremeGolfApiClient.get(ALERTS_ENDPOINT);
    const { data } = response;

    return data.alerts;
  }
}

export default AlertsApi;
