import SupremeGolfApiClient from './SupremeGolfApiClient';

const RESET_PASSWORD_ENDPOINT = 'v6/users/reset-password';

class ResetPasswordApi {
  static async resetPassword(token, password, passwordConfirmation) {
    const response = await SupremeGolfApiClient.post(RESET_PASSWORD_ENDPOINT, {
      token,
      password,
      passwordConfirmation,
    });
    const { data } = response;
    return data;
  }
}

export default ResetPasswordApi;
