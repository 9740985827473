import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  addFavorite: ['id', 'page', 'data'],
  addFavoriteDone: null,
  addFavoriteError: ['message'],
  removeFavorite: ['id', 'page', 'data'],
  removeFavoriteDone: ['removedCourseId'],
  removeFavoriteError: ['message'],
  getFavoriteCourses: [],
  getFavoriteCoursesDone: ['favoriteCourses'],
  getFavoriteCoursesError: ['message'],
});

export const FavoriteCoursesTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  favoriteCourses: [],
});

const toggleFavorite = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const toggleFavoriteDone = (state, { removedCourseId }) => {
  const favoriteCourses = state.favoriteCourses.filter(
    (course) => course.id !== removedCourseId,
  );

  return state.merge({
    isLoading: false,
    isError: false,
    favoriteCourses,
  });
};

const toggleFavoriteError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const getFavorites = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  errorMessage: '',
});

const getFavoritesDone = (state, { favoriteCourses }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  errorMessage: '',
  favoriteCourses,
});

const getFavoritesError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_FAVORITE]: toggleFavorite,
  [Types.ADD_FAVORITE_DONE]: toggleFavoriteDone,
  [Types.ADD_FAVORITE_ERROR]: toggleFavoriteError,
  [Types.REMOVE_FAVORITE]: toggleFavorite,
  [Types.REMOVE_FAVORITE_DONE]: toggleFavoriteDone,
  [Types.REMOVE_FAVORITE_ERROR]: toggleFavoriteError,
  [Types.GET_FAVORITE_COURSES]: getFavorites,
  [Types.GET_FAVORITE_COURSES_DONE]: getFavoritesDone,
  [Types.GET_FAVORITE_COURSES_ERROR]: getFavoritesError,
});
