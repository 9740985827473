import SupremeGolfApiClient from './SupremeGolfApiClient';

const SIGN_IN_ENDPOINT = 'v6/users/sign-in';

class LoginApi {
  static async login(email, password, rememberMe, invitationToken, iterableTracking) {
    const payload = {
      email,
      password,
      rememberMe,
    };
    if (invitationToken) payload.invitationToken = invitationToken;
    if (iterableTracking) payload.iterableTracking = iterableTracking;
    const response = await SupremeGolfApiClient.post(SIGN_IN_ENDPOINT, payload);
    const { data } = response;
    return data;
  }
}

export default LoginApi;
