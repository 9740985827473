import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setMessage: ['message'],
  setFoundEverything: ['foundEverything'],
  postFeedback: ['foundEverything', 'message'],
  postFeedbackDone: [],
  postFeedbackError: ['message'],
});

export const FeedbackTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  foundEverything: null,
  message: '',
  isLoading: false,
  isError: false,
  success: false,
});

const setMessage = (state, { message }) => state.merge({ message });
const setFoundEverything = (state, { foundEverything }) => state.merge({ foundEverything });

const postFeedback = (state) => state.merge({
  isLoading: true,
  isError: false,
  success: false,
});

const postFeedbackDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  success: true,
});

const postFeedbackError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
  success: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_MESSAGE]: setMessage,
  [Types.SET_FOUND_EVERYTHING]: setFoundEverything,
  [Types.POST_FEEDBACK]: postFeedback,
  [Types.POST_FEEDBACK_DONE]: postFeedbackDone,
  [Types.POST_FEEDBACK_ERROR]: postFeedbackError,
});
