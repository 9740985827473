const preferences = [
  'promotionalOptOut',
  'weeklyOptOut',
  'contestsAndSweepstakesOptOut',
  'newsletterOptOut',
  'pushNotificationMarketingPromosOptOut',
  'pushNotificationWeeklyDealsOptOut',
  'pushNotificationContestsSweepstakesOptOut',
];

const getPreferences = (optList) => {
  const optAll = optList.findIndex((item) => item === 'all') !== -1;
  const consumerPrivacyAgreements = {};
  preferences.forEach((preference) => {
    consumerPrivacyAgreements[preference] = {
      suspended: optAll ? !optAll : !optList.includes(preference),
      reason: '',
    };
  });
  return { consumerPrivacyAgreements };
};

export default getPreferences;
