import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import CountryActions, { CountryTypes } from 'reducers/country';
import CountriesApi from 'apis/supremeGolfApi/CountriesApi';

export function* requestCountriesListHandler() {
  try {
    const countries = yield call(CountriesApi.getCountriesList);
    const countriesList = countries.map((country) => ({
      id: country.iso2,
      label: country.name,
    })).filter((country) => country.id !== 'US');
    countriesList.unshift({ id: 'US', label: 'United States' });
    yield put(CountryActions.getCountriesListDone(countriesList));
  } catch (error) {
    yield put(CountryActions.getCountriesListError(error.message));
  }
}

function* requestCountriesListWatcher() {
  yield takeLatest(
    CountryTypes.GET_COUNTRIES_LIST,
    requestCountriesListHandler,
  );
}

export default [
  requestCountriesListWatcher,
];
