import {
  call, put, select, takeLatest, getContext,
} from 'redux-saga/effects';

import TimeSlotsActions, { sortTimeSlots, TimeSlotsTypes } from 'reducers/timeSlots';
import CourseSuggestionsActions from 'reducers/courseSuggestions';
import TrackingActions from 'reducers/tracking';
import SearchParamsActions from 'reducers/searchParams';
import flowActions from 'reducers/flow';
import TimeSlotsApi from 'apis/supremeGolfApi/TimeSlotsApi';
import DateHelper from 'utils/dateHelper';
import { teeTimeFailure } from 'sagas/checkout';
import { UNAVAILABLE_TEE_TIMES_ERROR_CODE } from 'utils/constants';

function* trackDateRecommendation(summary) {
  const profile = yield select((state) => state.profile);
  const recommendationSuccess = summary.availability?.forDate;
  yield put(CourseSuggestionsActions.trackCourseSuggestionEvent({
    event: recommendationSuccess ? 'clickOnDateRecommendationSuccess' : 'clickOnDateRecommendationFail',
    eventAction: 'click',
    iterableParams: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
    },
  }));
  yield put(SearchParamsActions.setIsRecommendedDate(false));
}

export function* requestTimeSlotsHandler({ courseId, params }) {
  try {
    const {
      date,
      players: qty,
      holes: numHoles,
      price,
      time,
      cart: isRiding,
      rate,
      rateTypes,
      isPrepaidOnly,
      isRecommendedDate,
      networkMembershipOnly,
      slug,
    } = params;

    let minHour = null;
    let maxHour = null;
    let minPrice = null;
    let maxPrice = null;

    if (time) [minHour, maxHour] = time;
    if (price) [minPrice, maxPrice] = price;

    const searchParams = {
      courseId,
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      isRiding,
      numHoles,
      rateTypes,
      isPrepaidOnly,
      networkMembershipOnly,
    };

    if (date) searchParams.date = DateHelper.formatDate(date);
    if (rate) searchParams[rate] = true;

    const {
      summary,
      teeTimeGroups: timeSlotList,
      authToken,
    } = yield call(TimeSlotsApi.getTimeSlots, searchParams);
    yield put(flowActions.setfTeeTimes({ authToken }));
    sessionStorage.setItem('teeTimesToken', authToken);
    sessionStorage.removeItem('checkoutBackURL');

    if (summary.availability?.error) {
      const filterValues = yield select((state) => state.search.searchParams.filterValues);
      const { previousUrl } = filterValues;
      yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE, {
        previousUrl: encodeURIComponent(previousUrl) || '/',
        courseId,
      });
      return;
    }

    const profile = yield select((state) => state.profile);

    if (!summary.availability?.forDate) {
      const detail = yield select(({ courseDetail }) => courseDetail);
      const iterable = yield getContext('iterable');
      const iterableTracking = yield call(iterable.getTrackingParams);
      const gtm = yield getContext('gtm');

      yield call(gtm.trackEvent, {
        eventCategory: 'search',
        eventAction: 'click',
        eventLabel: slug,
        event: 'feedback-landing-with-no-tee-times',
      });
      yield put(TrackingActions.trackEvent('feedback-landing-with-no-tee-times', {
        email: profile?.email,
        campaignId: iterableTracking?.campaign_id,
        templateId: iterableTracking?.template_id,
        messageId: iterableTracking?.message_id,
        userId: profile?.id,
        platform: 'web',
        course: {
          slug: detail?.course?.slug,
          name: detail?.course?.name,
          zipcode: detail?.course?.addressZipcode,
        },
      }));
    }

    if (isRecommendedDate) yield trackDateRecommendation(summary);

    yield put(TimeSlotsActions.getTimeSlotsDone(summary, timeSlotList));
  } catch (error) {
    yield put(TimeSlotsActions.getTimeSlotsError(error.message));
  }
}

export function* requestPrepareTeeTimeHandler({ teeTimeId, qty }) {
  try {
    const { playersFilter } = yield select((state) => state.search.searchParams.filterValues);
    const data = yield call(TimeSlotsApi.prepare, { teeTimeId, qty: (qty ?? playersFilter) || 1 });
    if (data?.error) throw new Error(data?.error);
    yield put(TimeSlotsActions.prepareTeeTimeDone(data.preparedTeeTime));
  } catch (error) {
    const errorMessage = error?.response?.data?.message ?? error?.message;
    const { sorted } = yield select((state) => state.timeSlots.teeTimes);
    const sortedTeeTimes = sorted.map((teeTime) => {
      if (teeTime.teeTimeId === teeTimeId) return { ...teeTime, isSoldOut: true };
      return teeTime;
    });
    yield put(TimeSlotsActions.setSortedTeeTimes(sortedTeeTimes));
    yield put(TimeSlotsActions.prepareTeeTimeError(errorMessage));
  }
}

export function* requestGetRelevantTeeTimesHandler({ courseId, date, teeTimeId }) {
  try {
    const data = yield call(TimeSlotsApi.getRelevantTeeTimes, { courseId, date, teeTimeId });
    const {
      selectedTeeTime: selected,
      otherTeeTimes: others,
      summary,
      authToken,
    } = data;

    yield put(flowActions.setfTeeTimes({ authToken }));
    sessionStorage.setItem('teeTimesToken', authToken);
    yield put(flowActions.setfProvider({ authToken }));
    sessionStorage.setItem('providerToken', authToken);
    const { pathname, search, query } = yield select((state) => state.router.location);

    sessionStorage.setItem('checkoutBackURL', `${pathname}${search}`);

    if (query?.isRecommendedDate === 'true') yield trackDateRecommendation(summary);

    let teetimesToBeSorted = [...others];
    if (selected?.teeTimeId) {
      const qty = selected.players.length ? Math.min(...selected.players) : 1;
      yield put(TimeSlotsActions.prepareTeeTime(selected.teeTimeId, qty));
      teetimesToBeSorted = [selected, ...teetimesToBeSorted];
    }

    const sorted = sortTimeSlots(teetimesToBeSorted, 'time');
    const teeTimes = { sorted, selected, others };
    yield put(TimeSlotsActions.getRelevantTeeTimesDone(teeTimes, summary));
  } catch (error) {
    const errorMessage = error?.response?.data?.message ?? error?.message;
    yield put(TimeSlotsActions.getRelevantTeeTimesError(errorMessage));
  }
}

function* requestTimeSlotsWatcher() {
  yield takeLatest(TimeSlotsTypes.GET_TIME_SLOTS, requestTimeSlotsHandler);
}

function* requestPrepareTeeTimeWatcher() {
  yield takeLatest(TimeSlotsTypes.PREPARE_TEE_TIME, requestPrepareTeeTimeHandler);
}

function* requestRelevantTeeTimesWatcher() {
  yield takeLatest(TimeSlotsTypes.GET_RELEVANT_TEE_TIMES, requestGetRelevantTeeTimesHandler);
}

export default [
  requestTimeSlotsWatcher,
  requestPrepareTeeTimeWatcher,
  requestRelevantTeeTimesWatcher,
];
