// Keep this file in alphabetical order.
module.exports = {
  breadcrumbs: false,
  claimCourse: false,
  courseOperators: false,
  courseOperatorsFreeMarketplace: false,
  courseOperatorsFreeMarketplaceSignupDetails: false,
  courseOperatorsVipPerks: false,
  courseOperatorsGolfCourseResources: false,
  courseReviews: false,
  featuredContests: false,
  courseSuggestion: false,
  dealsNav: false,
  dealsPage: false,
  deleteUser: false,
  disclaimerPage: true,
  exploreCountriesStates: false,
  extendedSupportForm: true,
  feedbackPage: true,
  forCourses: false,
  giftCardLink: false,
  golfTimePromotions: false,
  golfSpecials: true,
  hasNavMenu: false,
  hereToHelp: false,
  marketingCommunication: false,
  marketingOptInDefault: false,
  membership: false,
  moderator: false,
  myMemberships: true,
  nearMeNav: false,
  networkMembership: false,
  showNetworkSales: false,
  offers: false,
  partnerLogo: false,
  poweredByLogo: true,
  poweredByLogoScenery: true,
  priceAlerts: false,
  providerLogos: false,
  recommendedCourses: true,
  reviewAttachmentCta: false,
  rewardsAccumulation: false,
  rewardsReferral: false,
  sectionCourses: false,
  shareReservation: false,
  showDealsOnly: true,
  showForeplayReviewedOnly: false,
  showNearByAlertForm: false,
  showPrepaidRoundsFilter: false,
  showBarstoolBestFilter: false,
  showNetworkMembershipFilter: false,
  storeUrl: false,
  subScenery: false,
  supportReason: false,
  topDeals: false,
  barstoolVip: false,
  showTeeTimesBlog: false,
};
