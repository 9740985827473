import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { LIGHTNING_RATE, PLAYERS_RANGE } from 'utils/constants';

const { Types, Creators } = createActions({
  hardResetFilters: null,
  performCheckoutPreparation: ['id', 'prepaymentRuleId', 'reservationUrl', 'provider', 'sourceProvider'],
  performFilter: [],
  performMembershipSearch: null,
  performProvidersSearch: null,
  performRateTypeSearch: null,
  performSearch: ['forcedTargetEntity', 'routerAction', 'onlySearch', 'callback'],
  resetFilters: null,
  resetFiltersWithoutDate: null,
  resetFiltersWithoutDateNorTimeSlot: null,
  setCart: ['cart'],
  setDate: ['date', 'isRecommendedDate'],
  setDateFilter: ['dateFilter'],
  setDealsOnlyOff: ['previousRateTypes'],
  setDealsOnlyOn: ['rateTypes'],
  setForeplayReviewedOnly: ['foreplayReviewedOnlyValue'],
  setHoles: ['holes'],
  setHolesFilter: ['holesFilter'],
  setHotDealsSearch: ['hotDealsSearch'],
  setIsPrepaidOnly: ['isPrepaidOnly'],
  setIsBarstoolBestOnly: ['isBarstoolBestOnly'],
  setIsBarstoolVipOnly: ['isBarstoolVipOnly'],
  setNetworkMembershipOnly: ['networkMembershipOnly'],
  setIsRecommendedDate: ['isRecommendedDate'],
  setLocation: ['location'],
  setLocationFilter: ['locationFilter'],
  setLocationRange: ['locationRange'],
  setNearMeSearch: ['nearMeSearch'],
  setPlayers: ['players'],
  setPlayersFilter: ['playersFilter'],
  setPlayersRange: ['playersRange'],
  setPreviousRateTypes: ['previousRateTypes'],
  setPrice: ['price'],
  setPreviousUrl: ['previousUrl'],
  setRate: ['rate'],
  setRateTypes: ['rateTypes'],
  setSelectedCart: ['selectedCart'],
  setSelectedHoles: ['selectedHoles'],
  setSelectedRateType: ['selectedRateType'],
  setTime: ['time'],
  setTimeSlot: ['timeSlot'],
  setTeeOffAt: ['teeOffAt'],
  setValues: ['searchValues', 'filterValues'],
  showSearchFormError: ['show'],
  toggleDealsOnly: null,
  setThroughSection: ['throughSection'],
  setIsBestDeal: ['isBestDeal'],
  setSkipRate: ['skipRate'],
  setSkipProvider: ['skipProvider'],
  setViewMode: ['viewMode'],
  goBack: ['backToUrl'],
});

export const SearchParamsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  filterValues: {
    cart: null,
    dateFilter: new Date(),
    dealsOnly: false,
    holesFilter: null,
    foreplayReviewedOnly: false,
    locationFilter: null,
    locationRange: null,
    playersFilter: null,
    playersRange: PLAYERS_RANGE,
    previousRateTypes: [],
    previousUrl: null,
    price: null,
    isPrepaidOnly: false,
    isBarstoolBestOnly: false,
    isBarstoolVipOnly: false,
    networkMembershipOnly: false,
    isRecommendedDate: false,
    rate: null,
    rateTypes: [],
    selectedCart: null,
    selectedHoles: '',
    selectedRateType: {},
    time: null,
    timeSlot: null,
    teeOffAt: null,
    isBestDeal: false,
    skipRate: null,
    skipProvider: null,
  },
  searchFormError: false,
  searchValues: {
    date: new Date(),
    holes: null,
    hotDealsSearch: false,
    foreplayReviewedOnly: false,
    location: null,
    players: null,
  },
  throughSection: '',
  viewMode: 'list',
});

const hardResetFilters = (state) => state.merge({
  ...INITIAL_STATE,
  searchValues: {
    ...state.searchValues,
    foreplayReviewedOnly: false,
  },
});

const resetFilters = (state) => state.merge({
  filterValues: {
    ...state.filterValues,
    dateFilter: new Date(),
    dealsOnly: false,
    foreplayReviewedOnly: false,
    playersFilter: null,
    holesFilter: null,
    isPrepaidOnly: false,
    isBarstoolBestOnly: false,
    isBarstoolVipOnly: false,
    networkMembershipOnly: false,
    locationRange: null,
    price: null,
    time: null,
    cart: null,
    timeSlot: null,
    rate: null,
    rateTypes: [],
    playersRange: PLAYERS_RANGE,
    teeOffAt: null,
  },
  searchValues: {
    ...state.searchValues,
    foreplayReviewedOnly: false,
  },
});

const resetFiltersWithoutDate = (state) => state.merge({
  filterValues: {
    ...state.filterValues,
    dealsOnly: false,
    foreplayReviewedOnly: false,
    playersFilter: null,
    holesFilter: null,
    isPrepaidOnly: false,
    isBarstoolBestOnly: false,
    isBarstoolVipOnly: false,
    networkMembershipOnly: false,
    locationRange: null,
    price: null,
    time: null,
    cart: null,
    timeSlot: null,
    rate: null,
    rateTypes: [],
    playersRange: PLAYERS_RANGE,
    teeOffAt: null,
  },
  searchValues: {
    ...state.searchValues,
    foreplayReviewedOnly: false,
  },
});

const resetFiltersWithoutDateNorTimeSlot = (state) => state.merge({
  filterValues: {
    ...state.filterValues,
    dealsOnly: false,
    foreplayReviewedOnly: false,
    playersFilter: null,
    holesFilter: null,
    isPrepaidOnly: false,
    isBarstoolBestOnly: false,
    isBarstoolVipOnly: false,
    networkMembershipOnly: false,
    locationRange: null,
    price: null,
    time: null,
    cart: null,
    rate: null,
    rateTypes: [],
    playersRange: PLAYERS_RANGE,
  },
  searchValues: {
    ...state.searchValues,
    foreplayReviewedOnly: false,
  },
});

const setValue = (fieldCollection, fieldName) => (state, action) => (state.merge({
  [fieldCollection]: { ...state[fieldCollection], [fieldName]: action[fieldName] },
}));

const setDate = (state, { date, isRecommendedDate }) => (state.merge({
  filterValues: { ...state.filterValues, isRecommendedDate: !!isRecommendedDate },
  searchValues: { ...state.searchValues, date },
}));

const setLocation = (state, action) => (state.merge({
  searchValues: { ...state.searchValues, location: action.location },
  searchFormError: false,
}));

const setValues = (state, { searchValues, filterValues }) => state.merge({
  searchValues: state.searchValues.merge(searchValues),
  filterValues: state.filterValues.merge(filterValues),
});

const setHotDealsSearch = (state, { hotDealsSearch }) => state.merge({
  searchValues: { ...state.searchValues, hotDealsSearch },
  filterValues: state.filterValues.merge({
    dealsOnly: hotDealsSearch,
    rateTypes: [LIGHTNING_RATE],
    previousRateTypes: [],
  }),
});

const setNearMeSearch = (state, { nearMeSearch }) => state.merge({
  searchValues: { ...state.searchValues, nearMeSearch },
});

const showSearchFormError = (state, { show }) => state.merge({
  searchFormError: show,
});

const setDealsOnlyOff = (state, { previousRateTypes }) => state.merge({
  filterValues: state.filterValues.merge({
    dealsOnly: false,
    rateTypes: previousRateTypes,
    previousRateTypes: [],
  }),
});

const setDealsOnlyOn = (state, { rateTypes }) => state.merge({
  filterValues: state.filterValues.merge({
    dealsOnly: true,
    rateTypes: [LIGHTNING_RATE],
    previousRateTypes: rateTypes,
  }),
});

const setForeplayReviewedOnly = (state, { foreplayReviewedOnlyValue }) => state.merge({
  filterValues: state.filterValues.merge({
    foreplayReviewedOnly: foreplayReviewedOnlyValue,
  }),
  searchValues: state.searchValues.merge({
    foreplayReviewedOnly: foreplayReviewedOnlyValue,
  }),
});

const setPreviousRateTypes = (state, { previousRateTypes }) => state.merge({
  filterValues: state.filterValues.merge({
    previousRateTypes,
  }),
});

const toggleDealsOnly = (state) => state.merge({
  filterValues: state.filterValues.merge({
    dealsOnly: !state.filterValues.dealsOnly,
  }),
});

const setThroughSection = (state, { throughSection }) => state.merge({
  throughSection,
});

const setViewMode = (state, { viewMode }) => state.merge({
  viewMode,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.HARD_RESET_FILTERS]: hardResetFilters,
  [Types.RESET_FILTERS_WITHOUT_DATE_NOR_TIME_SLOT]: resetFiltersWithoutDateNorTimeSlot,
  [Types.RESET_FILTERS_WITHOUT_DATE]: resetFiltersWithoutDate,
  [Types.RESET_FILTERS]: resetFilters,
  [Types.SET_CART]: setValue('filterValues', 'cart'),
  [Types.SET_DATE_FILTER]: setValue('filterValues', 'dateFilter'),
  [Types.SET_DATE]: setDate,
  [Types.SET_DEALS_ONLY_OFF]: setDealsOnlyOff,
  [Types.SET_DEALS_ONLY_ON]: setDealsOnlyOn,
  [Types.SET_FOREPLAY_REVIEWED_ONLY]: setForeplayReviewedOnly,
  [Types.SET_HOLES_FILTER]: setValue('filterValues', 'holesFilter'),
  [Types.SET_HOLES]: setValue('searchValues', 'holes'),
  [Types.SET_HOT_DEALS_SEARCH]: setHotDealsSearch,
  [Types.SET_SELECTED_CART]: setValue('filterValues', 'selectedCart'),
  [Types.SET_LOCATION_FILTER]: setValue('filterValues', 'locationFilter'),
  [Types.SET_LOCATION_RANGE]: setValue('filterValues', 'locationRange'),
  [Types.SET_LOCATION]: setLocation,
  [Types.SET_NEAR_ME_SEARCH]: setNearMeSearch,
  [Types.SET_PLAYERS_FILTER]: setValue('filterValues', 'playersFilter'),
  [Types.SET_IS_PREPAID_ONLY]: setValue('filterValues', 'isPrepaidOnly'),
  [Types.SET_IS_BARSTOOL_BEST_ONLY]: setValue('filterValues', 'isBarstoolBestOnly'),
  [Types.SET_IS_BARSTOOL_VIP_ONLY]: setValue('filterValues', 'isBarstoolVipOnly'),
  [Types.SET_NETWORK_MEMBERSHIP_ONLY]: setValue('filterValues', 'networkMembershipOnly'),
  [Types.SET_IS_BEST_DEAL]: setValue('filterValues', 'isBestDeal'),
  [Types.SET_IS_RECOMMENDED_DATE]: setValue('filterValues', 'isRecommendedDate'),
  [Types.SET_SKIP_RATE]: setValue('filterValues', 'skipRate'),
  [Types.SET_SKIP_PROVIDER]: setValue('filterValues', 'skipProvider'),
  [Types.SET_PLAYERS_RANGE]: setValue('filterValues', 'playersRange'),
  [Types.SET_SELECTED_HOLES]: setValue('filterValues', 'selectedHoles'),
  [Types.SET_PLAYERS]: setValue('searchValues', 'players'),
  [Types.SET_PRICE]: setValue('filterValues', 'price'),
  [Types.SET_PREVIOUS_URL]: setValue('filterValues', 'previousUrl'),
  [Types.SET_RATE_TYPES]: setValue('filterValues', 'rateTypes'),
  [Types.SET_RATE]: setValue('filterValues', 'rate'),
  [Types.SET_TIME_SLOT]: setValue('filterValues', 'timeSlot'),
  [Types.SET_TEE_OFF_AT]: setValue('filterValues', 'teeOffAt'),
  [Types.SET_TIME]: setValue('filterValues', 'time'),
  [Types.SET_SELECTED_RATE_TYPE]: setValue('filterValues', 'selectedRateType'),
  [Types.SET_VALUES]: setValues,
  [Types.SHOW_SEARCH_FORM_ERROR]: showSearchFormError,
  [Types.SET_PREVIOUS_RATE_TYPES]: setPreviousRateTypes,
  [Types.TOGGLE_DEALS_ONLY]: toggleDealsOnly,
  [Types.SET_THROUGH_SECTION]: setThroughSection,
  [Types.SET_VIEW_MODE]: setViewMode,
});
