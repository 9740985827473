import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { SIGN_IN, HOME } from 'utils/routes';

import SessionActions from 'reducers/session';
import ResetPasswordActions, {
  ResetPasswordTypes,
} from 'reducers/resetPassword';
import ResetPasswordApi from 'apis/supremeGolfApi/ResetPasswordApi';

export function* requestResetPasswordHandler({
  token,
  password,
  passwordConfirmation,
}) {
  try {
    const resetPassword = yield call(
      ResetPasswordApi.resetPassword,
      token,
      password,
      passwordConfirmation,
    );
    yield put(ResetPasswordActions.resetPasswordDone(resetPassword));
    yield put(SessionActions.redirectTo({ path: HOME }));
    yield put(push(SIGN_IN));
  } catch (error) {
    yield put(ResetPasswordActions.resetPasswordError(error.message));
  }
}

function* requestResetPasswordWatcher() {
  yield takeLatest(
    ResetPasswordTypes.RESET_PASSWORD,
    requestResetPasswordHandler,
  );
}

export default [
  requestResetPasswordWatcher,
];
