import MembershipApiClient from './MembershipApiClient';

const MEMBERSHIP_BASE = 'Membership';

class GetMembershipsApi {
  static async getMemberships() {
    const response = await MembershipApiClient.get(`${MEMBERSHIP_BASE}/getMemberships`);

    return response.data;
  }
}

export default GetMembershipsApi;
