// Keep this file in alphabetical order.
module.exports = {
  breadcrumbs: false,
  claimCourse: false,
  courseOperators: true,
  courseOperatorsFreeMarketplace: true,
  courseOperatorsFreeMarketplaceSignupDetails: true,
  courseOperatorsVipPerks: false,
  courseOperatorsGolfCourseResources: true,
  courseReviews: true,
  featuredContests: true,
  courseSuggestion: false,
  dealsNav: true,
  dealsPage: false,
  deleteUser: true,
  disclaimerPage: true,
  exploreCountriesStates: false,
  extendedSupportForm: false,
  feedbackPage: true,
  forCourses: true,
  giftCardLink: false,
  golfTimePromotions: true,
  golfSpecials: true,
  hasNavMenu: true,
  hereToHelp: true,
  marketingCommunication: true,
  marketingOptInDefault: false,
  membership: false,
  moderator: true,
  nearMeNav: true,
  networkMembership: true,
  showNetworkSales: true,
  myMemberships: false,
  offers: true,
  partnerLogo: false,
  poweredByLogo: true,
  poweredByLogoScenery: false,
  priceAlerts: false,
  providerLogos: false,
  recommendedCourses: false,
  reviewAttachmentCta: true,
  rewardsAccumulation: true,
  rewardsReferral: true,
  sectionCourses: false,
  shareReservation: true,
  showDealsOnly: true,
  showForeplayReviewedOnly: true,
  showNearByAlertForm: true,
  showPrepaidRoundsFilter: true,
  showBarstoolBestFilter: true,
  showNetworkMembershipFilter: true,
  storeUrl: true,
  subScenery: false,
  supportReason: true,
  topDeals: true,
  barstoolVip: false,
  showTeeTimesBlog: false,
};
