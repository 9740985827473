import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  resendUnlockInstructions: ['email'],
  resendUnlockInstructionsDone: null,
  resendUnlockInstructionsError: ['message'],
  unlock: ['unlockToken'],
  unlockDone: null,
  unlockError: ['message'],
});

export const UnlockTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  unlockInstructionsSent: false,
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const resendUnlockInstructions = (state) => state.merge({
  unlockInstructionsSent: false,
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const resendUnlockInstructionsDone = (state) => state.merge({
  unlockInstructionsSent: true,
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const resendUnlockInstructionsError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const unlock = (state) => state.merge({
  unlockInstructionsSent: false,
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const unlockDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const unlockError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESEND_UNLOCK_INSTRUCTIONS]: resendUnlockInstructions,
  [Types.RESEND_UNLOCK_INSTRUCTIONS_DONE]: resendUnlockInstructionsDone,
  [Types.RESEND_UNLOCK_INSTRUCTIONS_ERROR]: resendUnlockInstructionsError,
  [Types.UNLOCK]: unlock,
  [Types.UNLOCK_DONE]: unlockDone,
  [Types.UNLOCK_ERROR]: unlockError,
});
