import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  redirectToNetworkMembership: ['meta'],
  redirectToNetworkMembershipDone: [],
  redirectToNetworkMembershipError: [],
  setNetworkMembershipRedirectTo: ['redirectTo'],
  resetNetworkMembershipBenefits: [],
  getNetworkMembershipBenefits: ['courseId'],
  getNetworkMembershipBenefitsDone: ['benefits'],
  getNetworkMembershipBenefitsError: ['error'],
  getNetworkMembershipCourses: [],
  getNetworkMembershipCoursesDone: ['networkCourses'],
  getNetworkMembershipCoursesError: ['error'],
  getNetworkMembershipPlans: [],
  getNetworkMembershipPlansDone: ['plans', 'benefits'],
  getNetworkMembershipPlansError: ['error'],
  getGolfClubCourses: ['withLocation', 'withBanner'],
  getGolfClubCoursesDone: ['courses', 'banner'],
  getGolfClubCoursesError: ['error'],
  getNetworkMembershipSubscription: [],
  getNetworkMembershipSubscriptionSilent: [],
  getNetworkMembershipSubscriptionDone: ['subscription'],
  getNetworkMembershipSubscriptionError: ['error'],
  getGolfVantageUrl: ['callback'],
  getGolfVantageUrlDone: ['golfVantageUrl'],
  getGolfVantageUrlError: ['error'],
  setNetworkMembershipCreditCard: ['creditCard'],
  setStatus: ['status'],
  purchaseNetworkMembership: ['creditCardId', 'billingPlanId', 'promoCode', 'callback'],
  purchaseNetworkMembershipDone: ['status'],
  purchaseNetworkMembershipError: ['status', 'error'],
  trackNetworkMembershipPurchase: ['planId'],
  cancelNetworkMembership: [],
  cancelNetworkMembershipDone: ['status'],
  cancelNetworkMembershipError: ['status'],
  changeCreditCard: ['creditCardId'],
  changeCreditCardDone: [],
  changeCreditCardError: ['error'],
  renewNetworkMembership: [],
  renewNetworkMembershipDone: ['status'],
  renewNetworkMembershipError: ['status'],
  resetChangeCreditCard: [],
  getCoursesWithOffers: ['status'],
  getCoursesWithOffersDone: ['offers'],
  getCoursesWithOffersError: ['error'],
  getCourseOffers: ['id'],
  getCourseOffersDone: ['courseOffers'],
  getCourseOffersError: ['error'],
  redeemNetworkOffer: ['id', 'meta'],
  redeemNetworkOfferDone: ['courseOffers'],
  redeemNetworkOfferError: ['error'],
  // promocode
  applyMembershipPromoCode: ['promoCode', 'cb'],
  applyMembershipPromoCodeDone: ['plans', 'benefits'],
  applyMembershipPromoCodeError: ['error'],
  resetPromoCode: [],
});

export const NetworkMembershipTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  redirectTo: '',
  status: '',
  isLoading: false,
  benefits: null,
  networkCourses: null,
  golfClubPlans: null,
  golfClubBenefits: null,
  golfClubCourses: null,
  golfClubBanner: null,
  subscription: null,
  golfVantageUrl: '',
  creditCard: null,
  isFetchingBenefits: false,
  isFetchingBenefitsDone: false,
  isFetchingBenefitsError: '',
  isFetchingNetworkCourses: false,
  isFetchingNetworkCoursesDone: false,
  isFetchingNetworkCoursesError: '',
  isFetchingPlans: false,
  isFetchingPlansDone: false,
  isFetchingPlansError: '',
  isFetchingGolfClubCourses: false,
  isFetchingGolfClubCoursesDone: false,
  fetchingGolfClubCoursesError: '',
  isFetchingSubscription: false,
  isFetchingSubscriptionDone: false,
  fetchingSubscriptionError: '',
  isFetchingGolfVantageUrl: false,
  isFetchingGolfVantageUrlDone: false,
  fetchingGolfVantageUrlError: '',
  isPurchaseMembershipLoading: false,
  isPurchaseMembershipDone: false,
  purchaseMembershipError: '',
  isCancellingMembership: false,
  isCancellingMembershipDone: false,
  isChangingCreditCard: false,
  isChangingCreditCardDone: false,
  creditCardError: '',
  isRenewingMembership: false,
  isRenwingMembershipDone: false,
  isDone: false,
  isError: false,
  offers: [],
  isFetchingOffers: false,
  isFetchingOffersDone: false,
  fetchingOffersError: '',
  courseOffers: [],
  isFetchingCourseOffers: false,
  isFetchingCourseOffersDone: false,
  fetchingCourseOffersError: '',
  isRedeemingNetworkOffer: false,
  isRedeemingNetworkOfferDone: false,
  redeemingNetworkOfferError: '',
  // Promo code
  isApplyingPromoCode: false,
  isApplyingPromoCodeDone: false,
  applyingPromoCodeError: null,
});

const setNetworkMembershipRedirectTo = (state, { redirectTo }) => state.merge({
  redirectTo,
});

const redirectToNetworkMembership = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const redirectToNetworkMembershipDone = (state) => state.merge({
  isLoading: false,
  iDone: true,
  isError: false,
});

const redirectToNetworkMembershipError = (state) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
});

const resetNetworkMembershipBenefits = (state) => state.merge({
  benefits: null,
  isFetchingBenefits: false,
  isFetchingBenefitsDone: false,
  isFetchingBenefitsError: '',
});

const getNetworkMembershipBenefits = (state) => state.merge({
  isFetchingBenefits: true,
  isFetchingBenefitsDone: false,
  fetchingBenefitsError: '',
});

const getNetworkMembershipBenefitsDone = (state, { benefits }) => state.merge({
  benefits,
  isFetchingBenefits: false,
  isFetchingBenefitsDone: true,
  fetchingBenefitsError: '',
});

const getNetworkMembershipBenefitsError = (state, { error }) => state.merge({
  isFetchingBenefits: false,
  isFetchingBenefitsDone: true,
  fetchingBenefitsError: error,
});
const getNetworkMembershipCourses = (state) => state.merge({
  isFetchingNetworkCourses: true,
  isFetchingNetworkCoursesDone: false,
  isFetchingNetworkCoursesError: '',
});

const getNetworkMembershipCoursesDone = (state, { networkCourses }) => state.merge({
  networkCourses,
  isFetchingNetworkCourses: false,
  isFetchingNetworkCoursesDone: true,
  isFetchingNetworkCoursesError: '',
});

const getNetworkMembershipCoursesError = (state, { error }) => state.merge({
  isFetchingNetworkCourses: false,
  isFetchingNetworkCoursesDone: true,
  isFetchingNetworkCoursesError: error,
});

const getNetworkMembershipPlans = (state) => state.merge({
  isFetchingPlans: true,
  isFetchingPlansDone: false,
  fetchingPlansError: '',
});

const getNetworkMembershipPlansDone = (state, { plans, benefits }) => state.merge({
  golfClubPlans: plans,
  golfClubBenefits: benefits,
  isFetchingPlans: false,
  isFetchingPlansDone: true,
  fetchingPlansError: '',
});

const getNetworkMembershipPlansError = (state, { error }) => state.merge({
  isFetchingPlans: false,
  isFetchingPlansDone: true,
  fetchingPlansError: error,
});

const getGolfClubCourses = (state) => state.merge({
  isFetchingGolfClubCourses: true,
  isFetchingGolfClubCoursesDone: false,
  fetchingGolfClubCoursesError: '',
});

const getGolfClubCoursesDone = (state, { courses, banner }) => state.merge({
  golfClubCourses: courses,
  golfClubBanner: banner,
  isFetchingGolfClubCourses: false,
  isFetchingGolfClubCoursesDone: true,
  fetchingGolfClubCoursesError: '',
});

const getGolfClubCoursesError = (state, { errorMessage }) => state.merge({
  isFetchingGolfClubCourses: false,
  isFetchingGolfClubCoursesDone: true,
  fetchingGolfClubCoursesError: errorMessage,
});

const getNetworkMembershipSubscriptionSilent = (state) => state.merge({
  isFetchingSubscription: false,
  isFetchingSubscriptionDone: false,
  fetchingSubscriptionError: '',
});

const getNetworkMembershipSubscription = (state) => state.merge({
  isFetchingSubscription: true,
  isFetchingSubscriptionDone: false,
  fetchingSubscriptionError: '',
});

const getNetworkMembershipSubscriptionDone = (state, { subscription }) => state.merge({
  isFetchingSubscription: false,
  isFetchingSubscriptionDone: true,
  fetchingSubscriptionError: '',
  subscription,
});

const getNetworkMembershipSubscriptionError = (state, { errorMessage }) => state.merge({
  isFetchingSubscription: true,
  isFetchingSubscriptionDone: false,
  fetchingSubscriptionError: errorMessage,
});

const getGolfVantageUrl = (state) => state.merge({
  isFetchingGolfVantageUrl: true,
  isFetchingGolfVantageUrlDone: false,
  fetchingGolfVantageUrlError: '',
});

const getGolfVantageUrlDone = (state, { golfVantageUrl }) => state.merge({
  golfVantageUrl,
  isFetchingGolfVantageUrl: false,
  isFetchingGolfVantageUrlDone: true,
  fetchingGolfVantageUrlError: '',
});

const getGolfVantageUrlError = (state, { error }) => state.merge({
  isFetchingGolfVantageUrl: false,
  isFetchingGolfVantageUrlDone: true,
  fetchingGolfVantageUrlError: error,
});

const setCreditCard = (state, { creditCard }) => state.merge({
  creditCard,
});

const setStatus = (state, { status }) => state.merge({
  status,
});

const purchaseNetworkMembership = (state) => state.merge({
  isPurchaseMembershipLoading: true,
  isPurchaseMembershipDone: false,
});

const purchaseNetworkMembershipDone = (state, { status }) => state.merge({
  isPurchaseMembershipLoading: false,
  isPurchaseMembershipDone: true,
  status,
});

const purchaseNetworkMembershipError = (state, { status, error }) => state.merge({
  isPurchaseMembershipLoading: false,
  isPurchaseMembershipDone: true,
  purchaseMembershipError: error,
  status,
});

const cancelNetworkMembership = (state) => state.merge({
  isCancellingMembership: true,
  isCancellingMembershipDone: false,
});

const cancelNetworkMembershipDone = (state, { status }) => state.merge({
  isCancellingMembership: false,
  isCancellingMembershipDone: true,
  status,
});

const cancelNetworkMembershipError = (state, { status }) => state.merge({
  isCancellingMembership: false,
  isCancellingMembershipDone: true,
  status,
});

const changeCreditCard = (state) => state.merge({
  isChangingCreditCard: true,
  isChangingCreditCardDone: false,
  creditCardError: '',
});

const changeCreditCardDone = (state) => state.merge({
  isChangingCreditCard: false,
  isChangingCreditCardDone: true,
  creditCardError: '',
});

const changeCreditCardError = (state, { error }) => state.merge({
  isChangingCreditCard: false,
  isChangingCreditCardDone: true,
  creditCardError: error,
});

const renewNetworkMembership = (state) => state.merge({
  isRenewingMembership: true,
  isRenewingMembershipDone: false,
});

const renewNetworkMembershipDone = (state, { status }) => state.merge({
  isRenewingMembership: false,
  isRenewingMembershipDone: true,
  status,
});

const renewNetworkMembershipError = (state, { status }) => state.merge({
  isRenewingMembership: false,
  isRenewingMembershipDone: true,
  status,
});

const resetChangeCreditCard = (state) => state.merge({
  isChangingCreditCard: false,
  isChangingCreditCardDone: false,
  creditCardError: '',
});

const getCoursesWithOffers = (state) => state.merge({
  offers: [],
  isFetchingOffers: true,
  isFetchingOffersDone: false,
  fetchingOffersError: '',
});

const getCoursesWithOffersDone = (state, { offers }) => state.merge({
  offers,
  isFetchingOffers: false,
  isFetchingOffersDone: true,
  fetchingOffersError: '',
});

const getCoursesWithOffersError = (state, { error }) => state.merge({
  isFetchingOffers: false,
  isFetchingOffersDone: true,
  fetchingOffersError: error,
});

const getCourseOffers = (state) => state.merge({
  courseOffers: [],
  isFetchingCourseOffers: true,
  isFetchingCourseOffersDone: false,
  fetchingCourseOffersError: '',
});

const getCourseOffersDone = (state, { courseOffers }) => state.merge({
  courseOffers,
  isFetchingCourseOffers: false,
  isFetchingCourseOffersDone: true,
  fetchingCourseOffersError: '',
});

const getCourseOffersError = (state, { error }) => state.merge({
  isFetchingCourseOffers: false,
  isFetchingCourseOffersDone: true,
  fetchingCourseOffersError: error,
});

const redeemNetworkOffer = (state) => state.merge({
  isRedeemingNetworkOffer: true,
  isRedeemingNetworkOfferDone: false,
  redeemingNetworkOfferError: '',
});

const redeemNetworkOfferDone = (state, { courseOffers }) => state.merge({
  courseOffers,
  isRedeemingNetworkOffer: false,
  isRedeemingNetworkOfferDone: true,
  redeemingNetworkOfferError: '',
});

const redeemNetworkOfferError = (state, { error }) => state.merge({
  isRedeemingNetworkOffer: false,
  isRedeemingNetworkOfferDone: true,
  redeemingNetworkOfferError: error,
});

// promocode
const applyMembershipPromoCode = (state) => state.merge({
  isApplyingPromoCode: true,
  isApplyingPromoCodeDone: false,
  applyingPromoCodeError: '',
});

const applyMembershipPromoCodeDone = (state, { plans, benefits }) => state.merge({
  golfClubPlans: plans,
  golfClubBenefits: benefits,
  isApplyingPromoCode: false,
  isApplyingPromoCodeDone: true,
  applyingPromoCodeError: '',
});

const applyMembershipPromoCodeError = (state, { error }) => state.merge({
  isApplyingPromoCode: false,
  isApplyingPromoCodeDone: true,
  applyingPromoCodeError: error,
});

const resetPromoCode = (state) => state.merge({
  isApplyingPromoCode: false,
  isApplyingPromoCodeDone: false,
  applyingPromoCodeError: '',
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_NETWORK_MEMBERSHIP_REDIRECT_TO]: setNetworkMembershipRedirectTo,
  [Types.REDIRECT_TO_NETWORK_MEMBERSHIP]: redirectToNetworkMembership,
  [Types.REDIRECT_TO_NETWORK_MEMBERSHIP_DONE]: redirectToNetworkMembershipDone,
  [Types.REDIRECT_TO_NETWORK_MEMBERSHIP_ERROR]: redirectToNetworkMembershipError,
  [Types.RESET_NETWORK_MEMBERSHIP_BENEFITS]: resetNetworkMembershipBenefits,
  [Types.GET_NETWORK_MEMBERSHIP_BENEFITS]: getNetworkMembershipBenefits,
  [Types.GET_NETWORK_MEMBERSHIP_BENEFITS_DONE]: getNetworkMembershipBenefitsDone,
  [Types.GET_NETWORK_MEMBERSHIP_BENEFITS_ERROR]: getNetworkMembershipBenefitsError,
  [Types.GET_NETWORK_MEMBERSHIP_PLANS]: getNetworkMembershipPlans,
  [Types.GET_NETWORK_MEMBERSHIP_PLANS_DONE]: getNetworkMembershipPlansDone,
  [Types.GET_NETWORK_MEMBERSHIP_PLANS_ERROR]: getNetworkMembershipPlansError,
  [Types.GET_GOLF_CLUB_COURSES]: getGolfClubCourses,
  [Types.GET_GOLF_CLUB_COURSES_DONE]: getGolfClubCoursesDone,
  [Types.GET_GOLF_CLUB_COURSES_ERROR]: getGolfClubCoursesError,
  [Types.GET_NETWORK_MEMBERSHIP_COURSES]: getNetworkMembershipCourses,
  [Types.GET_NETWORK_MEMBERSHIP_COURSES_DONE]: getNetworkMembershipCoursesDone,
  [Types.GET_NETWORK_MEMBERSHIP_COURSES_ERROR]: getNetworkMembershipCoursesError,
  [Types.GET_NETWORK_MEMBERSHIP_SUBSCRIPTION]: getNetworkMembershipSubscription,
  [Types.GET_NETWORK_MEMBERSHIP_SUBSCRIPTION_SILENT]: getNetworkMembershipSubscriptionSilent,
  [Types.GET_NETWORK_MEMBERSHIP_SUBSCRIPTION_DONE]: getNetworkMembershipSubscriptionDone,
  [Types.GET_NETWORK_MEMBERSHIP_SUBSCRIPTION_ERROR]: getNetworkMembershipSubscriptionError,
  [Types.GET_GOLF_VANTAGE_URL]: getGolfVantageUrl,
  [Types.GET_GOLF_VANTAGE_URL_DONE]: getGolfVantageUrlDone,
  [Types.GET_GOLF_VANTAGE_URL_ERROR]: getGolfVantageUrlError,
  [Types.SET_NETWORK_MEMBERSHIP_CREDIT_CARD]: setCreditCard,
  [Types.SET_STATUS]: setStatus,
  [Types.PURCHASE_NETWORK_MEMBERSHIP]: purchaseNetworkMembership,
  [Types.PURCHASE_NETWORK_MEMBERSHIP_DONE]: purchaseNetworkMembershipDone,
  [Types.PURCHASE_NETWORK_MEMBERSHIP_ERROR]: purchaseNetworkMembershipError,
  [Types.CANCEL_NETWORK_MEMBERSHIP]: cancelNetworkMembership,
  [Types.CANCEL_NETWORK_MEMBERSHIP_DONE]: cancelNetworkMembershipDone,
  [Types.CANCEL_NETWORK_MEMBERSHIP_ERROR]: cancelNetworkMembershipError,
  [Types.CHANGE_CREDIT_CARD]: changeCreditCard,
  [Types.CHANGE_CREDIT_CARD_DONE]: changeCreditCardDone,
  [Types.CHANGE_CREDIT_CARD_ERROR]: changeCreditCardError,
  [Types.RENEW_NETWORK_MEMBERSHIP]: renewNetworkMembership,
  [Types.RENEW_NETWORK_MEMBERSHIP_DONE]: renewNetworkMembershipDone,
  [Types.RENEW_NETWORK_MEMBERSHIP_ERROR]: renewNetworkMembershipError,
  [Types.RESET_CHANGE_CREDIT_CARD]: resetChangeCreditCard,
  [Types.GET_COURSES_WITH_OFFERS]: getCoursesWithOffers,
  [Types.GET_COURSES_WITH_OFFERS_DONE]: getCoursesWithOffersDone,
  [Types.GET_COURSES_WITH_OFFERS_ERROR]: getCoursesWithOffersError,
  [Types.GET_COURSE_OFFERS]: getCourseOffers,
  [Types.GET_COURSE_OFFERS_DONE]: getCourseOffersDone,
  [Types.GET_COURSE_OFFERS_ERROR]: getCourseOffersError,
  [Types.REDEEM_NETWORK_OFFER]: redeemNetworkOffer,
  [Types.REDEEM_NETWORK_OFFER_DONE]: redeemNetworkOfferDone,
  [Types.REDEEM_NETWORK_OFFER_ERROR]: redeemNetworkOfferError,
  // promocode
  [Types.APPLY_MEMBERSHIP_PROMO_CODE]: applyMembershipPromoCode,
  [Types.APPLY_MEMBERSHIP_PROMO_CODE_DONE]: applyMembershipPromoCodeDone,
  [Types.APPLY_MEMBERSHIP_PROMO_CODE_ERROR]: applyMembershipPromoCodeError,
  [Types.RESET_PROMO_CODE]: resetPromoCode,
});
