import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getOffers: null,
  getOffersDone: ['offers'],
  getOffersError: ['errorMessage'],
  redeemOffer: ['id', 'afterRedeem'],
  redeemOfferDone: ['offers'],
  redeemOfferError: ['redeemingErrorMessage'],
  trackOfferEvent: ['eventParams'],
});

export const OffersTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  offers: null,
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: null,
  isRedeeming: false,
  isRedeemingDone: false,
  isRedeemingError: false,
  redeemingErrorMessage: null,
});

const getOffers = (state) => state.merge({
  offers: null,
  isLoading: true,
  isDone: false,
  isError: false,
  errorMessage: null,
});

const getOffersDone = (state, { offers }) => state.merge({
  offers,
  isLoading: false,
  isDone: true,
  isError: false,
  errorMessage: null,
});

const getOffersError = (state, { errorMessage }) => state.merge({
  offers: null,
  isLoading: false,
  isDone: false,
  isError: true,
  errorMessage,
});

const redeemOffer = (state) => state.merge({
  isRedeeming: true,
  isRedeemingDone: false,
  isRedeemingError: false,
  redeemingErrorMessage: null,
});

const redeemOfferDone = (state, { offers }) => state.merge({
  offers,
  isRedeeming: false,
  isRedeemingDone: true,
  isRedeemingError: false,
  redeemingErrorMessage: null,
});

const redeemOfferError = (state, { redeemingErrorMessage }) => state.merge({
  isRedeeming: false,
  isRedeemingDone: false,
  isRedeemingError: true,
  redeemingErrorMessage,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_OFFERS]: getOffers,
  [Types.GET_OFFERS_DONE]: getOffersDone,
  [Types.GET_OFFERS_ERROR]: getOffersError,
  [Types.REDEEM_OFFER]: redeemOffer,
  [Types.REDEEM_OFFER_DONE]: redeemOfferDone,
  [Types.REDEEM_OFFER_ERROR]: redeemOfferError,
});
