import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getContest: [],
  getContestOnly: [],
  getContestDone: ['contests', 'pinnedBanners'],
  getContestOnlyDone: ['contests'],
  getContestError: ['message'],
});

export const ContestTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  contest: null,
  pinnedBanners: null,
  isLoading: false,
  isDone: false,
  isContestLoading: false,
  isError: false,
  errorMessage: '',
});

const getContest = (state) => state.merge({
  isLoading: true,
  isError: false,
  isDone: false,
  errorMessage: '',
});

const getContestDone = (state, { contests, pinnedBanners }) => state.merge({
  isLoading: false,
  isError: false,
  isDone: true,
  contests,
  pinnedBanners,
});

const getContestError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  isDone: true,
  errorMessage: message,
});

const getContestOnly = (state) => state.merge({
  isLoading: false,
  isError: false,
  isDone: false,
  isContestLoading: true,
});

const getContestOnlyDone = (state, { contests }) => state.merge({
  isContestLoading: false,
  isError: false,
  isDone: true,
  contests,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CONTEST]: getContest,
  [Types.GET_CONTEST_DONE]: getContestDone,
  [Types.GET_CONTEST_ERROR]: getContestError,
  [Types.GET_CONTEST_ONLY]: getContestOnly,
  [Types.GET_CONTEST_ONLY_DONE]: getContestOnlyDone,
});
