import axios from 'axios';
import humps from 'humps';
import history from 'config/history';

let store;

export const injectStore = (_store) => {
  store = _store;
};

class ApiClient {
  static async handleError(error) {
    const token = localStorage.getItem('X-Api-User-Token');
    if (axios.isCancel(error)) {
      // request cancelled
    } else if (error?.response?.status === 401 && !!token) {
      localStorage.clear();
      history.push('/sign-in?reset=true');
    } else if (error?.response?.status === 503) {
      const isUnderMaintainence = store.getState()?.app?.isUnderMaintenance;
      if (!isUnderMaintainence) {
        store.dispatch({ type: 'SET_IS_UNDER_MAINTENANCE', value: true });
      }
    } else {
      throw humps.camelizeKeys(error);
    }
  }

  get = async (url, params, options) => {
    try {
      const result = await axios.get(
        `${this.constructor.apiBaseUrl()}/${url}`,
        {
          headers: this.constructor.headers(),
          params: this.constructor.formatParams(params),
          withCredentials: this.constructor.withCredentials(),
        },
      );
      const isUnderMaintainence = store.getState()?.app?.isUnderMaintenance;
      if (isUnderMaintainence) {
        store.dispatch({ type: 'SET_IS_UNDER_MAINTENANCE', value: false });
      }
      if (options?.skipCamelizeKeys) {
        return result;
      }
      return humps.camelizeKeys(result);
    } catch (error) {
      return ApiClient.handleError(error);
    }
  };

  post = async (url, body, isFormData = false) => {
    try {
      const result = await axios.post(
        `${this.constructor.apiBaseUrl()}/${url}`,
        isFormData ? body : this.constructor.formatParams(body),
        {
          headers: this.constructor.headers(),
          withCredentials: this.constructor.withCredentials(),
        },
      );
      return humps.camelizeKeys(result);
    } catch (error) {
      return ApiClient.handleError(error);
    }
  };

  delete = async (url, params) => {
    try {
      const result = await axios.delete(
        `${this.constructor.apiBaseUrl()}/${url}`,
        {
          headers: this.constructor.headers(),
          params: this.constructor.formatParams(params),
          withCredentials: this.constructor.withCredentials(),
        },
      );
      return humps.camelizeKeys(result);
    } catch (error) {
      return ApiClient.handleError(error);
    }
  };

  put = async (url, body) => {
    try {
      const result = await axios.put(
        `${this.constructor.apiBaseUrl()}/${url}`,
        this.constructor.formatParams(body),
        {
          headers: this.constructor.headers(),
          withCredentials: this.constructor.withCredentials(),
        },
      );
      return humps.camelizeKeys(result);
    } catch (error) {
      return ApiClient.handleError(error);
    }
  };

  patch = async (url, body) => {
    try {
      const result = await axios.patch(
        `${this.constructor.apiBaseUrl()}/${url}`,
        this.constructor.formatParams(body),
        {
          headers: this.constructor.headers(),
          withCredentials: this.constructor.withCredentials(),
        },
      );
      return humps.camelizeKeys(result);
    } catch (error) {
      return ApiClient.handleError(error);
    }
  };
}

export default ApiClient;
