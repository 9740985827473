import SupremeGolfApiClient from './SupremeGolfApiClient';

const DEALS_ENDPOINT = 'v6/deals';

class DealsApi {
  static async getDealsNear(lat, lon, { radius, limit }) {
    const params = {
      lat,
      lon,
      radius,
      limit,
    };

    const response = await SupremeGolfApiClient.get(
      `${DEALS_ENDPOINT}/near`,
      params,
    );
    const { data } = response;

    return data.deals;
  }

  static async getDealDetail(id) {
    const response = await SupremeGolfApiClient.get(`${DEALS_ENDPOINT}/${id}`);
    const { data } = response;

    return data.deal;
  }
}

export default DealsApi;
