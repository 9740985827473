import SupremeGolfApiClient from './SupremeGolfApiClient';

const EXPLORE_ENDPOINT = 'v6/explore';

class ExploreApi {
  static async getExploreContent(params) {
    const response = await SupremeGolfApiClient.get(EXPLORE_ENDPOINT, params);
    const { data } = response;
    return data.explore;
  }
}

export default ExploreApi;
