import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  resetCity: [],
  getCity: ['params'],
  getCityDone: ['city'],
  getCityError: ['message'],
});

export const CityTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  city: {},
  isLoading: false,
  isError: false,
  errorMessage: '',
});

const resetCity = (state) => state.merge(INITIAL_STATE);

const getCity = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const getCityDone = (state, { city }) => state.merge({
  isLoading: false,
  isError: false,
  city,
});

const getCityError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_CITY]: resetCity,
  [Types.GET_CITY]: getCity,
  [Types.GET_CITY_DONE]: getCityDone,
  [Types.GET_CITY_ERROR]: getCityError,
});
