import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getDealDetail: ['dealId'],
  getDealDetailDone: ['deal'],
  getDealDetailError: ['message'],
});

export const DealDetailTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
  deal: {},
});

const getDealDetail = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const getDealDetailDone = (state, { deal }) => state.merge({
  isLoading: false,
  isError: false,
  deal,
});

const getDealDetailError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DEAL_DETAIL]: getDealDetail,
  [Types.GET_DEAL_DETAIL_DONE]: getDealDetailDone,
  [Types.GET_DEAL_DETAIL_ERROR]: getDealDetailError,
});
