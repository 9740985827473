import {
  call, put, takeLatest, all, getContext,
} from 'redux-saga/effects';

import SubscriptionsActions, {
  SubscriptionsTypes,
} from 'reducers/subscriptions';
import CitiesApi from 'apis/supremeGolfApi/CitiesApi';
import SubscriptionsApi from 'apis/supremeGolfApi/SubscriptionsApi';
import AlertsApi from 'apis/supremeGolfApi/AlertsApi';

export function* subscribe({
  email, zipCode, gRecaptchaResponseData, callback,
}) {
  try {
    let city;
    try {
      city = yield call(CitiesApi.findCity, { zipCode });
    } catch (error) {
      const {
        response: {
          data: {
            error: niceError,
          } = {},
        } = {},
      } = error;
      throw Error(niceError || error.message);
    }

    const LS = yield getContext('localStorage');
    const utmParams = yield call(LS.getUtmParams);

    yield all([
      call(AlertsApi.dealAlert, email, city.id, city.zipcodeId, gRecaptchaResponseData),
      call(SubscriptionsApi.subscribe, email, utmParams),
    ]);
    if (callback) callback();
    yield put(SubscriptionsActions.subscribeDone());
  } catch (error) {
    const {
      response: {
        data: {
          error: niceError,
        } = {},
      } = {},
    } = error;
    if (callback) callback(niceError || error.message);
    yield put(SubscriptionsActions.subscribeError(niceError || error.message));
  }
}

function* subscribeWatcher() {
  yield takeLatest(SubscriptionsTypes.SUBSCRIBE, subscribe);
}

export default [
  subscribeWatcher,
];
