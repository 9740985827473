class LocationHelper {
  static USER_DENIED_LOCATION = 'User denied Geolocation';

  static getUserLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (location) => resolve(location),
        (error) => reject(error),
        {
          enableHighAccuracy: false,
          timeout: 10000,
          maximumAge: 3600000,
        },
      );
    });
  }

  static hasUserLocationPermission() {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        () => resolve(true),
        () => resolve(false),
      );
    });
  }

  // This helper is to extract the location information from entities such as:
  // courses, deals, cities. Entities that we have in our system, not the
  // location from the geolocalization.
  static extractBasicLocationData(type, completeData) {
    const {
      id,
      slug,
      hierarchizedUrl,
      latitude,
      longitude,
      name,
      addressCity,
      addressState,
      fullName,
    } = completeData;

    const label = type === 'City' ? fullName : `${name} - ${addressCity}, ${addressState}`;

    return {
      type,
      id,
      slug,
      hierarchizedUrl,
      latitude,
      longitude,
      label,
    };
  }
}

export default LocationHelper;
