import SupremeGolfApiClient from './SupremeGolfApiClient';

const FAVORITE_COURSES_ENDPOINT = 'v6/favorite_courses';

class FavoriteCoursesApi {
  static async addFavorite(courseId) {
    const response = await SupremeGolfApiClient.post(
      FAVORITE_COURSES_ENDPOINT,
      {
        id: courseId,
      },
    );
    const { data } = response;
    return data;
  }

  static async removeFavorite(courseId) {
    const response = await SupremeGolfApiClient.delete(
      FAVORITE_COURSES_ENDPOINT,
      {
        id: courseId,
      },
    );
    const { data } = response;
    return data;
  }

  static async getFavorites() {
    const response = await SupremeGolfApiClient.get(FAVORITE_COURSES_ENDPOINT);
    const { data } = response;
    return data.courses;
  }
}

export default FavoriteCoursesApi;
