import React from 'react';

import PropTypes from 'prop-types';
import { ReactComponent as LoadingSpin } from 'assets/images/svg/loading-spin.svg';

const Spinner = ({
  label, wrapperStyles, labelStyles, labelWidthStyles,
}) => (
  <div className={wrapperStyles}>
    {label && (
      <div className={`${labelStyles} ${labelWidthStyles}`}>
        {label}
      </div>
    )}
    <LoadingSpin />
  </div>
);

Spinner.defaultProps = {
  label: null,
  labelStyles: 'font-black text-black leading-5 text-center md:leading-8 md:font-bold text-4 md:text-6 -tracking-0.2 md:-tracking-0.3',
  labelWidthStyles: 'w-36 md:w-50',
  wrapperStyles: 'flex flex-col items-center mx-auto my-40 md:my-56',
};

Spinner.propTypes = {
  wrapperStyles: PropTypes.string,
  labelStyles: PropTypes.string,
  labelWidthStyles: PropTypes.string,
  label: PropTypes.string,
};

export default Spinner;
