import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { DEALS_PAGINATION } from 'utils/constants';

const { Types, Creators } = createActions({
  getDeals: ['lat', 'lon', 'params'],
  getDealsDone: ['deals'],
  getDealsError: ['message'],
  getNextPage: [],
});

export const SearchDealsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  pageNumber: 1,
  errorMessage: '',
  hasMore: true,
  deals: [],
});

const getDeals = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  errorMessage: '',
});

const getDealsDone = (state, { deals }) => (
  state.merge({
    isLoading: false,
    isDone: true,
    isError: false,
    errorMessage: '',
    deals,
  })
);

const getDealsError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const getNextPage = (state) => state.merge({
  pageNumber: state.pageNumber + 1,
  hasMore: (state.pageNumber * DEALS_PAGINATION < state.deals.length),
});

export const getPaginatedListSelector = (state) => {
  const paginatedDeals = state.deals;

  return paginatedDeals.slice(0, state.pageNumber * DEALS_PAGINATION);
};

export const getListLengthSelector = (state) => state.deals.length;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DEALS]: getDeals,
  [Types.GET_DEALS_DONE]: getDealsDone,
  [Types.GET_DEALS_ERROR]: getDealsError,
  [Types.GET_NEXT_PAGE]: getNextPage,
});
