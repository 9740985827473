import { all, fork } from 'redux-saga/effects';

import affiliate from './affiliate';
import app from './app';
import autocompleteSaga from './autocomplete';
import banners from './banners';
import bestDeals from './bestDeals';
import bookingConfirmation from './bookingConfirmation';
import checkout from './checkout';
import city from './city';
import contest from './contest';
import country from './country';
import courseAlerts from './courseAlerts';
import courseDetailSaga from './courseDetail';
import courseMembershipSaga from './courseMembership';
import courseReviews from './courseReviews';
import courseSuggestionsSaga from './courseSuggestions';
import creditCards from './creditCards';
import dealDetail from './dealDetail';
import explore from './explore';
import faqsSaga from './faqs';
import favoriteCities from './favoriteCities';
import favoriteCourses from './favoriteCourses';
import feedback from './feedback';
import invite from './invite';
import location from './location';
import marketingBanner from './marketingBanner';
import membership from './membership';
import membershipDetails from './membershipDetails';
import networkMembership from './networkMembership';
import offers from './offers';
import pageSaga from './page';
import partnerOffers from './partnerOffers';
import priceAlerts from './priceAlerts';
import profile from './profile';
import rateTypes from './rateTypes';
import registrationsSaga from './registrations';
import requestResetPassword from './requestResetPassword';
import reservations from './reservations';
import resetPassword from './resetPassword';
import rewards from './rewards';
import routerSaga from './router';
import searchCoursesSaga from './searchCourses';
import searchDealsSaga from './searchDeals';
import searchParamsSaga from './searchParams';
import selectProvider from './selectProvider';
import session from './session';
import site from './site';
import subscriptionsSaga from './subscriptions';
import supportSaga from './support';
import timeSlots from './timeSlots';
import tracking from './tracking';
import unlock from './unlock';
import userAgreements from './userAgreements';
import welcome from './welcome';

const forkList = (sagasList) => sagasList.map(fork);

export default function* rootSaga() {
  yield all([
    ...forkList(affiliate),
    ...forkList(app),
    ...forkList(autocompleteSaga),
    ...forkList(banners),
    ...forkList(bestDeals),
    ...forkList(bookingConfirmation),
    ...forkList(checkout),
    ...forkList(city),
    ...forkList(contest),
    ...forkList(country),
    ...forkList(courseAlerts),
    ...forkList(courseDetailSaga),
    ...forkList(courseMembershipSaga),
    ...forkList(courseReviews),
    ...forkList(courseSuggestionsSaga),
    ...forkList(creditCards),
    ...forkList(dealDetail),
    ...forkList(explore),
    ...forkList(faqsSaga),
    ...forkList(favoriteCities),
    ...forkList(favoriteCourses),
    ...forkList(feedback),
    ...forkList(invite),
    ...forkList(location),
    ...forkList(marketingBanner),
    ...forkList(membership),
    ...forkList(membershipDetails),
    ...forkList(networkMembership),
    ...forkList(offers),
    ...forkList(pageSaga),
    ...forkList(partnerOffers),
    ...forkList(priceAlerts),
    ...forkList(profile),
    ...forkList(rateTypes),
    ...forkList(registrationsSaga),
    ...forkList(requestResetPassword),
    ...forkList(reservations),
    ...forkList(resetPassword),
    ...forkList(rewards),
    ...forkList(routerSaga),
    ...forkList(searchCoursesSaga),
    ...forkList(searchDealsSaga),
    ...forkList(searchParamsSaga),
    ...forkList(selectProvider),
    ...forkList(site),
    ...forkList(session),
    ...forkList(subscriptionsSaga),
    ...forkList(supportSaga),
    ...forkList(timeSlots),
    ...forkList(tracking),
    ...forkList(unlock),
    ...forkList(userAgreements),
    ...forkList(welcome),
  ]);
}
