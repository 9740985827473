import './config/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import App from 'components/App';

import store from 'config/store';

// eslint-disable-next-line import/no-unresolved
import 'assets/styles/tailwind-autogenerated.css';

const app = document.getElementById('root');

// GTM Setup
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

// Honeybadger Setup
const honeyBadgerConfig = {
  apiKey: process.env.REACT_APP_HONEY_BADGER_API_KEY,
  environment: process.env.REACT_APP_ENV,
  reportData: process.env.NODE_ENV !== 'development',
};
const honeyBadgerContext = {
  api_user_key: process.env.REACT_APP_X_API_KEY,
};

const honeybadger = Honeybadger.configure(honeyBadgerConfig);
honeybadger.setContext(honeyBadgerContext);

ReactDOM.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <App store={store} />
  </HoneybadgerErrorBoundary>,
  app,
);
