import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  requestResetPassword: [
    'email',
  ],
  requestResetPasswordDone: ['requestResponse'],
  requestResetPasswordError: ['message'],
  requestResetPasswordFinish: ['finish'],
  requestResetPasswordResetError: [],
});

export const RequestResetPasswordTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
  requestResponse: false,
});

const requestResetPasswordResetError = (state) => state.merge({
  isLoading: false,
  isError: false,
  errorMessage: '',
  requestResponse: false,
});

const requestResetPassword = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
  requestResponse: false,
});

const requestResetPasswordDone = (state, { requestResponse }) => state.merge({
  isLoading: false,
  isError: false,
  requestResponse,
  errorMessage: '',
});

const requestResetPasswordError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  requestResponse: false,
  errorMessage: message,
});

const requestResetPasswordFinish = (state) => state.merge({
  requestResponse: false,
  isError: false,
  errorMessage: '',

});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_RESET_PASSWORD_RESET_ERROR]: requestResetPasswordResetError,
  [Types.REQUEST_RESET_PASSWORD]: requestResetPassword,
  [Types.REQUEST_RESET_PASSWORD_DONE]: requestResetPasswordDone,
  [Types.REQUEST_RESET_PASSWORD_ERROR]: requestResetPasswordError,
  [Types.REQUEST_RESET_PASSWORD_FINISH]: requestResetPasswordFinish,
});
