import ApiClient from 'apis/ApiClient';
import LocalStorage from 'utils/LocalStorage';

class MembershipApiClient extends ApiClient {
  static headers() {
    const token = LocalStorage.getToken();
    if (token) {
      return {
        'x-api': process.env.REACT_APP_X_API_KEY,
        'x-api-user-token': token,
      };
    }

    return {
      'x-api': process.env.REACT_APP_X_API_KEY,
    };
  }

  static withCredentials() {
    return true;
  }

  static apiBaseUrl() {
    return process.env.REACT_APP_MEMBERSHIP_API_BASE_URL;
  }

  static formatParams(params) {
    return params;
  }
}

export default new MembershipApiClient();
