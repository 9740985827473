import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getCourseMembership: ['courseId'],
  getCourseMembershipDone: ['course', 'membership', 'ctaUrl', 'ctaFaqUrl'],
  getCourseMembershipError: ['message'],
  setMembershipUpsellBackToUrl: ['backToUrl'],
  setMembershipUpsellRedirectToUrl: ['redirectToUrl'],
  performMembershipUpsellSignUp: null,
  performMembershipUpsellSignUpDone: null,
  performMembershipUpsellSignUpError: ['message'],
  performMembershipUpsellGoBack: null,
});

export const CourseMembershipTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isDone: false,
  isError: false,
  errorMessage: '',
  backToUrl: '',
  redirectToUrl: '',
  course: {},
  membership: {},
  ctaUrl: '',
  ctaFaqUrl: '',
});

const getCourseMembership = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
});

const getCourseMembershipDone = (state, {
  course, membership, ctaUrl, ctaFaqUrl,
}) => state.merge({
  isLoading: false,
  isError: false,
  isDone: true,
  course,
  membership,
  ctaUrl,
  ctaFaqUrl,
});

const getCourseMembershipError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: false,
  isError: true,
  errorMessage: message,
});

const setMembershipUpsellBackToUrl = (state, { backToUrl }) => state.merge({
  backToUrl,
});

const setMembershipUpsellRedirectToUrl = (state, { redirectToUrl }) => state.merge({
  redirectToUrl,
});

const performMembershipUpsellSignUp = (state) => state.merge({
  isLoading: true,
  isError: false,
});

const performMembershipUpsellSignUpDone = (state) => state.merge({
  isLoading: false,
  isError: false,
});

const performMembershipUpsellSignUpError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COURSE_MEMBERSHIP]: getCourseMembership,
  [Types.GET_COURSE_MEMBERSHIP_DONE]: getCourseMembershipDone,
  [Types.GET_COURSE_MEMBERSHIP_ERROR]: getCourseMembershipError,
  [Types.SET_MEMBERSHIP_UPSELL_BACK_TO_URL]: setMembershipUpsellBackToUrl,
  [Types.SET_MEMBERSHIP_UPSELL_REDIRECT_TO_URL]: setMembershipUpsellRedirectToUrl,
  [Types.PERFORM_MEMBERSHIP_UPSELL_SIGN_UP]: performMembershipUpsellSignUp,
  [Types.PERFORM_MEMBERSHIP_UPSELL_SIGN_UP_DONE]: performMembershipUpsellSignUpDone,
  [Types.PERFORM_MEMBERSHIP_UPSELL_SIGN_UP_ERROR]: performMembershipUpsellSignUpError,
});
