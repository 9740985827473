import { createReducer, createActions } from 'reduxsauce';

import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  toggleHeaderMenu: null,
});

export const HeaderMenuSmallTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  open: false,
});

const toggleHeaderMenu = (state) => state.merge({
  open: !state.open,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOGGLE_HEADER_MENU]: toggleHeaderMenu,
});
