/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import SupremeGolfApiClient from './SupremeGolfApiClient';

const TEE_TIMES_ENDPOINT = 'v6/tee_times';
const TEE_TIME_GROUPS_ENDPOINT = 'v6/tee_time_groups';

class TimeSlotsApi {
  static async getTimeSlots({
    courseId,
    date,
    isRiding,
    maxHour,
    maxPrice,
    minHour,
    minPrice,
    numHoles,
    qty,
    rateTypes,
    isPrepaidOnly,
    ...searchParams
  }) {
    const response = await SupremeGolfApiClient.get(
      `${TEE_TIME_GROUPS_ENDPOINT}/at/${courseId}`,
      {
        date,
        isRiding,
        maxHour,
        maxPrice,
        minHour,
        minPrice,
        numHoles,
        qty,
        rateTypes,
        isPrepaidOnly,
        ...searchParams,
      },
    );
    const { data } = response;
    return data;
  }

  static async getFeaturedTimeSlots({
    courseId,
    date,
  }) {
    const response = await SupremeGolfApiClient.get(
      `${TEE_TIMES_ENDPOINT}/at/${courseId}/featured`,
      { date },
    );
    const { data } = response;
    return data;
  }

  static async getTeeTimeProviders({
    courseId,
    date,
    isRiding,
    numHoles,
    qty,
    rateType,
    timeSlot,
    isPrepaidOnly,
  }, authToken) {
    const url = `${TEE_TIMES_ENDPOINT}/at/${courseId}/tee_times`;

    const { minorRateType, majorRateType } = rateType;

    const response = await SupremeGolfApiClient.get(url, {
      authToken,
      date,
      isRiding,
      numHoles,
      qty,
      minorRateType,
      majorRateType,
      timeSlot,
      supportsPrepay: true,
      isPrepaidOnly,
    });
    const { data } = response;
    return data;
  }

  static async getRateTypes({
    courseId,
    date,
    isRiding,
    maxHour,
    maxPrice,
    minHour,
    minPrice,
    numHoles,
    qty,
    rateTypes,
    timeSlot,
    isPrepaidOnly,
    ...searchParams
  }, authToken) {
    const response = await SupremeGolfApiClient.get(
      `${TEE_TIME_GROUPS_ENDPOINT}/at/${courseId}/rate_types`,
      {
        authToken,
        date,
        isRiding,
        maxHour,
        maxPrice,
        minHour,
        minPrice,
        numHoles,
        qty,
        rateTypes,
        timeSlot,
        byMinorRateType: true,
        isPrepaidOnly,
        ...searchParams,
      },
    );
    const { data } = response;
    return data;
  }

  static async prepare({ qty, teeTimeId, prepaymentRuleId }) {
    try {
      const response = await SupremeGolfApiClient.get(
        `${TEE_TIMES_ENDPOINT}/${teeTimeId}/reservations/soft-prepare`,
        { qty, prepaymentRuleId },
      );
      return response.data;
    } catch (error) {
      const {
        response: {
          data: { error: explanatoryError },
        },
      } = error;
      return {
        error: explanatoryError || error.message,
        statusCode: error.response.status,
      };
    }
  }

  static async getRelevantTeeTimes({ courseId, date, teeTimeId }) {
    const payload = { date };
    if (teeTimeId) payload.teeTimeId = teeTimeId;
    const response = await SupremeGolfApiClient.get(`${TEE_TIMES_ENDPOINT}/at/${courseId}/relevant`, payload);

    return response.data;
  }
}

export default TimeSlotsApi;
