import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  createCreditCard: [
    'token',
    'gRecaptchaResponseData',
    'callback',
  ],
  createCreditCardDone: null,
  createCreditCardError: ['message', 'validationErrors'],
  resetCreditCard: null,
  resetCreditCardError: null,
});

export const CreateCreditCardTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  errorMessage: '',
  validationErrors: {},
  creditCard: null,
});

const createCreditCard = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
  validationErrors: {},
});

const createCreditCardDone = (state) => state.merge({
  isLoading: false,
  isError: false,
  errorMessage: '',
  validationErrors: {},
});

const createCreditCardError = (state, { message, validationErrors }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
  validationErrors,
});

const resetCreditCard = (state) => state.merge({
  creditCard: null,
});

const resetCreditCardError = (state) => state.merge({
  errorMessage: '',
  validationErrors: {},
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_CREDIT_CARD]: createCreditCard,
  [Types.CREATE_CREDIT_CARD_DONE]: createCreditCardDone,
  [Types.CREATE_CREDIT_CARD_ERROR]: createCreditCardError,
  [Types.RESET_CREDIT_CARD]: resetCreditCard,
  [Types.RESET_CREDIT_CARD_ERROR]: resetCreditCardError,
});
