import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getCourseDetail: ['courseId', 'iterableTracking'],
  getCourseDetailSilent: ['courseId'],
  getCourseDetailDone: ['course'],
  getCourseDetailError: ['message'],
  getCourseScorecardDone: ['scorecard'],
});

export const CourseDetailTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
  isError: false,
  isDone: false,
  errorMessage: '',
  course: {},
  scorecard: null,
  scorecardLady: null,
});

const getCourseDetail = () => INITIAL_STATE.merge({
  isLoading: true,
});

const getCourseScorecardDone = (state, { scorecard }) => state.merge({
  ...scorecard,
});

const getCourseDetailDone = (state, { course }) => state.merge({
  isLoading: false,
  isError: false,
  isDone: true,
  course,
});

const getCourseDetailError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  isDone: true,
  errorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COURSE_DETAIL]: getCourseDetail,
  [Types.GET_COURSE_DETAIL_DONE]: getCourseDetailDone,
  [Types.GET_COURSE_DETAIL_ERROR]: getCourseDetailError,
  [Types.GET_COURSE_SCORECARD_DONE]: getCourseScorecardDone,
});
