import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const InjectScript = ({ onLoad, src }) => {
  const handleScriptInject = ({ scriptTags }) => {
    if (scriptTags) {
      const scriptTag = scriptTags[0];
      scriptTag.onload = () => onLoad && onLoad();
    }
  };

  return src && (
    <Helmet onChangeClientState={(_newState, addedTags) => handleScriptInject(addedTags)}>
      <script
        type="text/javascript"
        src={src}
        async
      />
    </Helmet>
  );
};

InjectScript.defaultProps = {
  onLoad: null,
};

InjectScript.propTypes = {
  onLoad: PropTypes.func,
  src: PropTypes.string.isRequired,
};

export default InjectScript;
