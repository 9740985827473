import IterableApiClient from 'apis/iterableApi/IterableApiClient';

import getValue from 'utils/cookies';

class Iterable {
  // Track a click event using https://api.iterable.com/api/docs#events_track
  // Need to pass:
  //   * email or userId: string
  //   * eventName: string
  //   * dataFields: Object
  static trackEvent(data) {
    if (!IterableApiClient.isConfigured) return;

    if ((data.email || data.userId) && data.eventName) {
      IterableApiClient.post('api/events/track', data).catch((e) => e);
    }
  }

  static getTrackingParams(appendIterable = false) {
    const iterableEmailCampaignId = getValue('iterableEmailCampaignId');
    const iterableTemplateId = getValue('iterableTemplateId');
    const iterableMessageId = getValue('iterableMessageId');
    const params = {
      campaign_id: iterableEmailCampaignId ? Number(iterableEmailCampaignId) : null,
      template_id: iterableTemplateId ? Number(iterableTemplateId) : null,
      message_id: iterableMessageId,
    };

    if (appendIterable) {
      return {
        iterable_campaign_id: params.campaign_id,
        iterable_template_id: params.template_id,
        iterable_message_id: params.message_id,
      };
    }

    return params;
  }
}

export default Iterable;
